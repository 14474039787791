import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import {
    fetchUtilData,
    handleInput,
    renderListInner,
    renderForm, removeEntry
} from "../../api/attributes-databaase";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";

export default class ComDriverSettingAttribute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Attributes: [],
            newEntry: {},
            combineFieldName: "Ref",
            combineDataEntry: "Data",
            combineAttributeListKey: "Attributes",
            isCombineKey: true,
            showList: true,
            cqlTable: "GeneralTableData",
            cqlTableRef: "personnel",
            model: "",
            isLoading: false
        }
    }

    componentWillMount() {
        let loc = this.props.location.pathname.replace("/setting-model-databases/", "");
        console.log("setting-model-models > ", loc)
        this.setState({
            cqlTableRef: loc,
            model: loc
        })
    }

    componentDidMount = async () => {
        this.setState({isLoading: true});
        await this.loadInitialData()
        this.setState({isLoading: false})
    }

    loadInitialData = async () => {
        await fetchUtilData(this, this.state.cqlTable, "Data", this.state.cqlTableRef);
        await fetchUtilData(this, "GeneralTableField", "Attributes", this.state.cqlTableRef);
    }

    submitEntry = async (e, table, addData, _this) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        let entry = {..._this.state.newEntry};
        entry.Org = GetUserToken().OrgCode;
        for (let i in addData) {
            const row = addData[i];
            if (typeof row.type !== "undefined") {
                if (row.type === "hidden") {
                    entry[row.field] = row.defaultValue;
                }
            }
        }
        //todo let add our default variable

        let hub = {};
        hub.Org = GetUserToken().OrgCode;
        hub.CqlTable = this.state.cqlTableRef;
        hub.InData = [];
        hub.InData.push(entry);

        let backend = eConfig.mainBackend;
        let endpoint = "/api/fleet/generic/data/new";

        console.log("submitUploadEntry obj *** send > ", _this.state.newEntry);
        console.log("submitUploadEntry *** send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitUploadEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    const res = dataIn.RESULT;
                    if (res.HasError) {
                        let errMsg = "";
                        for (let i in res.Errors) {
                            const rowError = res.Errors[i];
                            errMsg = errMsg + "\nError: " + rowError.Error + " -> Row #:" + rowError.RowNumber
                                + ", Field Name: " + rowError.KeyName + "; Field Value: " + rowError.KeyValue
                        }
                        alert("Fail to insert, Total error: " + res.Errors.length + errMsg)
                    } else {
                        alert("Thank you Record submitted!");
                        let tmp = _this.state;
                        tmp.showList = true;
                        _this.setState(tmp);
                        _this.loadInitialData();
                    }
                }
            }

        });
    }
    renderList = () => {

        const keyList = [];

        for (let i in this.state.Attributes) {
            const row = this.state.Attributes[i];
            keyList.push({
                field: row.fieldname,
                label: row.fieldname.toLocaleUpperCase()
            });
        }
        const deleteKey = ["org", "tableref", "ref"];
        const deleteTable = "GeneralTableData";
        const sortKey = "position";
        return renderListInner(this, "Data", keyList, deleteKey, deleteTable, "showList", removeEntry, sortKey)
    }
    renderForm = () => {
        const options = ["yes", "no"];
        let ls = [];

        let data = this.state.Attributes;
        const keyOne = "position";
        data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)

        for (let i in data) {
            const row = data[i];
            let boo = false;
            if (row.mandatory === "yes") {
                boo = true;
            }
            ls.push({
                    field: row.fieldname,
                    label: row.fieldname.toLocaleUpperCase(),
                    options: options,
                    type: "text",
                    required: boo
                },
            );
            /*if(row.unique==="yes"){
                let tmp=this.state;
                tmp.isCombineKey=true;
                tmp.fieldBuilder[row.name]="Ref";
                this.setState(tmp);
            }*/
        }

        ls.push({
                field: "TableRef",
                label: "TableRef".toLocaleUpperCase(),
                options: options,
                type: "hidden",
                required: false,
                defaultValue: this.state.cqlTableRef,
            },
        );

        return renderForm(ls, "showList", this.state.cqlTable, this, this.submitEntry)
    }

    render() {

        let _this = this;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row">
                        <div className="col col-xs-12">
                            <h3>
                                {this.state.model.toLocaleUpperCase()} database

                                {this.state.showList ?
                                    <a className={"btn btn-primary pull-right"}
                                       onClick={(e) => handleInput(e, "showList", false, _this)}>Add New Record</a>
                                    :
                                    <a className={"btn btn-danger pull-right"}
                                       onClick={(e) => handleInput(e, "showList", true, _this)}>Cancel Adding new
                                        Record</a>
                                }
                            </h3>
                        </div>
                    </div>

                    {this.state.isLeading ?
                        <div className="row">
                            <div className="col col-xs-12">
                                <p style={{color: "red"}}>Please wait when loading data .....</p>
                            </div>
                        </div> :

                        <div className="row">
                            <div className="col col-xs-12">
                                {this.state.showList ? this.renderList() : this.renderForm()}
                            </div>
                        </div>
                    }


                </div>


            </PageContentWallpaper>
        )
    }

}
