import React, {Component} from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {getValue, setValue, setValueBox, submitEntry} from "../../component/generic/input-control-function";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";

export default class PopupUploadImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantity:0,
            comment:"",
        }
    }

    handleInput=(e)=>{
        const key=e.target.name;
        const val=e.target.value;
        this.setState({[key]:val})
    }
    closeMe = () => {
        const statusKey = this.props.popStatus;
        this.props.popFnClose(statusKey);
    }

    submitEntry=async (e)=>{
        e.preventDefault();
        let user =  GetUserToken();
        let state = this.state;
        const ref = this.props.selectedRecord[this.props.selectedRecordKey];
        let hub={
            Org : GetUserToken().OrgCode,
            PartNumber :ref,
            Quantity :parseFloat( state.quantity),
            LoadBy : user.Username,
            Comments :state.comment,
        }
        hub.Org = GetUserToken().OrgCode;

        console.log("LOGGER SUBMIT :> ", hub);

        const fnClose = this.closeMe;
        let _this = this;
        const parentProps = this.props._this;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/fleet/part/stock-loader";

        console.log("submitEntry obj *** send > ", hub);



        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    if (dataIn.status !== "OK") {
                        parentProps.loadInitialData();
                        alert("Thank you, Part stock submitted");
                        fnClose()
                    }

                }
            }

        });
    }

    render() {
        const title = this.props.popTitle;
        const _this = this.props._this;


        const tableRef = this.props.tableRef;
        const typeOptions = this.props.typeOptions;
        const fileCategory = this.props.fileCategory;

        const statusKey = this.props.popStatus;
        let status = this.props.state[statusKey];
        const fnClose = this.closeMe;
        if (!status) {
            return null
        }

        const ref = this.props.selectedRecord[this.props.selectedRecordKey];



        const actions = [
            <FlatButton
                label={"Close"}
                primary={true}
                onClick={fnClose}
            />,
        ];


        return (
            <MuiThemeProvider>
                <Dialog
                    title={title + " : " + ref}
                    actions={actions}
                    modal={false}
                    open={status}
                    onRequestClose={fnClose}
                    autoScrollBodyContent={true}
                >
                    <form onSubmit={this.submitEntry}>

                        <div className="form-group">
                            <label>Name Quantity</label>
                            <br/>
                            <input
                                className="form-control"
                                name={"quantity"}
                                onChange={(e) => this.handleInput(e)}
                                required={true}
                                value={this.state.quantity}
                            />

                        </div>
                        <div className="form-group">
                            <label>Note</label>
                            <br/>
                            <textarea
                                className="form-control"
                                name={"comment"}
                                onChange={(e) => this.handleInput(e)}
                                required={true}
                                value={this.state.comment}
                            />

                        </div>

                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn-primary form-control"
                            >Submit
                            </button>
                        </div>

                    </form>


                </Dialog>
            </MuiThemeProvider>
        )

    }


}
