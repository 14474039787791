import React, {Component} from 'react';
import {Router, Route, Switch} from 'react-router';
import createHistory from 'history/createHashHistory';

import {Provider} from 'react-redux';
import { createStore,applyMiddleware } from 'redux';
import logger from 'redux-logger'
import rootReducer from './reducers';

import loginScreen from './container/con-login';
import registerScreen from './container/con-register';
import pageNotFindScreen from './container/con-401';
import homeScreen from './container/con-home';



import ScreenUtilMaintenanceActivityList from './container/con-util-maintenance-activity-list';
import ScreenUtilMaintenanceDatabaseOption from './container/con-util-maintenance-database-category';


import ScreenSettingPlanning from './container/con-setting-planning';

import ScreenFleetCollectionMileage from "./container/con-fleet-collection-mileage";

import ScreenFleetDashboardTaskDue from "./container/con-fleet-dashboard-taskdue";
import ScreenFleetDashboardVehicle from "./container/con-fleet-dashboard-vehicle";
import ScreenFleetDashboardExpense from "./container/con-fleet-dashboard-expense";

import ScreenFleetFinanceInvoice from "./container/con-fleet-finance-invoice";

import ScreenFleetScheduleJobs from "./container/con-fleet-schedule-jobs";
import ScreenFleetFaults from  "./container/con-fleet-faults";

import ScreenFleetPartsInventory from "./container/con-parts-inventory";
import ScreenSettingModelAttributes from "./container/con-setting-model-attributes";
import ScreenSettingModelDatabases from "./container/con-setting-model-databases";

import ScreenFleetJobExpense from "./container/con-fleet-job-expense";

import ScreenFuelSpec from "./component/fuel/com-fuel-spec";
import ScreenFuelFilling from "./component/fuel/com-fuel-fillingUp";
import ScreenFuelUsage from "./component/fuel/com-fuel-usage";

const history = createHistory();
const store = createStore(
    rootReducer,
    applyMiddleware(logger)
);

class App extends Component {
  render() {
    return (
        <Provider store={store}>
          <Router history={history}>
            <Switch>

              <Route exact path="/" component={loginScreen}/>
              <Route path="/login" component={loginScreen}/>
              <Route path="/register" component={registerScreen}/>
              <Route path="/home" component={homeScreen}/>

              <Route path="/util-maintenance-activity-list" component={ScreenUtilMaintenanceActivityList}/>
              <Route path="/util-maintenance-database-option" component={ScreenUtilMaintenanceDatabaseOption}/>

\
              <Route path="/setting-planning" component={ScreenSettingPlanning}/>
              <Route path="/fleet-collection-mileage" component={ScreenFleetCollectionMileage}/>
              <Route path="/fleet-dashboard-task-due" component={ScreenFleetDashboardTaskDue}/>
              <Route path="/fleet-dashboard-vehicle" component={ScreenFleetDashboardVehicle}/>
              <Route path="/fleet-dashboard-expense" component={ScreenFleetDashboardExpense}/>
              <Route path="/fleet-finance-invoice" component={ScreenFleetFinanceInvoice}/>
              <Route path="/fleet-schedule-jobs" component={ScreenFleetScheduleJobs}/>

              <Route path="/fleet-faults" component={ScreenFleetFaults}/>

              <Route path="/parts-inventory" component={ScreenFleetPartsInventory}/>
              <Route path="/fleet-job-expense" component={ScreenFleetJobExpense}/>

              <Route path="/setting-model-attributes/:model" component={ScreenSettingModelAttributes}/>
              <Route path="/setting-model-databases/:model" component={ScreenSettingModelDatabases}/>

              <Route path="/fuel-spec" component={ScreenFuelSpec}/>
              <Route path="/fuel-filling" component={ScreenFuelFilling}/>
              <Route path="/fuel-usage" component={ScreenFuelUsage}/>



              <Route component={pageNotFindScreen}/>
            </Switch>
          </Router>
        </Provider>

    );
  }
}

export default App;

/*
<div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
 */
