import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import "../../asset/css/fieldset-lengend.css";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";
import {GetSectionFromDatabaseEntityOptions, GetJobListOption} from "../generic/find-data";


export default class CompPopupManageIssue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openStatus: true,
            tasks: [],
            currentTask: "",
        }
    }


    handleInput = async (e) => {
        let tmp = this.state;
        let key = e.target.name;
        tmp[key] = e.target.value;

        this.setState(tmp)
    }

    handleState = async (e) => {
        let tmp = this.state;
        let key = e.target.name;
        tmp[key] = e.target.value;
        this.setState(tmp)
    }
    handleListItem = (e, key) => {
        let tmp = this.state;
        tmp[key].push(e.target.value);
        this.setState(tmp)
    }
    addTask = (key, keyList) => {
        let tmp = this.state;
        if (tmp[key] !== "") {
            tmp[keyList].push(tmp[key]);
            tmp[key] = "";
            this.setState(tmp)
        } else {
            alert("please provide task name")
        }
    }
    removeListItem = (itemIn, key) => {
        let tmp = this.state;
        let ls = [];
        for (let i in tmp[key]) {
            const item = tmp[key][i];
            if (item !== itemIn) {
                ls.push(item);
            }
        }
        tmp[key] = ls;
        this.setState(tmp)
    }

    submitSchedule = async (e) => {
        e.preventDefault();

        const fnClose = this.props.popFnClose;
        let _this = this;
        const parentProps = this.props._this;
        let tasks = {};
        let hasTask=false;
        for (let i in this.state.tasks) {
            const key = this.state.tasks[i]
            tasks[key] = "0";
            hasTask=true;
        }
        const state = this.state;
        let hub = {
            Org: GetUserToken().OrgCode,
            Username: GetUserToken().Username,
            JobFrom: "planning",
            Vehicle: state.Vehicle,
            JobNumber: parseFloat(state.JobNumber),
            RequiredOn: state.RequiredOn,
            RequiredBy: state.RequiredBy,
            RequiredStatus: state.RequiredStatus,
            SourceLabour: state.SourceLabour,
            PersonInCharge: state.SourceLabour === "internal" ? state.AssignTo : "",
            VendorInCharge: state.SourceLabour !== "internal" ? state.AssignTo : "",
            SendNotification: "Yes",
            Tasks: tasks,
            Mileage: parseFloat(state.Mileage),
            Note: state.Note,

        }

        if(!hasTask){
            return alert("Sorry you can't submit without Job Card!")
        }
        if (!window.confirm("Are you sure that you want to schedule this Job?")) {
            return
        }
        
        let backend = eConfig.mainBackend;
        let endpoint = "/api/fleet/job/schedule/new";

        console.log("submitSchedule obj *** send > ", hub);
        console.log("submitSchedule *** send > ", hub);


        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitSchedule *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {

                    parentProps.loadInitialData();
                    alert("Thank you, Job schedule submitted");
                    fnClose()
                }
            }

        });
    }

    GetJobListOption = () => {
        let _this=this.props._this;
        if(this.state.Vehicle !==""){
            return GetJobListOption(_this.state.AssignJobs,this.state.Vehicle)
        }
        return  GetJobListOption(_this.state.AssignJobs);
    }
    getAssignInCharge = () => {
        const _this = this.props._this;
        let o = [];
        if (this.state.SourceLabour === "internal") {
            o = GetSectionFromDatabaseEntityOptions(
                _this.state.Personnel,
                _this.state.PersonnelAttributes,
                "names",
                null,
                "array"
            );
        }
        if (this.state.SourceLabour === "external") {
            o = GetSectionFromDatabaseEntityOptions(
                _this.state.Suppliers,
                _this.state.SupplierAttributes,
                "names",
                null,
                "array"
            );
        }
        return o;
    }
    getVehicleList = () => {
        let ls = [];
        const vehicles = this.props.vehicles;
        for (let i in vehicles) {
            const row = vehicles[i];
            ls.push({
                key: row.ref,
                val: row.ref,
            })
        }
        return ls;
    }
    getStatusList = () => {
        let ls = [];
        const options = this.props.options;
        const keyOne = "position";
        options.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)

        console.log("getStatusList -> ", options);
        for (let i in options) {
            const row = options[i];
            if (row.category === "status" && row.tableref === "vehicle") {
                console.log("getStatusList 222 -> ", row);
                ls.push({
                    key: row.name,
                    val: row.name.toLocaleUpperCase()
                })
            }
        }

        return ls;

        /*
        category: "priority"
​​
color: "red"
​​
date: "2019-11-21"
​​
id: "76e1a1b9-fe58-4336-bfdc-2a2c577767a3"
​​
level: ""
​​
name: "high"
​​
org: "easipath"
​​
orgdatetime: "2019-11-21 12:06:05"
​​
position: "3"
​​
profile: null
​​
score: ""
​​
status: "active"
​​
tableref: "vehicle"
​​
time: "12:06:05"
​​
type: "issue"
         */
    }

    renderListItem = (keyIn) => {

        return (
            <div className={""} style={{maxHeight: 200, minHeight: 200}}>
                <div className={"row"}>
                    <div className={"col-lg-10"}>
                        <input type="text"
                               className="form-control"
                               placeholder="Type task name..."
                               name={"currentTask"}
                               onChange={(e) => this.handleState(e)}
                               value={this.state.currentTask}
                        />
                    </div>
                    <div className={"col-lg-1"}>
                        <button
                            type="button"
                            className="btn  pull-left"
                            style={{marginTop: 0, marginLeft: -20}}
                            onClick={() => this.addTask("currentTask", "tasks")}
                        >Add
                        </button>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-lg-12"}>
                        <ul
                            style={{minHeight: 170, maxHeight: 150, overflow: "scroll", listStyle: "none"}}
                            className="table"
                        >

                            {this.state[keyIn].map((item) => {
                                return (
                                    <li style={{decoration: "none"}}>
                                        <span style={{width: 20}}>
                                            <a
                                                style={{color: "red", cursor: "pointer"}}
                                                onClick={() => this.removeListItem(item, "tasks")}
                                            ><i className={"fa fa-trash-o"}/></a>
                                        </span>
                                        &nbsp;&nbsp;
                                        <span>
                                            {item}
                                        </span>
                                    </li>
                                )
                            })}

                        </ul>
                    </div>
                </div>
            </div>
        )

    }
    boxSelectOption = (label, name, required, options, handFunc, smallText) => {
        if (typeof options === "undefined") {
            options = [];
        }
        return (
            <div className="form-group row" style={{width: "100%"}}>
                <label style={{color: "gray"}}>{label}</label>
                <select
                    name={name}
                    className="form-control"
                    required={required}
                    onChange={(e) => handFunc(e)}
                >
                    <option value={""}>Choose...</option>
                    {options.map((row, index) => {
                        return (
                            <option key={index} value={row.key}>{row.val}</option>
                        )
                    })}

                </select>
                {smallText !== "" ?
                    <small className="form-text text-muted">{smallText}
                        else.
                    </small> : null
                }
            </div>
        )
    }
    boxTextareaVertical = (label, name, type, placeholder, required, handFunc, smallText) => {
        return (
            <div className="form-group row">
                <label style={{color: "gray"}}>{label}</label>
                <textarea
                    name={name}
                    type={type}
                    className="form-control"
                    aria-describedby={placeholder}
                    required={required}
                    onChange={(e) => handFunc(e)}
                />
                {smallText !== "" ?
                    <small className="form-text text-muted">{smallText}
                        else.
                    </small> : null
                }
            </div>
        )
    }
    boxInputVertical = (label, name, type, placeholder, required, handFunc, smallText) => {
        return (
            <div className="form-group row">
                <label style={{color: "gray"}}>{label}</label>
                <input
                    name={name}
                    type={type}
                    className="form-control"
                    aria-describedby={placeholder}
                    required={required}
                    onChange={(e) => handFunc(e)}
                />
                {smallText !== "" ?
                    <small className="form-text text-muted">{smallText}
                        else.
                    </small> : null
                }
            </div>
        )
    }

    render() {

        const title = this.props.popTitle;
        const _this = this.props._this;
        const openStatus = this.props.popStatus;
        const fnClose = this.props.popFnClose;
        const vehicle = this.props.vehicle;

        if (vehicle === null) {
            return null
        }


        const actions = [
            <FlatButton
                label={"Close"}
                primary={true}
                onClick={fnClose}
            />,
        ];

        return (
            <MuiThemeProvider>
                <Dialog
                    title={"New Job Schedule"}
                    actions={actions}
                    modal={false}
                    fullWidth={true}
                    fullScreen
                    open={openStatus}
                    onRequestClose={fnClose}
                    autoScrollBodyContent={true}
                    style={{marginTop: 100, minWidth: 500, maxWidth: "none"}}
                >

                    <form onSubmit={this.submitSchedule}>
                        <div className={"container"}>
                            <div className={"row"}>
                                <div className={"col-lg-12"}>
                                    <br/>
                                    <p
                                        className={"alert alert-info"}
                                        style={{
                                            textAlign: "center"
                                        }}
                                    >New Job Schedule</p>
                                </div>
                            </div>

                            <fieldset className="scheduler-border">
                                <legend className="scheduler-border" style={{color: "red"}}>Filter</legend>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        {this.boxSelectOption(
                                            "Vehicle", "Vehicle", true, this.getVehicleList(), this.handleInput, "")}
                                    </div>
                                    <div className={"col"}>

                                        {this.boxSelectOption(
                                            "Job Number",
                                            "JobNumber",
                                            true,
                                            this.GetJobListOption(),
                                            this.handleInput,
                                            ""
                                        )}


                                    </div>
                                </div>
                            </fieldset>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <fieldset className="scheduler-border">
                                        <legend className="scheduler-border" style={{color: "red"}}>Period</legend>
                                        {this.boxInputVertical(
                                            "Date Booked In",
                                            "RequiredOn",
                                            "date",
                                            "YYYY-MM-DD",
                                            true,
                                            this.handleInput,
                                            "")}

                                        {this.boxInputVertical(
                                            "Date Booked Out",
                                            "RequiredBy",
                                            "date",
                                            "YYYY-MM-DD",
                                            true,
                                            this.handleInput,
                                            "")}

                                    </fieldset>
                                </div>
                                <div className={"col"}>
                                    <fieldset className="scheduler-border">
                                        <legend className="scheduler-border" style={{color: "red"}}>Location</legend>

                                        {this.boxSelectOption(
                                            "Category", "SourceLabour", true, [
                                                {key: "internal", val: "Internal"},
                                                {key: "external", val: "External"}
                                            ], this.handleInput, "")}

                                        {this.boxSelectOption(
                                            "Assign To", "AssignTo",
                                            true, this.getAssignInCharge(),
                                            this.handleInput,
                                            ""
                                        )}

                                    </fieldset>
                                </div>
                                <div className={"col"}>
                                    <fieldset className="scheduler-border">
                                        <legend className="scheduler-border" style={{color: "red"}}>Identity</legend>

                                        {this.boxSelectOption(
                                            "Status", "RequiredStatus", true, this.getStatusList(), this.handleInput, "")}
                                        {this.boxTextareaVertical(
                                            "Note",
                                            "Note",
                                            "text",
                                            "",
                                            true,
                                            this.handleInput,
                                            "")}
                                    </fieldset>
                                </div>
                            </div>
                            <div className={"row"}>

                                <div className={"col"}>
                                    <fieldset className="scheduler-border">
                                        <legend className="scheduler-border" style={{color: "red"}}>Create Job Card</legend>
                                        {this.renderListItem("tasks")}
                                    </fieldset>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <button
                                        type={"button"}
                                        onClick={() => fnClose()}
                                        className={"btn btn-danger pull-left"}
                                    >Cancel
                                    </button>
                                    <button
                                        type={"submit"}
                                        className={"btn btn-primary pull-right"}
                                    >Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Dialog>


            </MuiThemeProvider>
        )
    }

}
