import React, {Component} from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {removeEntry} from "./api";

export default class CompPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    renderSingleListView = (data, cols, title,deleteKey,deleteTable,_this) => {
        return (
            <table className={"table"}>
                <thead>
                <tr>
                    {title.toLocaleUpperCase()} Detail
                </tr>
                <tr>
                    {cols.map((item, index) => {
                        return (
                            <th>
                                {item.label}
                            </th>
                        )
                    })}
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {data.map((row, index) => {
                    return (
                        <tr key={"8uiiu"+index}>
                            {cols.map((item) => {
                                return (
                                    <td>
                                        {row[item.field]}
                                    </td>
                                )
                            })}
                            <td>
                                <a
                                    style={{color:"blue",cursor:"pointer"}}
                                    onClick={(e)=>removeEntry(e,row,deleteKey,deleteTable,_this)}
                                >Remove</a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        );
    }
    renderSingleObjectView = (data, cols, title) => {
        let ls = [];
        for (let i in data) {
            ls.push({
                key: i,
                val: data[i]
            })
        }
        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th colSpan={2}>
                        {title.toLocaleUpperCase()} Detail
                    </th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    return (
                        <tr>
                            <th>{row.key}</th>
                            <td>{row.val}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        );
    }

    render() {

        const title = this.props.popTitle;
        const data = this.props.popData;
        const cols = this.props.propCols;
        const openStatus = this.props.popStatus;
        const fnClose = this.props.popFnClose;
        const displayType = this.props.popDisplay;

        const deleteKey = this.props.popupDeleteKey;
        const deleteTable=this.props.popupDeleteTable;
        const _this = this.props._this;

        const actions = [
            <FlatButton
                label={"Close"}
                primary={true}
                onClick={fnClose}
            />,
        ];

        return (
            <MuiThemeProvider>
                <Dialog
                    title={title}
                    actions={actions}
                    modal={false}
                    open={openStatus}
                    onRequestClose={fnClose}
                    autoScrollBodyContent={true}
                >
                    <div className="row container">
                        <div className="col col-xs-12 col-lg-12">
                            {displayType === "single" ?
                                this.renderSingleObjectView(data, cols, title) :
                                this.renderSingleListView(data, cols, title,deleteKey,deleteTable,_this)
                            }
                        </div>
                    </div>

                </Dialog>
            </MuiThemeProvider>
        )
    }
}