import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import {
    handleInput,
    renderList,
    renderForm,
    removeEntry,
    fetchGeneralData
} from "../../api/attributes-databaase";
import {GetUserToken} from "../../api/services";

export default class ComSettingModelAttribute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            newEntry: {},
            showList: true,
            cqlTable: "GeneralTableField",
            cqlTableRef:"vehicle",
            model:"",
            isLoading:false
        }
    }
    componentWillMount() {
        let loc = this.props.location.pathname.replace("/setting-model-attributes/", "");
        console.log("setting-model-models > ",loc)
        this.setState({
            cqlTableRef:loc,
            model:loc
        })
    }

     componentDidMount=async() =>{
        this.setState({isLoading:true});
        await this.loadInitialData();
        this.setState({isLoading:false})
    }

    loadInitialData = async () => {
        let conds = [{
            Key: "org",
            Val: GetUserToken().OrgCode,
            Type: "string"
        },{
            Key: "tableref",
            Val: this.state.cqlTableRef,
            Type: "string"
        }];
        await fetchGeneralData(this, this.state.cqlTable, "Data",conds);
    }
    renderList = () => {
        if(this.state.isLoading){
            return(
                <h1 style={{color:"red"}}>Please wait when loading data .....</h1>
            )
        }

        const keyList = [
            {field: "fieldname", label: "Name"},
            {field: "mandatory", label: "Mandatory"},
            {field: "unique", label: "Unique"},
            {field: "position", label: "Position"},
            {field: "orgdatetime", label: "Last Updated"}
        ];
        const deleteKey=["org","tableref","fieldname"];
        const deleteTable="GeneralTableField";
        const sortKey="position";
        return renderList(this, "Data", keyList,deleteKey,deleteTable, "showList", removeEntry,sortKey)
    }
    renderForm = () => {
        const options = ["yes", "no"];
        const optionsDataType=["string","float"];
        const optionSection=["general","identity","contact","names","email","address"]
        let ls = [

            {field: "TableRef", label: "Table Name", options: options, type: "hidden", required: true,defaultValue:this.state.cqlTableRef},
            {field: "FieldName", label: "Name of Attribute", options: options, type: "text", required: true},
            {field: "Mandatory", label: "Is Mandatory?", options: options, type: "option", required: true},
            {field: "Unique", label: "Unique primary key", options: options, type: "option", required: true},
            {field: "Position", label: "Position (form display order)", options: options, type: "text", required: true ,dataType:"float"},
            {field: "DataType", label: "Data Type(format of your data)",options: optionsDataType, type: "option", required: true},
            {field: "DefaultValue", label: "Default Value", options: options, type: "text", required: false},
            {field: "Section", label: "Display Section", options: optionSection, type: "option", required: true},
            {field: "AutoGenerated", label: "Auto Generated", options: options, type: "option", required: true},
            {field: "SameAs", label: "Same As", options: options, type: "text", required: false},
        ];



        return renderForm(ls, "showList", this.state.cqlTable, this)
    }

    render() {

        let _this = this;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row">
                        <div className="col col-xs-12">
                            <h3>
                                {this.state.model.toLocaleUpperCase()} ATTRIBUTES


                                {this.state.showList ?
                                    <a className={"btn btn-primary pull-right"}
                                       onClick={(e) => handleInput(e, "showList", false, _this)}>Add New Attribute</a>
                                    :
                                    <a className={"btn btn-danger pull-right"}
                                       onClick={(e) => handleInput(e, "showList", true, _this)}>Cancel Adding new
                                        Record</a>
                                }
                            </h3>
                        </div>
                    </div>

                    {this.state.showList ? this.renderList() : this.renderForm()}
                </div>
            </PageContentWallpaper>
        )
    }

}
