import React from "react";
import FlatButton from "material-ui/FlatButton";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "@material-ui/core/Dialog";
import {
    GetModelListOption,
} from "../generic/find-data";
import {
    BoxSelectOption,
    BoxTextareaVertical,
    BoxInputVertical
} from "../generic/input-control-function";
import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";


export default class Labour extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Profile: {},
            currKey: "",
            currVal: "",
        }
    }

    handleInput = (e) => {
        const key = e.target.name;
        const dataType = this.getFieldDataType(key).type;
        let val = e.target.value;
        if (dataType === "number") {
            val = parseFloat(val)
        }
        if (dataType === "checkbox") {
            let storeVal = false;
            if (typeof this.state[key] !== "undefined") {
                storeVal = this.state[key]
            }
            val = !storeVal;
            console.log("checkbox storeVal --> ", storeVal, " > ", this.state[key])
        }
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    handleState = async (e) => {
        let tmp = this.state;
        let key = e.target.name;
        tmp[key] = e.target.value;
        this.setState(tmp)
    }
    closeMe = () => {
        const title = this.props.popTitle;
        const statusKey = this.props.popStatus;
        let status = this.props.state[statusKey];
        this.props.popFnClose(statusKey);
    }
    getFieldDataType = (fieldName) => {
        const dataModel = this.getModelData();
        for (let sessionName in dataModel) {
            const rows = dataModel[sessionName];
            for (let e in rows) {
                const row = rows[e];
                if (row.name === fieldName) {
                    return row;
                }
            }
        }
        return {type: "text"}
    }
    buildDataFromModelState = () => {
        const state = this.state;
        let hub = {};
        const dataModel = this.getModelData();
        for (let sessionName in dataModel) {
            const rows = dataModel[sessionName];
            for (let e in rows) {
                const row = rows[e];
                const key = row.name;
                let val = "";
                if (typeof state[key] === "undefined") {
                    val = row.type === "number" ? parseFloat("0") : 0;
                } else {
                    val = row.type === "number" ? parseFloat(state[key]) : state[key];
                }
                hub[key] = val
            }
        }

        return hub;
    }
    getWorkerBasedOnSource=()=>{
        const ls=this.getPersonnelList();
        if(typeof this.state.Source ==="undefined"){
            return ls
        }
        if(this.state.Source==="external"){
            return this.getSupplierList()
        }
        return ls
    }
    getSupplierList = () => {
        const _this = this.props._this;
        return GetModelListOption(_this, "supplier", "names", "array")
    }
    getPersonnelList = () => {
        const _this = this.props._this;
        return GetModelListOption(_this, "personnel", "names", "array")
    }
    getModelData = () => {
        return {
            "General": [

                {
                    label: "Source",
                    name: "Source",
                    type: "option",
                    placeholder: "Source",
                    required: true,
                    smallText: "",
                    options: [{key:"internal",val:"internal"},{key:"external",val:"external"},],
                },
                {
                    label: "Worker",
                    name: "Worker",
                    type: "option",
                    placeholder: "Worker",
                    required: true,
                    smallText: "",
                    options: this.getWorkerBasedOnSource(),
                },
                {
                    label: "Hours worked",
                    name: "Hours",
                    type: "number",
                    placeholder: "Part number",
                    required: true,
                    smallText: "",
                },
                {
                    label: "Rate/Hour",
                    name: "RateHour",
                    type: "number",
                    placeholder: "Rate/Hour",
                    required: true,
                    smallText: "",
                },{
                    label: "Total Cost",
                    name: "TotalCost",
                    type: "number",
                    placeholder: "Total Cost",
                    required: true,
                    smallText: "",
                },{
                    label: "Reference",
                    name: "Reference",
                    type: "text",
                    placeholder: "Reference",
                    required: true,
                    smallText: "",
                },{
                    label: "Note",
                    name: "Note",
                    type: "textarea",
                    placeholder: "Note",
                    required: true,
                    smallText: "",
                },

            ],

        }
    }
    submitEntryLabour = async (e) => {
        e.preventDefault();
        let entry = this.buildDataFromModelState();
        let hub={
            Data:entry,
            Field:"labour",
            Job:this.props.state.selectedJob
        }
        console.log("LOGGER SUBMIT :> ", hub);

        const fnClose = this.props.fnClose;
        let _this = this;
        const parentProps = this.props._this;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/fleet/expense/new";

        console.log("submitEntryLabout obj *** send > ", hub);

        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitEntryLabout *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    if (dataIn.status !== "OK") {
                        parentProps.loadInitialData();
                        alert("Thank you, Labour Expense submitted");
                        fnClose()
                    }

                }
            }

        });

    }
    renderModelSection = (sessionName) => {
        const data = this.getModelData()[sessionName];
        if (typeof data === "undefined") {
            return null
        }

        return (
            <fieldset className="scheduler-border">
                <legend className="scheduler-border" style={{color: "red"}}>Labour</legend>
                <div className={"row"}>
                    <div className={"col"}>
                        {data.map((row, index) => {
                            if (row.type === "option") {
                                //(label, name, required, options, handFunc, smallText)
                                return BoxSelectOption(
                                    row.label,
                                    row.name,
                                    row.required,
                                    row.options,
                                    this.handleInput,
                                    row.smallText
                                )
                            }
                            if (row.type === "textarea") {
                                //(label, name, type, placeholder, required, handFunc, smallText)
                                return BoxTextareaVertical(
                                    row.label,
                                    row.name,
                                    row.type,
                                    row.placeholder,
                                    row.required,
                                    this.handleInput,
                                    row.smallText
                                )
                            }


                            return (
                                BoxInputVertical(
                                    row.label,
                                    row.name,
                                    row.type,
                                    row.placeholder,
                                    row.required,
                                    this.handleInput,
                                    row.smallText
                                )
                            )
                        })}
                    </div>
                </div>
            </fieldset>
        )
    }
    render(){
        return(
            <div className={"container"}>
                <form onSubmit={this.submitEntryLabour}>
                    <div className={"row"}>
                        <div className={"col col-log-6"}>
                            {this.renderModelSection("General")}
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col col-log-12"}>

                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col col-log-6"}>
                            <button type={"submit"} className={"btn btn-primary pull-right"}>
                                Submit
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        )
    }

}
