import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {FindImagesAny} from "../generic/find-data";
import {ServiceDataLoadderSubscribe} from "../../api/service-data-loader"
import {removeEntry} from "../generic/find-data";
import PopupNewSpec from "./popup-add-spec";

import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../common/redux-dispatch-to-props";
import Wallpaper from "../common/ef-wallper";
import {getValue, renderForm, setValue, setValueBox} from "../setting-maintenance/api";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";


class ComFuelFillingUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPart: null,
            showUploadImage: false,
            showAddSpec: false,
            newEntry: {},
            cqlTableRef: "FillingFuel"
        }

    }

    componentDidMount = async () => {
        await this.loadInitialData();
    }

    loadInitialData = async () => {
        await ServiceDataLoadderSubscribe(this, [
            "vehicle",
            "driver",
            "fuel-spec",
            "files"
        ])
    }
    openPopup = (stateKey, selectedDataKey, selectedDataValue) => {
        let tmp = this.state;
        tmp[stateKey] = true;
        if (typeof selectedDataKey !== "undefined") {
            tmp[selectedDataKey] = selectedDataValue;
        }
        this.setState(tmp);

    }
    closePopup = (stateKey, selectedDataKey) => {
        this.setState({
            [stateKey]: false,
            [selectedDataKey]: null
        })
    }
    setParentStateKey = (key, val) => {
        this.setState({
            [key]: val,
        })
    }

    removeEntry = async (e, row) => {
        const table = "SpecFuel";
        const deleteKey = ["org", "vehicle"];
        await removeEntry(e, row, deleteKey, table, this)
    }
    submitEntry = async (e, table, addData, _this) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        let entry = {..._this.state.newEntry};
        entry.Org = GetUserToken().OrgCode;
        for (let i in addData) {
            const row = addData[i];
            if (typeof row.type !== "undefined") {
                if (row.type === "hidden") {
                    entry[row.field] = row.defaultValue;
                }
                if (row.type === "number") {
                    entry[row.field] = parseInt(entry[row.field]);
                }
                if (row.type === "float") {
                    entry[row.field] = parseFloat(entry[row.field]);
                }
            }
        }
        entry.CompleteFillUp = entry.CompleteFillUp==="yes";
        //todo let add our default variable

        let hub = entry;
        hub.Org = GetUserToken().OrgCode;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/fuel/filling/new";

        console.log("submitEntry *** send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    alert("Thank you Record submitted!");
                    let tmp = _this.state;
                    tmp.showAddSpec = false;
                    tmp.newEntry={};
                    _this.setState(tmp);
                    _this.loadInitialData();
                }
            }

        });
    }
    getOptions = (entityIn, cat) => {
        //todo define props keys
        const entityData = entityIn + "s";
        const entityAttributes = entityIn + "Attributes";

        //todo get props data
        const dataAttribute = (typeof this.state[entityAttributes] !== "undefined" ? this.state[entityAttributes] : []);
        const dataEntity = (typeof this.state[entityData] !== "undefined" ? this.state[entityData] : []);


        let keyList = [];
        for (let i in dataAttribute) {
            if (dataAttribute[i].section === cat) {
                keyList.push(dataAttribute[i].fieldname);
            }
        }

        let ls = [];
        for (let e in dataEntity) {
            const rowParrent = dataEntity[e];
            const key = rowParrent.ref;
            let val = "";
            let x = 0;
            for (let i in keyList) {
                const field = keyList[i];
                const v = rowParrent.data[field] || "--"

                val += x == 0 ? " " + v : ", " + v
            }
            ls.push({
                key: key,
                val: val
            })
        }

        return ls

    }
    getVehicleOption = () => {
        const vehicles = this.state.Vehicles;
        if (typeof vehicles === "undefined") {
            return []
        }
        let ls = [];
        vehicles.map((row) => {
            ls.push({key: row.ref, val: row.ref});
        });
        return ls
    }
    getDriverOption = () => {
        const vehicles = this.state.Vehicles;
        if (typeof vehicles === "undefined") {
            return []
        }
        let ls = [];
        vehicles.map((row) => {
            ls.push(row.ref);
        });
        return ls
    }
    renderPartList = () => {

        let ls = [];
        if (typeof this.state.FillingFuel !== "undefined") {
            ls = this.state.FillingFuel
        }

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>Reg.#</th>
                    <th>Qty</th>
                    <th>Cost/Unit</th>
                    <th>Date</th>
                    <th>Mileage</th>
                    <th>Is Full?</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    let link = FindImagesAny(row.vehicle, this.state.Files, "vehicle");

                    return (
                        <tr key={index}>
                            <td width={80}>
                                <img src={link} width={"100%"}/>
                            </td>
                            <td>{row.vehicle}</td>
                            <td>{row.quantity}</td>
                            <td>{row.costunit + " / " + row.unit}</td>
                            <td>{row.refueldate}</td>
                            <td>{row.mileage}</td>
                            <td>{row.completefillup ? "Yes" : "No"}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )

    }

    renderForm = () => {
        const vehicles = this.getVehicleOption();// this.getOptions("Vehicle","general");
        const drivers = this.getOptions("Driver", "names");
        console.log("1 @@@--> ", vehicles);
        console.log("2 @@@--> ", drivers);

        let ls = [
            {
                field: "vehicle",
                label: "Vehicle",
                options: vehicles,
                type: "option",
                required: true
            },
            {
                field: "Driver",
                label: "Driver",
                options: drivers,
                type: "option",
                required: true
            },
            {
                field: "Quantity",
                label: "Quantity",
                options: [],
                type: "number",
                required: true
            }, {
                field: "CostUnit",
                label: "CostUnit",
                options: [],
                type: "float",
                required: true
            },
            {
                field: "Mileage",
                label: "Mileage",
                options: [],
                type: "number",
                required: true
            },
            {
                field: "RefuelDate",
                label: "Refuel Date",
                options: [],
                type: "date",
                required: true
            },
            {
                field: "TaxInvoice",
                label: "Tax Invoice",
                options: [],
                type: "string",
                required: false
            }, {
                field: "Reference",
                label: "Reference",
                options: [],
                type: "string",
                required: false
            }, {
                field: "Origin",
                label: "Origin",
                options: [
                    {key: "Fuel Tank", val: "Fuel Tank"},
                    {key: "Vendor", val: "Vendor"},
                    {key: "N/D", val: "N/D"},
                ],
                type: "option",
                required: true
            },
            {
                field: "CompleteFillUp",
                label: "Complete Fill Up?",
                options: [
                    {key: "yes", val: "Yes"},
                    {key: "no", val: "No"},
                ],
                type: "option",
                required: true
            },


        ];

        let _this = this;

        return (
            <div className={"container"}>
                <p
                    className={"alert alert-success"}>
                    FUEL SPEC [New Record form]
                    <a
                        onClick={() => this.setState({showAddSpec: false})}
                        className={"pull-right"}
                        style={{color: "red", cursor: "pointer"}}
                    >Cancel
                    </a>
                </p>
                <form onSubmit={(e) => this.submitEntry(e, "SpecFuel", ls, _this)}>
                    {ls.map((row, index) => {
                        let dataType = "string";
                        if (typeof row.dataType !== "undefined") {
                            dataType = row.dataType;
                        }
                        if (row.type === "option") {
                            return (
                                <div className="row" key={"zty" + index}>
                                    <div className="form-group col-md-12 col-lg-12">
                                        <label htmlFor="inputState">{row.label}</label>
                                        <select
                                            datatype={dataType}
                                            name={row.field}
                                            className="form-control"
                                            onChange={(e) => setValueBox(e, _this, dataType)}
                                            defaultValue={getValue(row.field, _this)}
                                            required={row.required}
                                        >
                                            <option value="" selected>Choose...</option>
                                            {row.options.map((item, index2) => {
                                                let key = item.key;
                                                let desc = item.val;
                                                if (typeof desc !== "undefined") {
                                                    desc = desc.toLocaleUpperCase()
                                                }
                                                return (
                                                    <option key={index2} value={key}>{desc}</option>
                                                )
                                            })}

                                        </select>
                                    </div>
                                </div>
                            )
                        }

                        if (row.type === "hidden") {
                            //setValueHidden(row.defaultValue, row.field,_this,dataType)
                            return (
                                <div className="row" key={"zty" + index} style={{display: true}}>
                                    <div className="form-group col-md-12  col-lg-12">
                                        <label htmlFor="inputState">{row.label}</label>
                                        <input
                                            datatype={dataType}
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => setValue(e, row.field, _this, dataType)}
                                            value={row.defaultValue}
                                            required={row.required}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            )
                        }

                        if (row.type === "date") {
                            return (
                                <div className="row" key={"zty" + index} style={{display: true}}>
                                    <div className="form-group col-md-12  col-lg-12">
                                        <label htmlFor="inputState">{row.label}</label>
                                        <input
                                            datatype={dataType}
                                            type={row.type }
                                            className="form-control"
                                            onChange={(e) => setValue(e, row.field, _this, dataType)}
                                            value={row.defaultValue}
                                            required={row.required}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        if (row.type === "float"){
                            return (
                                <div className="row" key={"zty" + index}>
                                    <div className="form-group col-md-12  col-lg-12">
                                        <label htmlFor="inputState">{row.label}</label>
                                        <input
                                            datatype={dataType}
                                            type={"number" }
                                            step={"0.01"}
                                            className="form-control"
                                            onChange={(e) => setValue(e, row.field, _this, dataType)}
                                            value={getValue(row.field, _this)}
                                            required={row.required}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div className="row" key={"zty" + index}>
                                <div className="form-group col-md-12  col-lg-12">
                                    <label htmlFor="inputState">{row.label}</label>
                                    <input
                                        datatype={dataType}
                                        type={row.type }
                                        className="form-control"
                                        onChange={(e) => setValue(e, row.field, _this, dataType)}
                                        value={getValue(row.field, _this)}
                                        required={row.required}
                                    />
                                </div>
                            </div>
                        )
                    })}
                    <button
                        type={"submit"}
                        className={"btn btn-default pull-right "}
                        style={{backgroundColor: " #00b3ee"}}
                    > Submit
                    </button>
                </form>
            </div>
        );

    }

    openForm = () => {
        let tmp = this.state;
        tmp.showAddSpec = true;
        this.setState(tmp);
        console.log("(:)-->openForm--> ", this.state.showAddSpec);
    }

    render() {

        return (
            <PageContentWallpaper {...this.props} >
                <div>
                    {!this.state.showAddSpec && (
                        <div className="row">
                            <div className="col col-xs-12">
                                <h4
                                    className={"alert alert-info"}
                                    style={{color: "gray", fontSize: 18, textAlign: "center"}}
                                >
                                    <i className={"fa fa-setting"}>
                                        &nbsp;
                                    </i>&nbsp;
                                    {"Fuel Filling".toLocaleUpperCase()}
                                    <small>
                                        <a
                                            onClick={() => this.openForm()}
                                            className={"pull-right"}
                                            style={{color: "#33b8ff", cursor: "pointer"}}
                                        >Add New
                                        </a>
                                    </small>
                                </h4>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col col-xs-12">
                            {!this.state.showAddSpec ? this.renderPartList() : this.renderForm()}
                        </div>
                    </div>
                </div>
            </PageContentWallpaper>
        )
    }


}


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(class ConFuelFilling extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        return (
            <Wallpaper {...this.props} hasHeader={true} hasFooter={true}>
                <ComFuelFillingUp {...this.props}/>
            </Wallpaper>
        );
    }
})
