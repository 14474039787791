import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import {
    fetchUtilData,
} from "./api";

import {ColumnChart, LineChart, PieChart} from 'react-chartkick';
import 'chart.js';

export default class ComFleetDashboardVehicle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            cqlTable: "Expenses",
            cqlTableRef: "vehicle",
        }
    }

    async componentDidMount() {
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await fetchUtilData(this, this.state.cqlTable, "Data", this.state.cqlTableRef);
    }

    chartData=()=>{
        const data=[
            {key:"Mon",val:2500},
            {key:"Tue",val:1500},
            {key:"Wed",val:2960},
            {key:"Thu",val:1830},
            {key:"Fri",val:3500},
            {key:"Sat",val:3230},
            {key:"Sun",val:2500},
        ]

        let ls=[];
        for(let i in data){
            const row=data[i];
            const key=row.key;
            const val=row.val;
            ls.push([
                key,val
            ])
        }

        console.log("-=-=-=> ",ls)

        return ls;

        //  [["Sun", 32], ["Mon", 46], ["Tue", 28]]
    }
    renderOverviewSection = () => {

        let ls = [
            {
                label: "Cost fuel",
                cost: 1500,
                targetCost: 2500,
                currency: "$",
                icon: "fa fa-filter"
            },
            {
                label: "Cost labour",
                cost: 27000,
                targetCost: 33000,
                currency: "$",
                icon: "fa fa-wrench"
            },
            {
                label: "Cost parts",
                cost: 12563,
                targetCost: 11500,
                currency: "$",
                icon: "fa fa-automobile"
            },
        ];

        const calculatePercentage = (currerntCost, targetCost) => {
            let percentage = 100;
            let bg = "bg-danger";
            let color = "red";
            if (parseFloat(currerntCost) > parseFloat(targetCost)) {
                return {percentage, bg, color};
            }
            if (parseFloat(currerntCost) === 0) {
                return 0, "bg-primary", "blue";
            }
            percentage = (currerntCost / targetCost) * 100;
            percentage = percentage.toFixed(0);

            if (percentage < 79) {
                bg = "bg-primary";
                color = "blue";
            }
            if (percentage > 80 && percentage < 99) {
                bg = "bg-warning";
                color = "yalow";
            }
            if (percentage > 100) {
                bg = "bg-danger";
                color = "red";
            }
            return {percentage, bg, color};
        }

        return ls.map((row, index) => {
            let {percentage, bg, color} = calculatePercentage(row.cost, row.targetCost);
            return (
                <React.Fragment >


                    <div className={"row"} style={{marginTop:20,borderColor:"red",borderWidth:2,borderRadius:5}}>
                        <div className={"col col-lg-2"}>
                            <i className={row.icon+" pull-left"} style={{color: color,fontSize:32,marginTop:10,marginRight:20}}>&nbsp;</i>
                        </div>
                        <div className={"col col-lg-10"}>
                            <div
                                style={{
                                    color: "gray",
                                    fontSize: 20,
                                }}
                            >
                                {row.currency} {row.cost}
                                <span style={{
                                    fontSize: 14,
                                    marginLeft: 10
                                }}>
                                    {row.label}
                                                </span>
                            </div>
                            <div className="progress">
                                <div
                                    className={"progress-bar " + bg}
                                    role="progressbar"
                                    aria-valuenow={percentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{"width": percentage + "%", color: color}}
                                >{percentage}%
                                </div>
                            </div>
                        </div>
                    </div>


                </React.Fragment>
            )
        })


    }

    render() {

        let _this = this;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row">
                        <div className="col col-xs-12">
                            <h4
                                className={"alert alert-info"}
                                style={{color:"gray",fontSize:18,textAlign:"center"}}
                            >
                                {"Expense Dashboard".toLocaleUpperCase()}
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12">

                            <fieldset className="scheduler-border">
                                <legend className="scheduler-border">Expense Period <small>(filter)</small></legend>

                                <form>
                                    <div className="row">
                                        <div className="col">
                                            <input type="date" className="form-control" placeholder="Start Date" />
                                        </div>
                                        <div className="col">
                                            <input type="date" className="form-control" placeholder="End Date" />
                                        </div>
                                        <div className="col">
                                            <select className="form-control"
                                                    placeholder="End Date" >
                                                <option value={""}>Choose Vehicle...</option>
                                                <option value={"all"}>-All-</option>
                                                <option value={"CA 789 987"}>CA 987 789</option>
                                                <option value={"CA 778 365"}>CA 778 365</option>
                                            </select>
                                        </div>
                                        <div className="col">
                                            <select className="form-control"
                                                    placeholder="End Date" >
                                                <option value={""}>Group By</option>
                                                <option value={"day"}>Day</option>
                                                <option value={"week"}>Week</option>
                                                <option value={"month"}>month</option>
                                                <option value={"month"}>Year</option>
                                            </select>
                                        </div>

                                        <div className="col">
                                            <button type="text" className="form-control">
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                </form>


                            </fieldset>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-xs-12 col-lg-12">

                            <div className="" style={{"width": "100%"}}>

                                <fieldset className="scheduler-border">
                                    <legend className="scheduler-border">Expense Period Result</legend>

                                <div className="">

                                    <div className="row">
                                        <div className="col col-xs-12 col-lg-8">
                                            <fieldset className="scheduler-border">
                                                <legend className="scheduler-border">Chart</legend>
                                            <LineChart data={this.chartData()} />
                                            </fieldset>

                                        </div>
                                        <div className="col col-xs-12 col-lg-4" >
                                            <fieldset className="scheduler-border" style={{minHeight:"95%"}}>
                                                <legend className="scheduler-border">Cost entries period</legend>
                                            {this.renderOverviewSection()}
                                            </fieldset>
                                        </div>
                                    </div>

                                </div>

                                </fieldset>


                            </div>

                        </div>
                    </div>

                    {this.state.showList ? this.renderList() : null}


                </div>
            </PageContentWallpaper>
        )
    }


}