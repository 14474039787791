import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";
import React from "react";

const fetchUtilData = async (_this,tableName,stateField) => {
    let conds = [{
        Key: "org",
        Val: GetUserToken().OrgCode,
        Type: "string"
    }];
    await fetchingAll(_this,tableName, conds, (data) => {
        //console.log("fetchUtilData :> ", data);
        _this.setState({
            [stateField]: data
        });

    });
}
const fetchingAll = async (_this,table, conds, callback) => {
    let hub = {
        Fields: [],
        DbName: "fleetminder",
        TableName: table,
        Conditions: conds,
    }
    let backend = eConfig.mainBackend;
    let endpoint = "/api/global-service/list";

    console.log("fetchingAll send > ", table, hub);
    await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
        //console.log("fetchingAll response > ", table, dataIn);
        if(dataIn !==null){
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        }

    });
}

const handleInput = (e, key, val,_this) => {
    e.preventDefault();
    let tmp = _this.state;
    tmp[key] = val;
    _this.setState(tmp);
}
const getValue = (key,_this) => {
    return _this.state.newEntry[key]
}
const setValue = (e, key,_this,dataType) => {
    let val = "";
    if (typeof e.target !== "undefined") {
        val = e.target.value;
    }
    const state = _this.state;
    let newEntry = state.newEntry;
    newEntry[key] = val;
    if(dataType==="float"){
        let myNumber=parseFloat(val);
        if(isNaN(myNumber)){
            myNumber = 0
        }
        newEntry[key] = myNumber;
    }
    state.newEntry = newEntry;
    _this.setState(state);
}
const setValueBox = (e,_this,dataType) => {
    const val = e.target.value;
    const key = e.target.name;

    console.log("setValueBox ^^^--> ", key, " > ", val);
    const state = _this.state;
    let newEntry = state.newEntry;
    newEntry[key] = val;
    if(dataType==="float"){
        let myNumber=parseFloat(val);
        if(isNaN(myNumber)){
            myNumber = 0
        }
        newEntry[key] = myNumber;
    }
    state.newEntry = newEntry;
    _this.setState(state);
}
const removeEntry = async (e, row,deleteKey,table,_this) => {
    e.preventDefault();
    if (!window.confirm("Are you sure that you want to remove?")) {
        return
    }
    //Conditions
    let conds = [
        /*{Key: "org", Val: row.org, Type: "string"},
        {Key: "name", Val: row.name, Type: "string"},*/
    ]
    for(let i in deleteKey){
        const key  =deleteKey[i];
        conds.push({
            Key: key,
            Val: row[key],
            Type: "string"
        });
    }
    let hub = {
        Fields: [],
        DbName: "fleetminder",
        TableName: table,
        Conditions: conds,
    }
    let backend = eConfig.mainBackend;
    let endpoint = "/api/global-service/remove";

    console.log("removeEntry send > ", hub);
    await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
        console.log("removeEntry response > ", dataIn);
        if(dataIn !==null) {
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Record  removed!");
                _this.setState({showList: true});
                _this.loadInitialData();
            }
        }
    });
}
const submitEntry = async (e,table,addData,_this) => {
    e.preventDefault();
    if (!window.confirm("Are you sure that you want to submit?")) {
        return
    }

    let entry = {..._this.state.newEntry};
    entry.Org = GetUserToken().OrgCode;
    //todo let add our default variable
    entry.Id = "";
    entry.Status="";
    entry.Date ="";
    entry.Time ="";
    entry.OrgDateTime ="";

    let hub = {
        Fields: [],
        DbName: "fleetminder",
        TableName: table,
        In: entry,
    }
    let backend = eConfig.mainBackend;
    let endpoint = "/api/global-service/add";

    console.log("submitUploadEntry send > ", hub);
    await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
        console.log("submitUploadEntry response > ", dataIn);
        if(dataIn !==null){
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Record submitted!");
                let tmp = _this.state;
                tmp.showList = true;
                _this.setState(tmp);
                _this.loadInitialData();
            }
        }

    });
}

const renderList = (_this,stateFieldStore,keyList,deleteKey,deleteTable,stateFieldShowList,fnRemoveEntry) => {
    let state = _this.state;
    let data = state[stateFieldStore];
    if (!state[stateFieldShowList]) {
        return null
    }

    let ls = data;

    if (ls.length === 0) {
        return (
            <div className={"alert alert-danger"}>Empty list!</div>
        )
    }

    return (
        <div className={"container"}>
            <p className={"alert alert-primary"}>Available Records</p>
            <table
                className={"table"}
                style={{
                    border: '3px solid gray',
                    borderRightColor: 'gray',
                }}
            >
                <thead>

                <tr>
                    {keyList.map((item)=>{
                        return(
                            <td>{item.label}</td>
                        )
                    })}
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {

                    return (
                        <tr key={"xkdkd" + index}>
                            {keyList.map((item)=>{
                                return(
                                    <td>{row[item.field]}</td>
                                )
                            })}
                            <th style={{width: 100}}>
                                <a
                                    onClick={(e) => fnRemoveEntry(e, row,deleteKey,deleteTable,_this)}
                                    className={"pull-right"}
                                    style={{color:"blue",fontSize:16,fontWeight:"bold"}}
                                >
                                    <span className={"fa fa-trash"}>&nbsp;Remove</span>
                                </a>

                            </th>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
const renderForm = (ls,stateFieldShow,cqlTable,_this) => {
    let state = _this.state;
    if (state[stateFieldShow]) {
        return null
    }

    const getRequiredColorSpan=(boo)=>{

        if(boo){
            return(
                <span style={{
                    color:"red",
                    fontSize:18,
                    marginLeft:10
                }}>*</span>
            )
        }
        return null
    }

    return (
        <div className={"container"}>
            <p className={"alert alert-success"}>New Record</p>
            <form onSubmit={(e)=>submitEntry(e,cqlTable,ls,_this)}>
                {ls.map((row, index) => {
                    let dataType="string";
                    if(typeof row.dataType !=="undefined"){
                        dataType = row.dataType;
                    }
                    if (row.type === "option") {
                        return (
                            <div className="row" key={"zty" + index}>
                                <div className="form-group col-md-12 col-lg-12">
                                    <label htmlFor="inputState">{row.label}{getRequiredColorSpan(row.required)}</label>
                                    <select
                                        dataType={dataType}
                                        name={row.field}
                                        className="form-control"
                                        onChange={(e) => setValueBox(e, _this,dataType)}
                                        value={getValue(row.field,_this)}
                                        required={row.required}
                                    >
                                        <option value="" selected>Choose...</option>
                                        {row.options.map((item, index2) => {
                                            let key = item ;
                                            let desc = item.toLocaleUpperCase();
                                            return (
                                                <option key={index2} value={key}>{desc}</option>
                                            )
                                        })}

                                    </select>
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div className="row" key={"zty" + index}>
                            <div className="form-group col-md-12  col-lg-12">
                                <label htmlFor="inputState">{row.label}{getRequiredColorSpan(row.required)}</label>
                                <input
                                    dataType={dataType}
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setValue(e, row.field,_this,dataType)}
                                    value={getValue(row.field,_this)}
                                    required={row.required}
                                />
                            </div>
                        </div>
                    )
                })}
                <button
                    type={"submit"}
                    className={"btn btn-default pull-right "}
                    style={{backgroundColor: " #00b3ee"}}
                > Submit
                </button>
            </form>
        </div>
    )
}

export {
    fetchUtilData,
    handleInput,
    getValue,
    setValue,
    setValueBox,
    removeEntry,
    submitEntry,
    renderList,
    renderForm
}
