import {SAVE_NAVIGATIONS} from '../constant';

const initial = {
    home: {
        desc: "Home", data: [
            {menu: "home", display: "Home"},
        ]
    },
    survey: {
        desc: "Survey",
        data: [
            {menu: "survey-taken", display: "Take"},
            {menu: "survey-schedule", display: "Schedule"},
            {menu: "survey-report", display: "Report"},
        ]
    },
    asset: {
        desc: "Asset",
        data: [
            {menu: "asset-manage", display: "Manage"},
            {menu: "asset-import", display: "Import-CSV"},
            {menu: "asset-survey", display: "Survey"},
            {menu: "asset-datalink", display: "DataLink"},
            {menu: "asset-enumerator-attribute", display: "Enumerator-Attribute"},
            {menu: "asset-enumerator", display: "Enumerator"},
            {menu: "asset-data-extraction", display: "Data-Extraction-Wizard"},
        ]
    },
    setting: {
        desc: "Setting",
        data: [
            {menu: "setting-maintenance-project", display: "Project"},
            {menu: "setting-maintenance-asset", display: "Asset"},
            {menu: "setting-maintenance-asset-attribute", display: "Asset-Attribute"},
            {menu: "setting-maintenance-survey-new", display: "Survey"},
            {menu: "setting-maintenance-survey-question", display: "Survey Question"},
            {menu: "setting-maintenance-asset-requirement", display: "Asset Requirement"},
            {menu: "setting-maintenance-all-setup", display: "All-Setup"},
            ]
    },
    util: {
        desc: "Util",
        data: [
            {menu: "util-asset-category", display: "Asset Category"},
        ]
    },

}


const reduceNavigations = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case SAVE_NAVIGATIONS:
            newState = action.payload;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default reduceNavigations;