import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import {
    fetchUtilData,
    handleInput,
    renderList,
    renderListInner,
    renderForm, removeEntry
} from "./api";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";
import PopupUploadFile from "./popup-upload-file";
import PopupDownloadFile from "./popup-download-file";
import PopupAssignDriver from "./popup-assign-driver";
import PopupIssue from "../flauts/popup-issue";
import PopupEntityDetail from "../generic/entity-quicky-view";

import FindDriverFullName from '../generic/find-driver-name';
import { FindData ,FindImages,FindEntryCounter} from '../generic/find-data';


export default class ComFleetDashboardVehicle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            JobSchedules:[],
            Attributes: [],
            Files: [],
            Drivers:[],
            DriverAttributes:[],
            Personnel:[],
            PersonnelAttributes:[],
            AssignDrivers:[],
            DatabaseOptions:[],
            Faults:[],
            showList: true,
            cqlTable: "GeneralTableData",
            cqlTableRef: "vehicle",
            showUploadVehiclePicture: false,
            selectedVehicle: null,
            selectedFileCategory:"",
            showDownloadFile:false,
            downloadFileList:[],
            showAssignDriver:false,
            showNewIssue:false,
            showEntityDetail:false,
            selectEntityDetailKey:"",
            selectEntityDetailData:null,

        }


    }

    async componentDidMount() {
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await fetchUtilData(this, this.state.cqlTable, "Data", this.state.cqlTableRef);
        await fetchUtilData(this, "GeneralTableField", "Attributes", this.state.cqlTableRef);
        await fetchUtilData(this, "Documents", "Files", this.state.cqlTableRef);
        await fetchUtilData(this, "GeneralTableField", "DriverAttributes", "driver");
        await fetchUtilData(this,  this.state.cqlTable, "Drivers", "driver");
        await fetchUtilData(this, "GeneralTableField", "PersonnelAttributes", "personnel");
        await fetchUtilData(this,  this.state.cqlTable, "Personnel", "personnel");
        await fetchUtilData(this, "UtilDatabaseOptions", "DatabaseOptions", "vehicle");
        await this.fetchVehicleDriver();
        await this.fetchJobScheduleProcessing();
        await this.fetchFaults()
    }

    fetchJobScheduleProcessing=async ()=>{
        let _this = this;
        const user = GetUserToken();
        const hub={
            Org:user.OrgCode,
            /*JobFrom string
            Vehicle string
            JobStatus string*/
            Stage :"processing"
        }

        let backend = eConfig.mainBackend;
        let endpoint = "/api/fleet/job/schedule/list";

        console.log("fetchJobScheduleProcessing send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("fetchJobScheduleProcessing response > ", dataIn);
            if(dataIn===null){
                return null
            }
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    JobSchedules: data
                })

            } else {
               return null
            }
        });


    }
    fetchFaults= async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: GetUserToken().OrgCode,
            Type: "string"
        }];
        await this.fetchingAll("Faults", conds, (data) => {
            console.log("fetchFaults :> ", data);
            //const keyOne = "position";
            /*data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)*/
            _this.setState({
                Faults: data
            });

        });
    }
    fetchVehicleDriver = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: GetUserToken().OrgCode,
            Type: "string"
        }];
        await this.fetchingAll("VehicleDriver", conds, (data) => {
            console.log("fetchVehicleDriver :> ", data);
            //const keyOne = "position";
            /*data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)*/
            _this.setState({
                AssignDrivers: data
            });

        });
    }
    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "fleetminder",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if(dataIn===null){
                callback([]);
                return null
            }
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    onClickVehicleChangePicture = (e) => {
        const vehicle = JSON.parse(e.target.name);
        this.setState({
            selectedVehicle: vehicle,
            selectedFileCategory: e.target.value,
        });
        console.log("&&&&&$$$$$$$--> ", vehicle)
    }
    closeShowUploadFile = () => {
        this.setState({
            showUploadVehiclePicture: false,
        })
    }
    submitEntry = async (e, table, addData, _this) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        let entry = {..._this.state.newEntry};
        entry.Org = GetUserToken().OrgCode;
        for (let i in addData) {
            const row = addData[i];
            if (typeof row.type !== "undefined") {
                if (row.type === "hidden") {
                    entry[row.field] = row.defaultValue;
                }
            }
        }
        //todo let add our default variable

        let hub = {};
        hub.Org = GetUserToken().OrgCode;
        hub.CqlTable = this.state.cqlTableRef;
        hub.InData = [];
        hub.InData.push(entry);

        let backend = eConfig.mainBackend;
        let endpoint = "/api/fleet/generic/data/new";

        console.log("submitUploadEntry obj *** send > ", _this.state.newEntry);
        console.log("submitUploadEntry *** send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitUploadEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    const res = dataIn.RESULT;
                    if (res.HasError) {
                        let errMsg = "";
                        for (let i in res.Errors) {
                            const rowError = res.Errors[i];
                            errMsg = errMsg + "\nError: " + rowError.Error + " -> Row #:" + rowError.RowNumber
                                + ", Field Name: " + rowError.KeyName + "; Field Value: " + rowError.KeyValue
                        }
                        alert("Fail to insert, Total error: " + res.Errors.length + errMsg)
                    } else {
                        alert("Thank you Record submitted!");
                        let tmp = _this.state;
                        tmp.showList = true;
                        _this.setState(tmp);
                        _this.loadInitialData();
                    }
                }
            }

        });
    }
    openPopupIssue = (row) => {

        this.setState({
            showIssue: true,
            selectedVehicle:row,

        })
    }
    closePopupIssue = () => {

        this.setState({
            showIssue: false,
            selectedVehicle:null,

        })
    }
    openUpload = (e) => {
        e.preventDefault();
        this.setState({
            showUploadVehiclePicture: true
        })
    }
    openDownload=(row)=>{
        let ls=[];
        for(let i in this.state.Files){
            const o =this.state.Files[i];
            if(o.ref ===row.ref){
                ls.push(o);
            }
        }
        this.setState({
            showDownloadFile:true,
            downloadFileList:ls,
            selectedVehicle: row,
        })

    }
    openAssignDriver=(vehicle)=>{
        this.setState({
            showAssignDriver:true,
            selectedVehicle:vehicle,
        })
    }
    closeAssignDriver=()=>{
        this.setState({
            showAssignDriver:false,
        })
    }
    closeDownload=()=>{
        this.setState({
            showDownloadFile:false,
            downloadFileList:[],
        })
    }
    closePopupEntityDetail=()=>{
        this.setState({
            showEntityDetail:false,
            selectEntityDetailKey:"",
            selectEntityDetailData:null
        })
    }
    renderList =()=> {
        let ls = this.state.Data;
        const styleListAlert = {
            maxHeight: 20
        }

        let _this =this;

        const setEntityDetailView=(key,dataRow)=>{
            console.log("%^^^^^^> ",key," > ",dataRow)
            /*
        popStatus={this.state.showEntityDetail}
                                popFnClose={this.closePopupEntityDetail}
                                keyRow={this.state.selectEntityDetailKey}
                                dataRow={this.state.selectEntityDetailData}
         */
            _this.setState({
                showEntityDetail:true,
                selectEntityDetailKey:key,
                selectEntityDetailData:dataRow
            })

        }

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th style={{maxWidth: 160, width: 160}}>&nbsp;</th>
                    <th style={{textAlign: "center", maxWidth: 300, width: 300}}>&nbsp;</th>
                    <th style={{textAlign: "center", maxWidth: 150, width: 150}}>&nbsp;</th>
                    <th style={{textAlign: "center", maxWidth: 300, width: 300}}>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {

                    const schInfo = FindEntryCounter(row.ref,this.state.JobSchedules,"Vehicle");
                    const faultInfo = FindEntryCounter(row.ref,this.state.Faults,"vehicle");
                    return (
                        <tr>

                            <td style={{textAlign: "center", maxWidth: 160, width: 160}}>
                                <img src={FindImages(row.ref,this.state.Files)} alt={row.ref} className="img-thumbnail" width={150}/>

                            </td>
                            <td>
                                {FindData(row.data, "identity",this.state.Attributes)}
                                <br/>

                                <a onClick={(e) => this.openAssignDriver(row)}>
                                    <il className={"fa fa-plus"} style={{fontSize: 18, color: "blue"}}>&nbsp;</il>
                                </a>
                                &nbsp;|&nbsp;&nbsp;
                                <il className={"fa fa-user"}>&nbsp;</il>

                                <span style={{color: "gray"}}>{FindDriverFullName({
                                    ref:row.ref,
                                    Drivers:this.state.Drivers,
                                    DriverAttributes:this.state.DriverAttributes,
                                    AssignDrivers:this.state.AssignDrivers
                                })||"---------"}</span>
                                <br/>


                                <a onClick={(e) => this.openDownload(row)}>
                                    <il className={"fa fa-download"} style={{fontSize: 18, color: "blue"}}>&nbsp;</il>
                                </a>
                                &nbsp;|&nbsp;&nbsp;
                                <il className={"fa fa-file"}>&nbsp;</il>
                                <span style={{color: "gray"}}>Files</span>

                                <br/>

                                <a onClick={() => this.openPopupIssue(row)}>
                                    <il className={"fa fa-plus"} style={{fontSize: 18, color: "blue"}}>&nbsp;</il>
                                </a>
                                &nbsp;|&nbsp;&nbsp;
                                <il className="fa pen-fancy">&nbsp;</il>

                                <span style={{color: "gray"}}>Log a Fault</span>
                                <br/>


                            </td>


                            <td>
                                <ul className="list-group">
                                    <li style={styleListAlert}
                                        className="list-group-item d-flex justify-content-between align-items-center">
                                        Jobs
                                        <span className="badge badge-primary badge-pill">0</span>
                                    </li>
                                    <li style={styleListAlert}
                                        className="list-group-item d-flex justify-content-between align-items-center">
                                        Faults
                                        <span className="badge badge-primary badge-pill">{faultInfo.total}</span>
                                    </li>
                                    <li style={styleListAlert}
                                        onClick={()=>setEntityDetailView("JobNumber",schInfo.list)}
                                        className="list-group-item d-flex justify-content-between align-items-center">
                                        Schedule
                                        <span className="badge badge-primary badge-pill">{schInfo.total}</span>
                                    </li>


                                </ul>
                            </td>

                            <th>
                                <nav className="navbar navbar-light bg-light">
                                    <form className="form-inline" onSubmit={this.openUpload}>

                                        <div className="form-group col-md-7">
                                            <label>File To Upload</label>
                                            <br/>
                                            <select
                                                name={JSON.stringify(row)}
                                                className="form-control"
                                                onChange={(e) => this.onClickVehicleChangePicture(e)}
                                                required={true}
                                            >
                                                <option selected value={""}>Choose...</option>
                                                <option value={"Reg. Document"}>Reg. Document</option>
                                                <option value={"Pictures"}>Pictures</option>

                                            </select>
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>&nbsp;</label>
                                            <button className="btn btn-outline-success my-2 my-sm-0"
                                                    type="submit">Upload
                                            </button>
                                        </div>
                                    </form>
                                </nav>
                            </th>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }


    render() {

        let _this = this;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row">
                        <div className="col col-xs-12">
                            <h3>
                                Vehicle Dashboard

                            </h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-xs-12 col-lg-12">
                            <PopupUploadFile
                                popTitle={"Upload File"}
                                _this={this}
                                popStatus={this.state.showUploadVehiclePicture}
                                popFnClose={this.closeShowUploadFile}
                                vehicle={this.state.selectedVehicle}
                                fileCategory={this.state.selectedFileCategory}
                                tableRef={"vehicle"}
                            />

                            <PopupDownloadFile
                                popTitle={"Download File"}
                                _this={this}
                                popStatus={this.state.showDownloadFile}
                                popFnClose={this.closeDownload}
                                vehicle={this.state.selectedVehicle}
                                fileList={this.state.downloadFileList}
                                tableRef={"vehicle"}
                            />

                            <PopupAssignDriver
                                popTitle={"Assign Driver"}
                                _this={this}
                                popStatus={this.state.showAssignDriver}
                                popFnClose={this.closeAssignDriver}
                                vehicle={this.state.selectedVehicle}
                                driverList={this.state.Drivers}
                                driverAttributeList={this.state.DriverAttributes}
                                tableRef={"vehicle"}
                            />

                            <PopupIssue
                                popTitle={"New Issue"}
                                _this={this}
                                popStatus={this.state.showIssue}
                                popFnClose={this.closePopupIssue}
                                vehicle={this.state.selectedVehicle}
                                Personnels={this.state.Personnel}
                                PersonnelAttributes={this.state.PersonnelAttributes}
                                Drivers={this.state.Drivers}
                                DriverAttributes={this.state.DriverAttributes}
                                DatabaseOptions={this.state.DatabaseOptions}
                                TableRef={"vehicle"}
                            />



                            <PopupEntityDetail
                                popTitle={"View "}
                                _this={this}
                                popStatus={this.state.showEntityDetail}
                                popFnClose={this.closePopupEntityDetail}
                                keyRow={this.state.selectEntityDetailKey}
                                dataRow={this.state.selectEntityDetailData}
                                headers={[
                                    {key:"JobFrom",val:"Category"},
                                    {key:"RequiredStatus",val:"Status"},
                                    {key:"Mileage",val:"Mileage"},
                                ]}
                            />


                        </div>
                    </div>

                    {this.state.showList ? this.renderList() : null}




                </div>
            </PageContentWallpaper>
        )
    }

}


/*
o.JobFrom ="inspection"
	o.Vehicle  ="BHD 789 GP"
	o.RequiredStatus ="pending"
	o.Mileage = 78630
	o.JobNumber=101
 */
