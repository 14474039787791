import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {FindImagesAny, GetJobListOption} from "../generic/find-data";
import {ServiceDataLoadder} from "../../api/service-data-loader";
import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import Invoice from "./Invoice";
import Labour from "./Labour";
import Misc from "./Misc";
import Parts from "./Parts";
import File from "./File";


export default class ComPartsInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopupExpense: false,
            selectedJob:null,
        }

    }

    async componentDidMount() {
        await this.loadInitialData();
    }

    loadInitialData = async () => {
        ServiceDataLoadder(this)
    }
    openPopup = (stateKey, selectedDataKey, selectedDataValue) => {
        let tmp = this.state;
        tmp[stateKey] = true;
        if (typeof selectedDataKey !== "undefined") {
            tmp[selectedDataKey] = selectedDataValue;
        }
        this.setState(tmp);

    }
    closePopup = (stateKey, selectedDataKey) => {
        this.setState({
            [stateKey]: false,
            [selectedDataKey]: null
        })
    }
    setParentStateKey = (key, val) => {
        this.setState({
            [key]: val,
        })
    }
    renderPartList = () => {
        if(this.state.showPopupExpense){
            return null
        }

        let ls = [];
        if (typeof this.state.CompletedJobs !== "undefined") {
            ls = this.state.CompletedJobs
        }

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th style={{maxWidth:80}}>#</th>
                    <th>JobNumber</th>
                    <th>Activity</th>
                    <th>Service</th>
                    <th>Mileage</th>
                    <th>Date</th>
                    <th>Frequency</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    let link = FindImagesAny(row.vehicle, this.state.Files, "parts");

                    return (
                        <tr>
                            <td style={{maxWidth:80}}>
                                <img src={link} width={80}/>
                            </td>
                            <td>{row.jobnumber}</td>
                            <td>{row.activity}</td>
                            <td>{row.service}</td>
                            <td>{row.duemileage}</td>
                            <td>{row.duedate}</td>
                            <td>{row.frequency}</td>
                            <td>
                                <a
                                    style={{color:"#33b8ff",cursor:"pointer"}}
                                    onClick={()=>this.openPopup("showPopupExpense","selectedJob",row)}
                                >Input Expense</a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    closeAddExpense=()=>{
        this.setState({
            showPopupExpense: false,
            selectedJob:null,
        })
    }

    renderAddingNewExpense=()=>{
        if(!this.state.showPopupExpense){
            return null
        }
        const job=this.state.selectedJob;
        return(
            <div className={"maitner"}>
                <p>
                    Input new expenses for {job.vehicle} &nbsp;
                    and Job# {job.jobnumber}
                    <a
                        className={"btn btn-danger pull-right"}
                        onClick={()=>this.closeAddExpense()}>
                        Cancel Adding
                    </a>
                    <hr/>
                </p>
                <MuiThemeProvider>
                <Tabs>
                    <Tab label="Invoice">
                        <Invoice
                        _this={this}
                        state={this.state}
                        fnClose={this.closeAddExpense}
                        />
                    </Tab>
                    <Tab label="Labour">
                        <Labour
                            _this={this}
                            state={this.state}
                            fnClose={this.closeAddExpense}
                        />
                    </Tab>
                    <Tab label="Misc">
                        <Misc
                            _this={this}
                            state={this.state}
                            fnClose={this.closeAddExpense}
                        />
                    </Tab>

                    <Tab label="Parts">
                        <Parts
                            _this={this}
                            state={this.state}
                            fnClose={this.closeAddExpense}
                        />
                    </Tab>

                    <Tab label="Upload Document">
                        <File
                            _this={this}
                            state={this.state}
                            fnClose={this.closeAddExpense}
                        />
                    </Tab>





                </Tabs>
                </MuiThemeProvider>
            </div>
        )
    }

    render() {

        return (
            <PageContentWallpaper {...this.props} >
                <div>
                    <div className="row">
                        <div className="col col-xs-12">
                            <h4
                                className={"alert alert-info"}
                                style={{color: "gray", fontSize: 18, textAlign: "center"}}
                            >
                                <i className={"fa fa-setting"}>
                                    &nbsp;
                                </i>&nbsp;
                                {"Job Expense (completed jobs)".toLocaleUpperCase()}

                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12">
                            {this.renderPartList()}
                            {this.renderAddingNewExpense()}
                        </div>
                    </div>

                </div>
            </PageContentWallpaper>
        )
    }
}
