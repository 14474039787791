import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {
    fetchUtilData,
    renderList,
    renderFormWithSubmit,
    removeEntry,
    buildDashActivityDue,
    findRecordGeneral,
    VehicleImageCard,
} from "./api";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";
import 'bootstrap/dist/css/bootstrap.css';
/*import url("//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css");*/
import {Card, ListGroup, ListGroupItem, Nav} from 'react-bootstrap';

export default class ComUtilMaintenanceActivityType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Operations:[],
            Data: [],
            Activities: [],
            Services: [],
            Vehicles: [],
            Files:[],
            Plannings:[],
            JobSchedules:[],
            Faults:[],
            newEntry: {},
            selectedVehicle: "",
            selectedActivity: "",
            selectedService: "",
            showList: true,
            cqlTable: "AssignJob",
            currentView: null,
            showPopup: false,
            dashActivityService: "activity",
            dataChooseActivity:null,
            dataChooseService:null

        }
    }

    async componentDidMount() {
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        //await fetchUtilData(this, this.state.cqlTable, "Data");
        await fetchUtilData(this, "UtilActivityList", "Activities");
        await fetchUtilData(this, "UtilServiceList", "Services");
        await fetchUtilData(this, "Vehicle", "Vehicles");
        await fetchUtilData(this, "Documents", "Files", "vehicle");
        await this.fetchOperationPlanned();
        await this.fetchMaintenancePlan();
        await this.fetchJobScheduleProcessing();
        await this.fetchFaults();

        // OperationPlanned
    }
    fetchFaults= async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: GetUserToken().OrgCode,
            Type: "string"
        }];
        await this.fetchingAll("Faults", conds, (data) => {
            console.log("fetchFaults :> ", data);
            //const keyOne = "position";
            /*data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)*/
            _this.setState({
                Faults: data
            });

        });
    }
    fetchJobScheduleProcessing = async () => {
        let _this = this;
        const user = GetUserToken();
        const hub = {
            Org: user.OrgCode,
            /*JobFrom string
            Vehicle string
            JobStatus string*/
            Stage: "processing"
        }

        let backend = eConfig.mainBackend;
        let endpoint = "/api/fleet/job/schedule/list";

        console.log("fetchJobScheduleProcessing send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("fetchJobScheduleProcessing response > ", dataIn);
            if (dataIn === null) {
                return null
            }
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    JobSchedules: data
                })

            } else {
                return null
            }
        });


    }
    fetchOperationPlanned = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: GetUserToken().OrgCode,
            Type: "string"
        }];
        await this.fetchingAll("AssignJob", conds, (data) => {
            console.log("fetchOperationPlanned :> ", data);
            //const keyOne = "position";
            /*data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)*/
            _this.setState({
                Data: data
            });

        });
    }
    fetchMaintenancePlan = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: GetUserToken().OrgCode,
            Type: "string"
        }];
        await this.fetchingAll("maintenanceplaning", conds, (data) => {
            console.log("fetchMaintenancePlan :> ", data);
            //const keyOne = "position";
            /*data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)*/
            _this.setState({
                Plannings: data
            });

        });
    }
    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "fleetminder",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (dataIn === null) {
                callback([]);
                return null
            }
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    setValueBox = (e) => {
        const val = e.target.value;
        const key = e.target.name;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    getValue = (key) => {
        return this.state[key]
    }
    openNewTask = () => {
        const state = this.state;
        let tmp = this.state;
        tmp.newEntry.VehicleID = state.selectedVehicle;
        tmp.newEntry.Activity = state.selectedActivity;
        tmp.newEntry.Service = state.selectedService;
        tmp.showList = false;
        this.setState(tmp);
    }
    closeNewTask = () => {
        const state = this.state;
        let tmp = this.state;
        tmp.newEntry.VehicleID = "";
        tmp.newEntry.Activity = "";
        tmp.newEntry.Service = "";
        tmp.showList = true;
        this.setState(tmp);
    }
    closeShowPopup = () => {
        this.setState({
            currentView: null,
            showPopup: false
        })
    }
    submitTask = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        const _this = this;
        const table = "MaintenanceTask";

        let entry = {...this.state.newEntry};
        let o = {};
        o.Org = GetUserToken().OrgCode;
        o.CostParts = parseFloat(entry.CostParts);
        o.CostPartsDiscount = parseFloat(entry.CostPartsDiscount);
        o.CostLabour = parseFloat(entry.CostLabour);
        o.CostLabourDiscount = parseFloat(entry.CostLabourDiscount);
        o.LastDueMileage = parseFloat(entry.LastDueMileage);
        o.NextDueMileage = parseFloat(entry.NextDueMileage);
        o.Activity = entry.Activity;
        o.Category = entry.Category;
        o.CostLabourVat = entry.CostLabourVat;
        o.CostPartsVat = entry.CostPartsVat;
        o.LastDueDate = entry.LastDueDate;
        o.Manager = entry.Manager;
        o.Name = entry.Name;
        o.Service = entry.Service;
        o.VehicleID = entry.VehicleID;


        //todo let add our default variable
        o.Id = "";
        o.Status = "";
        o.Date = "";
        o.Time = "";
        o.OrgDateTime = "";

        let hub = {
            Fields: [],
            DbName: "fleetminder",
            TableName: table,
            In: o,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/add";

        console.log("submitTask send > ", hub, " > ", o);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitTask response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    alert("Thank you Record submitted!");
                    let tmp = _this.state;
                    tmp.showList = true;
                    tmp.newEntry = {};
                    _this.setState(tmp);
                    _this.loadInitialData();
                }
            }

        });
    }
    onClickBreakdownActivity = (data) => {
        this.setState({
            dataChooseActivity:data
        });
        //todo please find out all the task due for this vehicle
    }
    onClickBreakdownService = (data) => {
        this.setState({
            dataChooseService:data
        });
        //todo please find out all the task due for this vehicle
    }
    openScheduleVehicle=(row)=>{
        if(!window.confirm("Are you sure that you want to schedule this Job?")){
            return;
        }

        localStorage.setItem("REQ_SCHEDULE",JSON.stringify(row))
        window.location.href="#/fleet-schedule-jobs";

    }
    renderList = () => {
        const keyList = [
            {field: "name", label: "Name"},
            {field: "activity", label: "Activity"},
            {field: "orgdatetime", label: "Last Updated"}
        ];
        const deleteKey = ["org", "service", "name"];
        const deleteTable = "MaintenanceTask";
        return renderList(this, "Data", keyList, deleteKey, deleteTable, "showList", removeEntry)
    }
    renderForm = () => {
        if (this.state.showList) {
            return null;
        }

        const options = ["yes", "no"];
        const categories = ["mileage", "period", "fixed"];
        const vats = ["include", "exclude"];
        let ls = [
            {field: "Category", label: "Category", options: categories, type: "option", required: true},
            {field: "Name", label: "Name of Task", options: options, type: "text", required: true},
            {field: "CostParts", label: "Cost Parts", options: options, type: "text", required: true},
            {field: "CostPartsDiscount", label: "Cost Parts Discount", options: options, type: "text", required: true},
            {field: "CostPartsVat", label: "Cost Parts Vat", options: vats, type: "option", required: true},
            {field: "CostLabour", label: "Cost Labour", options: options, type: "text", required: true},
            {
                field: "CostLabourDiscount",
                label: "Cost Labour Discount",
                options: options,
                type: "text",
                required: true
            },
            {field: "CostLabourVat", label: "Cost Labour Vat", options: vats, type: "option", required: true},
            {field: "Manager", label: "Manager of this task", options: options, type: "text", required: true},
            {field: "LastDueMileage", label: "Last Due Mileage", options: options, type: "text", required: true},
            {field: "LastDueDate", label: "Last Due Date", options: options, type: "text", required: true},
            {field: "NextDueMileage", label: "Next Due Mileage", options: options, type: "text", required: true},
        ]

        return (
            <div>
                <h3>
                    &nbsp;
                    <a
                        className={"btn btn-danger pull-right"}
                        onClick={() => this.closeNewTask()}
                    >Cancel</a>
                </h3>
                <br/>
                {renderFormWithSubmit(ls, "showList", this.state.cqlTable, this, this.submitTask)}
            </div>
        )

    }

    renderShowTaskDetail() {

        const actions = [
            <FlatButton
                label="Close"
                primary={true}
                onClick={this.closeShowPopup}
            />,
        ];

        let ls = [];
        const avoidList = (keyIn) => {
            let boo = false;
            const ls = ["date", "id", "org", "status", "profile", "orgdatetime", "type", "time"];
            for (let i in ls) {
                const item = ls[i];
                if (item === keyIn) {
                    return true;
                }
            }
        }
        for (let i in this.state.currentView) {
            const val = this.state.currentView[i];
            if (!avoidList(i)) {
                ls.push({
                    key: i,
                    val: val
                })
            }

        }
        return (
            <Dialog
                title={"View Task Detail"}
                actions={actions}
                modal={false}
                open={this.state.showPopup}
                onRequestClose={this.closeShowPopup}
                autoScrollBodyContent={true}
            >

                <br/>

                <div className="col-lg-12">
                    <table className={"table"}>
                        <thead>

                        </thead>
                        <tbody>
                        {ls.map((row, index) => {
                            return (
                                <tr>
                                    <th>{row.key}:</th>
                                    <td>{row.val}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>


            </Dialog>


        )

    }

    renderDashboard = () => {
        const _this = this;
        const onClickOverDash = (dashBox) => {
            _this.setState({
                dashActivityService: dashBox
            });
        }
        const DashBox = ({size, color, icon, boxNumber, boxName, dashName}) => {
            // https://react-bootstrap.netlify.com/components/cards/
            const boxSize = "col-lg-" + size + " col-md-" + (size - 1);
            const redirectViewSch=()=>{window.location.href="#/fleet-schedule-jobs"}
            const redirectViewFaults=()=>{window.location.href="#/fleet-faults-vehicle"}
            const redirectViewAlert=()=>{window.location.href="#/fleet-alert-vehicle"}

            let fnOnClick=onClickOverDash;
            if(boxName==="Jobs Scheduled"){
                fnOnClick=redirectViewSch
            }else if(boxName==="Faults Logging"){
                fnOnClick=redirectViewFaults
            }else if(boxName==="Reminders"){
                fnOnClick=redirectViewAlert
            }


            return (
                <div className={boxSize}>
                    <Card bg="dark" text="white" style={{width: '100%',}}
                         >
                        <Card.Header>{boxName}</Card.Header>
                        <Card.Body>
                            <Card.Title style={{textAlign: "center",fontSize:36}}>{boxNumber}</Card.Title>
                            <Card.Text style={{textAlign: "center", cursor: "pointer"}}  onClick={() => fnOnClick(dashName)}>
                                View Detail
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            )

        }



        let totalPending = this.state.Data.length;
        let totalScheduled =this.state.JobSchedules!==null?this.state.JobSchedules.length:0;
        let totalFaults = this.state.Faults!==null?this.state.Faults.length:0;
        let totalWarning = "N/A";


        let DashboardPending = {
            size: 3,
            color: "success",
            icon: "glyphicon glyphicon-headphones",
            boxNumber: totalPending,
            boxName: "Jobs Due",
            dashName: "activity",
        }
        let DashboardScheduled = {
            size: 3,
            color: "warning",
            icon: "glyphicon glyphicon-volume-up",
            boxNumber: totalScheduled,
            boxName: "Jobs Scheduled",
            dashName: "schedule"
        }
        let DashboardOverdue = {
            size: 3,
            color: "danger",
            icon: "glyphicon glyphicon-ban-circle",
            boxNumber: totalFaults,
            boxName: "Faults Logging",
            dashName: "overdue"
        }
        let DashboardWarning = {
            size: 3,
            color: "danger",
            icon: "glyphicon glyphicon-ban-circle",
            boxNumber: totalWarning,
            boxName: "Reminders",
            dashName: "reminder"
        }

        return (
            <div className="row">
                {DashBox(DashboardPending)}
                {DashBox(DashboardScheduled)}
                {DashBox(DashboardOverdue)}
                {DashBox(DashboardWarning)}
            </div>
        )
    }

    renderSideDashVehicle = () => {
        let info=this.state.dataChooseActivity;
        let ls = [];
        if(info===null){
            info={};
            info.activity="Choose service...";
        }
        let act=info.activity;
         let vehicles={};
        for(let i in info.vehicles){
            const row=info.vehicles[i];
            let ser="Info";
            if(row.service.length===1){
                ser=row.service.toLocaleUpperCase();
            }

            let records=[row];

            if(typeof vehicles[row.vehicle] !=="undefined"){
                let recs=vehicles[row.vehicle].rows;
                recs.push(row);
                records=recs;
            }

            vehicles[row.vehicle] = {jobNumber:row.jobNumber,vehicle:row.vehicle,total:ser,row:row,rows:records}
        }

        for(let i in vehicles){
            const row=vehicles[i];
            ls.push(row);
        }

        /*let ls = [
            {vehicle: "CA 987 456", total: "A"},
            {vehicle: "CY 963 741", total: "B"},
            {vehicle: "BTV 987 GP", total: "C"},
            {vehicle: "CA 125 963", total: "C"},
            {vehicle: "CA 753 159", total: "H"},
        ];*/

        return (
            <Card style={{height: '100%'}}>
                <VehicleImageCard
                    Files={this.state.Files}
                    dataChooseService={this.state.dataChooseService}
                />
                  <Card.Body style={{maxHeight: 150}}>
                    <Card.Title>{act} </Card.Title>
                    <Card.Text>
                        Following vehicle need your attention.
                        Explore Job due detail in middle block.
                    </Card.Text>
                </Card.Body>


                <ListGroup className="list-group-flush">
                    {ls.map((row, index) => {
                        return (
                            <ListGroupItem
                                onClick={(e) => this.onClickBreakdownService(row)}
                                style={styles.dashVehicleBadge}
                                onMouseEnter={{color: '#ed1212'}}

                            >
                                {row.vehicle}

                                <Card.Link

                                    className={"pull-right"}
                                    style={{...styles.dashVehicleBadge,color:"blue"}}
                                >
                                    {row.total!=="Info"?row.total:<i className={"fa fa-list"}>&nbsp;</i> }
                                </Card.Link>
                            </ListGroupItem>

                        )
                    })}
                </ListGroup>

            </Card>
        )

    }
    renderSideDashContent = () => {
        if(this.state.dataChooseService ===null || this.state.dataChooseActivity===null){
            return(
                <Card border="primary" style={{height: '100%'}}>
                    <Card.Header>
                        <Card.Title style={{color: "black"}}>Current Service Due</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text style={{color:"red"}}>
                            Waiting your vehicle service selection....
                        </Card.Text>
                    </Card.Body>
                </Card>
            )
        }

        const inService = this.state.dataChooseService;
        const inActivity =this.state.dataChooseActivity;


        let selectedVehicle = inService.vehicle;
        let selectedService = inService.total;
        let tasks = [];



        let tasksCurrent=[];
        for(let i in inService.rows){
            const rec=inService.rows[i];
            const currJob =findRecordGeneral(
                rec.jobNumber,
                "jobnumber",
                this.state.Data
            );
             tasksCurrent.push(currJob);
        }

        return (

            <Card border="primary" style={{height: '100%'}}>
                <Card.Header>
                    <Card.Title style={{color: "black"}}>Current Service Due</Card.Title>
                </Card.Header>
                <Card.Body>

                    {/*<Card.Title>Current Service Due</Card.Title>*/}

                    <Card.Text>
                        <small style={{color: "blue"}}>
                            Vehicle [{selectedVehicle}] {selectedService} service due
                        </small>

                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th style={{maxWidth:30}}>#</th>
                                <th>Activity</th>
                                <th>Job</th>
                                <th>Unit</th>
                                <th>Due</th>
                                <th>Status</th>

                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {tasksCurrent.map((row) => {
                                console.log("^^memo^^> ",row);
                                let frequency = row.frequency;
                                let jobStatus = "Due";
                                //todo work to find our what is the current based on current collection mileage and date
                                let dueValue = frequency==="mileage"?row.duemileage:row.duedate;

                                return (
                                    <tr>
                                        <td>
                                            <a onClick={() => this.openScheduleVehicle(row)}
                                               style={{color: "red", cursor: "pointer"}}
                                            >{row.jobnumber}</a>
                                        </td>
                                        <td>{row.activity}</td>
                                        <td>{row.service}</td>
                                        <td>{frequency}</td>
                                        <td>{dueValue}</td>
                                        <td>{jobStatus}</td>

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>

                    </Card.Text>


                </Card.Body>
            </Card>

        )
    }
    renderSideDashActivity = () => {
        let _this = this;
        let currentContent = this.state.dashActivityService;
        let currentContentTitle = {
            activity: "Jobs Due",
            schedule: "Jobs Schedules",
            overdue: "Warnings",
            reminder: "Reminders"
        };

        /*let lsDashActivityDue = [
            {
                activity: "Planned Service",
                selectedDash: "activity",
                total: 5,
                vehicles: [
                    {
                        vehicle: "CA 987 456",
                        service: "A",
                        tasks: [
                            "Oil change",
                            "Brakes",
                            "Front while check",
                        ]
                    },
                ]
            },
            {activity: "Check List", total: 8},
            {activity: "UnPlanned", total: 1},
        ];*/
        let lsDashSchedule = [
            {activity: "Planned Service", total: 2},
            {activity: "Check List", total: 1},
        ];
        let lsDashOverdue = [
            {activity: "Planned Service", total: 2},

        ];
        let lsDashReminders = [
            {activity: "Planned Service", total: 5},
            {activity: "Check List", total: 2},
            {activity: "UnPlanned", total: 1},
        ];

        //todo load live data into activity

        let data = {
            activity: buildDashActivityDue(this.state.Data,this.state.Plannings),
            schedule: lsDashSchedule,
            overdue: lsDashOverdue,
            reminder: lsDashReminders,
        }
        const changeView = (inText) => {
            _this.setState({
                dashActivityService: inText
            });
        }

        return (
            <Card style={{height: '100%'}}>
                <Card.Header>
                    <Nav variant="tabs">
                        <Nav.Item onClick={() => changeView("activity")}>
                            <Nav.Link style={{fontSize: 14}}>{currentContentTitle[currentContent]} Breakdown</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>

                <Card.Body>
                    <ListGroup className="list-group-flush">
                        {data[currentContent].map((row, index) => {
                            return (
                                <ListGroupItem
                                    key={index}
                                    onClick={() => this.onClickBreakdownActivity(row)}
                                    style={styles.dashVehicleBadge}
                                    onMouseEnter={{color: '#ed1212'}}
                                >
                                    {row.activity}

                                    <Card.Link
                                        href="#"
                                        className={"pull-right"}
                                        style={styles.dashVehicleBadge}
                                    >
                                        {row.total}
                                    </Card.Link>
                                </ListGroupItem>
                            )
                        })}
                    </ListGroup>
                </Card.Body>


            </Card>
        )
    }

    closeShowPopup = () => {
        this.setState({
            currentView: null,
            showPopup: false
        })
    }
    openShowPopup = (row) => {
        this.setState({
            currentView: row,
            showPopup: true
        })
    }
    onChangeActualCost = (e, key, row) => {

    }
    submitTaskStatus = (e) => {
        e.preventDefault();
    }

    renderShowTaskDetail() {

        if (this.state.currentView === null) {
            return null
        }

        const actions = [
            <FlatButton
                label="Close"
                primary={true}
                onClick={this.closeShowPopup}
            />,
        ];

        let ls = [];
        const avoidList = (keyIn) => {
            let boo = false;
            const ls = ["date", "id", "org", "status", "profile", "orgdatetime", "type", "time"];
            for (let i in ls) {
                const item = ls[i];
                if (item === keyIn) {
                    return true;
                }
            }
        }
        for (let i in this.state.currentView) {
            const val = this.state.currentView[i];
            if (!avoidList(i)) {
                ls.push({
                    key: i,
                    val: val
                })
            }

        }

        const row = this.state.currentView;

        let invoicesData = [
            {key: "Part Cost", cost: row.costParts, actualKey: "ActualCostParts", costVat: "include"},
            {key: "Labour Cost", cost: row.CostLabour, actualKey: "ActualCostLabour", costVat: "include"},
            /*{storeCostVat:row.costVat,actualKey:"ActualCostVat",costVat:"include"},*/
        ];
        const innerStyle = {textAlign: "right"};
        return (
            <Dialog
                title={"View Task Detail"}
                actions={actions}
                modal={false}
                open={this.state.showPopup}
                onRequestClose={this.closeShowPopup}
                autoScrollBodyContent={true}
            >

                <br/>

                <div className="col-lg-12">
                    <p className={"alert alert-info"}>
                        Activity: {row.activity} &nbsp;|&nbsp; Service:{row.service}  &nbsp;|&nbsp;
                        Task name: {row.task}
                    </p>

                    <p className={"alert alert-primary"}>
                        Operation Status Change
                    </p>

                    <form onSubmit={this.submitTaskStatus}>

                        <div className="form-group">
                            <label htmlFor="exampleFormControlSelect1">Status select</label>
                            <select className="form-control" id="exampleFormControlSelect1" required={true}>
                                <option value={""}>--select your status--</option>
                                <option value={"pending"}>Pending</option>
                                <option value={"progress"}>In Progress</option>
                                <option value={"supplier"}>On Supplier demand</option>
                                <option value={"skip"}>Skip this operation</option>
                                <option value={"invoice"}>Invoice</option>
                                <option value={"complete"}>Complete</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1">Comment</label>
                            <textarea
                                className="form-control"
                                rows="2"
                                required={true}
                            ></textarea>
                        </div>


                        <br/>


                        <p className={"alert alert-primary"}>
                            Invoice
                        </p>

                        <table className={"table"}>
                            <thead>

                            <tr>
                                <th>#</th>
                                <th style={innerStyle}>Planned Cost</th>
                                <th style={innerStyle}>Vat Cost</th>
                                <th style={innerStyle}>Actual Cost</th>
                            </tr>

                            </thead>
                            <tbody>
                            {invoicesData.map((row, index) => {
                                return (
                                    <tr>
                                        <td>{row.key}</td>
                                        <th style={innerStyle}>{row.cost}</th>
                                        <td style={innerStyle}>{row.costVat}</td>
                                        <td style={innerStyle}>
                                            <input className={"form-controler"}
                                                   onChange={(e) => this.onChangeActualCost(e, row.key, row)}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th colSpan={4}>
                                    <button className={"btn btn-primary pull-right"} type={"submit"}>
                                        Save Update
                                    </button>
                                </th>
                            </tr>
                            </tfoot>
                        </table>

                    </form>
                </div>


            </Dialog>


        )

    }

    render() {

        let _this = this;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row ">
                        <div className="col col-xs-12">
                            <h3>
                                Maintenance Dashboard
                            </h3>
                        </div>
                    </div>

                    {this.renderDashboard()}

                    <div className="row ">
                        <div className="col col-xs-12 col-lg-12">
                            <hr/>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col col-xs-12 col-lg-3 ">
                            {this.renderSideDashActivity()}
                        </div>

                        <div className="col col-xs-12 col-lg-6 ">
                            {this.renderSideDashContent()}
                        </div>

                        <div className="col col-xs-12 col-lg-3 ">
                            {this.renderSideDashVehicle()}
                        </div>

                    </div>


                    <div className="row">
                        <div className="col col-xs-12 col-lg-12">
                            <MuiThemeProvider>
                                {this.renderShowTaskDetail()}
                            </MuiThemeProvider>
                        </div>
                    </div>


                </div>
            </PageContentWallpaper>
        )
    }

}

const styles = {
    dashVehicleBadge: {
        cursor: "pointer",


    }
}
