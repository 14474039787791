import React, {Component} from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {getValue, setValue, setValueBox, submitEntry} from "./api";

export default class CompPopupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    submitEntry=async (e,cqlTable,ls,_this,fnClose)=>{
        await submitEntry(e,cqlTable,ls,_this);
        //await fnClose();
    }

    returnForm= (cqlTable,ls,_this,fnClose)=>{
        console.log("&&&&--<> ",cqlTable," > ",ls);
        return(
            <div className={"container"}>
                <form onSubmit={(e)=>this.submitEntry(e,cqlTable,ls,_this,fnClose)}>

                    {ls.map((row, index) => {

                        if (row.type === "option") {

                            return (
                                <div className="row" key={"zty" + index}>
                                    <div className="form-group col-md-12 col-lg-12">
                                        <label htmlFor="inputState">{row.label}</label>
                                        <select
                                            name={row.field}
                                            className="form-control"
                                            onChange={(e) => setValueBox(e, _this,row.dataType)}
                                            value={getValue(row.field,_this)}
                                            required={row.required}
                                        >
                                            <option value="" selected>Choose...</option>
                                            {row.options.map((item, index2) => {
                                                let key = item ;
                                                let desc = item.toLocaleUpperCase();
                                                return (
                                                    <option key={index2} value={key}>{desc}</option>
                                                )
                                            })}

                                        </select>
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div className="row" key={"zty" + index}>
                                <div className="form-group col-md-12  col-lg-12">
                                    <label htmlFor="inputState">{row.label}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => setValue(e, row.field,_this,row.dataType)}
                                        value={getValue(row.field,_this)}
                                        required={true}
                                    />
                                </div>
                            </div>
                        )
                    })}
                    <button
                        type={"submit"}
                        className={"btn btn-default pull-right "}
                        style={{backgroundColor: " #00b3ee"}}

                    > Submit
                    </button>
                </form>
            </div>
        )
    }
    render(){
        const title = this.props.popTitle;
        const _this = this.props._this;
        const cqlTable = this.props.cqlTable;
        const openStatus = this.props.popStatus;
        const fnClose = this.props.popFnClose;
        const ls = this.props.popData;
        console.log("render &&&&--<> ",title," > ",ls);

        const actions = [
            <FlatButton
                label={"Close"}
                primary={true}
                onClick={fnClose}
            />,
        ];

        return (
            <MuiThemeProvider>
                <Dialog
                    title={title}
                    actions={actions}
                    modal={false}
                    open={openStatus}
                    onRequestClose={fnClose}
                    autoScrollBodyContent={true}
                >
                    {this.returnForm(cqlTable,ls,_this,fnClose)}

                </Dialog>
            </MuiThemeProvider>
        )

    }
}