import React, {Component} from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {getValue, setValue, setValueBox, submitEntry} from "./api";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";

export default class CompPopupAssign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newEntry: {
                name: "",
                comment: "",
            },
        }
    }

    handleFileTypeChange=(e,key)=>{
        let tmp = this.state;
        tmp.newEntry[key] = e.target.value;
        this.setState({
            tmp
        })
    }

    submitEntry=async (e)=>{
        e.preventDefault();
        const user = GetUserToken();
        const vehicle=this.props.vehicle;
        const fnClose = this.props.popFnClose;
        const _this = this.props._this;

        let entry={
            Org:user.OrgCode,
            Vehicle:vehicle.ref,
            Driver: this.state.newEntry.name,
            Comment:this.state.newEntry.comment,
            Id:"",
            Profile:{},
            Status:"",
            Date:"",
            Time:"",
            OrgDateTime:"",
        }
        let hub = {
            Fields: [],
            DbName: "fleetminder",
            TableName: "VehicleDriver",
            In: entry,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/add";

        console.log("submitEntry send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitEntry response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Asset attribute submitted!");
                _this.loadInitialData();
                fnClose()

            }
        });
    }

    render(){
        const title = this.props.popTitle;
        const _this = this.props._this;
        const openStatus = this.props.popStatus;
        const fnClose = this.props.popFnClose;
        const vehicle=this.props.vehicle;
        const drivers=this.props.driverList;
        const attributes=this.props.driverAttributeList;

        if(vehicle===null){
            return null
        }

        const actions = [
            <FlatButton
                label={"Close"}
                primary={true}
                onClick={fnClose}
            />,
        ];

        const findDriverFullName=(driver)=>{
            let names="";
            for(let i in attributes){
                const row =attributes[i];
                if(row.section==="names"){
                    names = names + driver.data[row.fieldname]+" ";
                }
            }
            return names.toLocaleUpperCase();
        }

        return (
            <MuiThemeProvider>
                <Dialog
                    title={title +" Vehicle: "+vehicle.ref}
                    actions={actions}
                    modal={false}
                    open={openStatus}
                    onRequestClose={fnClose}
                    autoScrollBodyContent={true}
                >
                    <form onSubmit={this.submitEntry}>
                        <p className={"alert alert-info"}>Assign Driver to this Vehicle</p>

                        <div className="form-group">
                            <label>Drivers</label>
                            <br/>
                            <select
                                className="form-control"
                                onChange={(e) => this.handleFileTypeChange(e,"name")}
                                required={true}
                                value={this.state.newEntry.name}
                            >
                                <option selected value={""}>Choose...</option>

                                {drivers.map((row,index)=>{
                                    let fullname =findDriverFullName(row) ;

                                    return(
                                        <option value={row.ref}>{fullname || row.ref}</option>
                                    )

                                })}
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Comment</label>
                            <br/>
                            <textarea
                                className="form-control"
                                onChange={(e) => this.handleFileTypeChange(e,"comment")}
                                value={this.state.newEntry.comment}
                                size={3}
                            ></textarea>

                        </div>


                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn-primary form-control"
                            >Submit
                            </button>
                        </div>

                    </form>


                </Dialog>
            </MuiThemeProvider>
        )

    }

}