
import React from 'react';
import Paper from 'material-ui/Paper';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
import ArrowDropRight from 'material-ui/svg-icons/navigation-arrow-drop-right';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "./redux-dispatch-to-props";


import {Card} from "react-bootstrap";
const style = {
    display: 'inline-block',
    minHeight: "90%",
    maxWidth:"100%"
    /*margin: '16px 32px 16px 0',*/
};

class SideMenuNav extends React.Component {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
    }

    componentDidMount(){

    }


    _handleClick(menuName) {
        console.log("SideMenuNav _handleClick > ",menuName);
        window.location.href = "#/"+menuName;
    }


    setModuleMenu = (module, menu, submenu,link) => {
        this.props.dispatch({
            type: "SAVE_MODULE_MENU",
            payload: {
                module: module,
                menu: menu,
                submenu
            }
        });
        localStorage.setItem("@fleetminder-module",module);
        localStorage.setItem("@fleetminder-menu",menu);
        localStorage.setItem("@fleetminder-submenu",submenu);
        localStorage.setItem("@fleetminder-link",link);

        window.location.href = "#/"+link;
        const arr=link.split("/");
        if(arr.length>1){//let refresh for alies menu change e.g /#/setting-model-databases/manufacturer
            window.location.reload();
        }

    }

    findModuleFromMenuLocation=()=>{

        let loc = this.props.location.pathname;
        console.log("findModuleFromMenuLocation > ",loc)
        const menus = this.props.navigations2.menus;
        for(let module in menus){
            const rowModule = menus[module];
            console.log("*2 compare submenu > ",rowModule," > ",(rowModule.hasMenu));

            for(let i in rowModule){
                const rowMenus=rowModule[i];
                if(rowMenus.hasMenu){
                    for(let a in rowMenus.submenu){
                        const row=rowMenus.submenu[a];
                        const menu="/"+row.link;
                        console.log("2 compare submenu > ",module," > ",menu," > ",loc);
                        if(menu ===loc){
                            return module;
                        }
                    }
                }else{
                    const menu="/"+rowMenus.link;
                    console.log("1 compare menu > ",module," > ",menu," > ",loc)
                    if(menu ===loc){
                        return module;
                    }
                }
            }
        }

        return "home"

    }

    renderMenu=()=>{
        let _this= this;
        let module=localStorage.getItem("@fleetminder-module"); //this.props.navigations2.currentModule;
        if(typeof module ==="undefined" || module===null){
            module=this.findModuleFromMenuLocation();
        }

        console.log("renderMenu > ",module);
        const menus=this.props.navigations2.menus[module];

        const innerOptions=(rows,menu)=>{
            const arr=[];
            for(let i in rows){
                const row = rows[i];
                arr.push((
                    <MenuItem
                        primaryText={row.name}
                        style={styles.menuItem3}
                        onClick={()=>_this.setModuleMenu(module,menu,row.name,row.link)}
                    />
                ))
            }

            return arr;
        }
        return menus.map((row1,index)=>{
            if(!row1.hasMenu){
                return(
                    <MenuItem
                        key={index}
                        primaryText={row1.name}
                        style={styles.menuItem}
                        onClick={()=>_this.setModuleMenu(module,row1.name,"",row1.link)}
                    />

                )
            }

            return(
                <MenuItem
                    key={index}
                    style={styles.menuItem}
                    rightIcon={<ArrowDropRight style={{color:"white"}}/>}
                    primaryText={row1.name}
                    menuItems={innerOptions(row1.submenu,row1.name)}

                />
            )

        });
    }

    render() {
        const module=this.props.navigations2.currentModule;
        const width = window.innerWidth, height=  window.innerHeight;
        const myWidth = (width / 100) * 15;
        const menuBackground ={
            backgroundColor:"#795548",
            color:"white",
            minWidth:myWidth,
            minHeight:height,
            borderRadius:10,
            borderWidth:1,
            borderColor:"yalow",
            marginRight:10,
            marginTop:10,
        }

        let defaultMenuImageLink="https://www.efleets.com/content/efleets/us/en/how-it-works/maintenance-management/_jcr_content/par/interior_hero_copy/largeImage.img.jpg/1530820884503.jpg";
        defaultMenuImageLink="http://survey.bizminder.co.za/static/media/home.5b0e1d30.jpg";
        return (
            <MuiThemeProvider>

                <div style={style}>

                    <Menu desktop={true} width={280} style={menuBackground}>
                        <img
                            src={defaultMenuImageLink}
                            style={{width:"100%",marginTop:-20}}

                        />


                        <MenuItem
                            primaryText={module.toLocaleUpperCase() + " Menu"}
                            style={styles.menuItem2}
                        />

                        <Divider
                        />
                        {this.renderMenu()}

                    </Menu>
                </div>


            </MuiThemeProvider>
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SideMenuNav)

const styles={
    menuBackground:{
        backgroundColor:"#13B5EA",
        color:"white"
    },
    menuItem:{
        color:"white",
        fontSize:12,
        fontWeight:""
    },
    menuItem3:{
        backgroundColor:"#13B5EA",
        color:"white",
        fontSize:12,
        fontWeight:"",
        menuItems:{
            backgroundColor:"#13B5EA",
            color:"white",
        },

    },
    menuItem2:{
        color:"#ffd9cc",
        fontSize:14,
        fontWeight:"bold",
        minHeight:"90%"
    }
}
