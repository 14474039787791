import React, {Component} from "react";
import FlatButton from "material-ui/FlatButton";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "@material-ui/core/Dialog";

const objectToArray = (inData) => {
    let ls = [];
    for (let key in inData) {
        const d = inData[key];
        if (typeof d === 'string' || d instanceof String) {
            ls.push({key: key, val: d});
        }
    }

    console.log("5555 %^^^^^^> ", ls, " > ", inData)
    return ls
}

export default class ComponentName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showViewDetail: false,
            currentRow: null,
            currentKey: "",
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    openView = (key, row) => {
        let myData = objectToArray(row);
        this.setState({
            showViewDetail: true,
            currentRow: myData,
            currentKey: key,
        });

    }
    closeView = () => {
        this.setState({
            showViewDetail: false,
            currentRow: null,
            currentKey: "",
        })
    }
    renderTable = (keyRow, dataRow) => {
        const postedHeaders=this.props.headers;
        const fnClose = this.props.popFnClose;
        let headers = postedHeaders;
        const avoidMe = {
            Id: true,
            Username: true,
            OrgDateTime: true,
            Date: true,
            Time: true,
            Status: true,
            Org: true
        };

        /*for (let i in dataRow) {
            const row = dataRow[i];
            for (let item in row) {
                if (item !== keyRow) {
                    let myData = row[item];
                    if (typeof myData === 'string' || myData instanceof String) {
                        const avoidMe = {
                            Id: true,
                            Username: true,
                            OrgDateTime: true,
                            Date: true,
                            Time: true,
                            Status: true,
                            Org: true
                        };
                        if (typeof avoidMe[item] === "undefined") {
                            headers.push(item)
                        }

                    }
                }
            }
            break
        }*/


        /*if (this.state.showViewDetail) {
            return (
                <div className={"col col-lg-5"}>
                    <div className={"alert alert-info"}>Selected Data Detail</div>
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>Key</th>
                            <th>Value</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.currentRow.map((row) => {
                            return (
                                <tr>
                                    <th>{row.key}</th>
                                    <td>{row.val}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                        <tfoot>
                        <tr>
                            <th colSpan={2}>
                                <a className={"btn btn-danger"} onClick={() => this.closeView()}>Close View</a>
                            </th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            )
        }*/


        return (
            <div className={"row container"} style={{maxWidth: "100%"}}>
                <div className={"col col-lg-" + this.state.showViewDetail ? 7 : 12}>
                    <div
                        style={{marginTop:20}}
                        className={"alert alert-info"}
                    >Data List</div>
                    <table className={"table  table-bordered"}>

                        <thead>

                        <tr>
                            <th>#</th>
                            <th>{keyRow}</th>
                            {headers.map((item) => {
                                return (
                                    <th>{item.key}</th>
                                )
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {dataRow.map((row) => {

                            return (
                                <tr>
                                    <td>
                                        <a
                                            style={{color: "blue", cursor: "pointer", fontSize: 16, fontWeight: "700"}}
                                            onClick={() => this.openView(row[keyRow], row)}
                                        >
                                            Click for More detail...
                                        </a>
                                    </td>
                                    <td>{row[keyRow]}</td>
                                    {headers.map((item) => {
                                        if (item.key === keyRow) {
                                            return null
                                        }
                                        return (
                                            <td>{row[item.val]}</td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    <br/>
                    <button className={"btn btn-danger pull-left"} onClick={()=>fnClose()}>Close</button>
                </div>
                {this.state.showViewDetail ?
                    <div className={"col col-lg-5"}>
                        <div
                            style={{marginTop:20}}
                             className={"alert alert-info"}
                        >Selected Data Detail</div>
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.currentRow.map((row) => {
                                if(typeof avoidMe[row.key] !=="undefined"){
                                    return null
                                }
                                return (
                                    <tr>
                                        <th>{row.key}</th>
                                        <td>{row.val}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th colSpan={2}>
                                    <a className={"btn btn-danger"} onClick={() => this.closeView()}>Close View</a>
                                </th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>:null
                }
            </div>
        )

    }

    render() {
        const title = this.props.popTitle;
        const openStatus = this.props.popStatus;
        const fnClose = this.props.popFnClose;
        const keyRow = this.props.keyRow;
        const dataRow = this.props.dataRow;

        if (dataRow === null) {
            return null
        }


        const actions = [
            <FlatButton
                label={"Close"}
                primary={true}
                onClick={fnClose}
            />,
        ];

        return (
            <MuiThemeProvider>
                <Dialog
                    title={title}
                    actions={actions}
                    modal={false}
                    fullWidth={true}
                    open={openStatus}
                    onRequestClose={fnClose}
                    autoScrollBodyContent={true}
                    style={{
                        marginTop: 100,
                        minWidth: 500,
                        maxWidth: "none",
                        marginRight: 30,
                        marginLeft: 30,
                        marginBottom: 30
                    }}
                    fullScreen
                >
                    <div className={"container"}>
                        {this.renderTable(keyRow, dataRow)}

                    </div>


                </Dialog>
            </MuiThemeProvider>
        )
    }
}

const styles = {}
