

const cleanInputUsername = (inData) => {
    if(typeof inData ==="undefined"){
        inData ="";
    }
    let data = inData.replace(" ", "");
    data = data.toLocaleLowerCase();
    return data;
}


export {cleanInputUsername};
