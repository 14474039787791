import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import "../../asset/css/fieldset-lengend.css";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";

export default class CompPopupScheduleStatusChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            RequiredStatus:"",
            JobStage:"",
            Mileage:""
        }
    }
    handleInput = async (e) => {
        let tmp = this.state;
        let key = e.target.name;
        tmp[key] = e.target.value;

        this.setState(tmp)
    }

    submitScheduleEmail= async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to schedule this Job?")) {
            return
        }
        const job = this.props.job;
        const fnClose = this.props.popFnClose;
        let _this = this;
        const parentProps = this.props._this;
        let tasks = {};
        for (let i in this.state.tasks) {
            const key = this.state.tasks[i]
            tasks[key] = "0";
        }
        const state = this.state;
        const user=GetUserToken();
        let hub = {
            Org: GetUserToken().OrgCode,
            SenderName : "FleetMinder Job ("+this.props.job.jobNumber+") reminder",
            To: this.props.job.inChargeEmail,
            /*From: user.Username,*/
            ReplyTo: "do-not-reply@bizminder.co.za",
            Body: state.Body,
            Subject:state.Subject,
        }

        let backend = eConfig.mainBackend;
        let endpoint = "/api/fleet/job/schedule/email-send";

        if(state.JobStage==="no"){
            endpoint = "/api/fleet/job/schedule/status-close";
        }

        console.log("submitScheduleEmail *** send > ", hub);


        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitScheduleEmail *** response > ", dataIn);
            alert("Thank you, Job schedule email send");
            fnClose();

        });
    }

    getStatusList = () => {
        let ls = [];
        const options = this.props.options;
        const keyOne = "position";
        options.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)
        for (let i in options) {
            const row = options[i];
            if (row.category === "status" && row.tableref === "vehicle") {
                ls.push({
                    key: row.name,
                    val: row.name.toLocaleUpperCase()
                })
            }
        }
        return ls;
    }

    boxSelectOption = (label, name, required, options, handFunc, smallText) => {
        if (typeof options === "undefined") {
            options = [];
        }
        return (
            <div className="form-group row" style={{width: "100%"}}>
                <label style={{color: "gray"}}>{label}</label>
                <select
                    name={name}
                    className="form-control"
                    required={required}
                    onChange={(e) => handFunc(e)}
                    style={{minWidth:"100%"}}
                >
                    <option value={""}>Choose...</option>
                    {options.map((row, index) => {
                        return (
                            <option key={index} value={row.key}>{row.val}</option>
                        )
                    })}

                </select>
                {smallText !== "" ?
                    <small className="form-text text-muted">{smallText}
                        else.
                    </small> : null
                }
            </div>
        )
    }
    boxTextareaVertical = (label, name, type, placeholder, required, handFunc, smallText) => {
        return (
            <div className="form-group row">
                <label style={{color: "gray"}}>{label}</label>
                <textarea
                    name={name}
                    type={type}
                    className="form-control"
                    aria-describedby={placeholder}
                    required={required}
                    onChange={(e) => handFunc(e)}
                />
                {smallText !== "" ?
                    <small className="form-text text-muted">{smallText}
                        else.
                    </small> : null
                }
            </div>
        )
    }
    boxInputVertical = (label, name, type, placeholder, required, handFunc, smallText,defaultValue,readonly) => {
        return (
            <div className="form-group row">
                <label style={{color: "gray"}}>{label}</label>
                <input
                    name={name}
                    type={type}
                    className="form-control"
                    aria-describedby={placeholder}
                    required={required}
                    onChange={(e) => handFunc(e)}
                    value={defaultValue}
                    readOnly={readonly}
                />
                {smallText !== "" ?
                    <small className="form-text text-muted">{smallText}
                        else.
                    </small> : null
                }
            </div>
        )
    }
    renderBoxSelectedVehicle=()=>{
        const job = this.props.job;
        let vehicle = "-----";
        console.log("renderBoxSelectedVehicle &&&&& > ",job," > ",this.props);
        if(job===null){
            return
        }
        return(
            <div className="form-group row" style={{width: "100%"}}>
                <label style={{color: "gray"}}>Selected Vehicle</label>
                <select
                    name={"vehicle"}
                    className="form-control"
                    required={false}
                    style={{minWidth:"100%"}}
                >
                    <option value={""}>{job.ref || "-----"}</option>
                </select>
            </div>
        )
    }

    getVehicleReg=()=>{
        const job = this.props.job;
        if(job ===null){return "---"}
        return job.ref;
    }
    getJobNumber=()=>{
        const job = this.props.job;
        if(job ===null){return "---"}
        return job.jobNumber;
    }
    getJobFrom=()=>{
        const job = this.props.job;
        if(job ===null){return "---"}
        return job.jobFrom;
    }

    render() {

        const title = this.props.popTitle;
        const _this = this.props._this;
        const openStatus = this.props.popStatus;
        const fnClose = this.props.popFnClose;
        const job = this.props.job;

        if (!openStatus) {
            return null
        }


        const actions = [
            <FlatButton
                label={"Close"}
                primary={true}
                onClick={fnClose}
            />,
        ];

        return (
            <MuiThemeProvider>
                <Dialog
                    title={"New Job Schedule"}
                    actions={actions}
                    modal={false}
                    fullWidth={true}
                    fullScreen
                    open={openStatus}
                    onRequestClose={fnClose}
                    autoScrollBodyContent={true}
                    style={{marginTop: 60, minWidth: 500, maxWidth: "none"}}
                >

                    <form onSubmit={this.submitScheduleEmail}>
                        <div className={"container"}>
                            <div className={"row"}>
                                <div className={"col-lg-12"}>
                                    <br/>
                                    <p
                                        className={"alert alert-info"}
                                        style={{
                                            textAlign: "center"
                                        }}
                                    >Send Email  Schedule Status of vehicle: <span style={{color:"red"}}>{this.getVehicleReg()}</span></p>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col"}>
                                    <fieldset className="scheduler-border">
                                        <legend className="scheduler-border" style={{color: "red"}}>New</legend>
                                        {this.boxInputVertical(
                                            "Receiver name",
                                            "ReceiverName",
                                            false,
                                            this.getStatusList(),
                                            this.handleInput,
                                            "",
                                            "",
                                            this.props.job !==null?this.props.job.inChargeName:"----",
                                            true
                                        )}
                                        {this.boxInputVertical(
                                            "Receiver Email",
                                            "ReceiverEmail",
                                            false,
                                            [
                                                {key:"yes",val:"Keep job Open"},
                                                {key:"no",val:"Close this job"}
                                            ],
                                            this.handleInput,
                                            "",
                                            "",
                                            this.props.job !==null?this.props.job.inChargeEmail:"----",
                                            true
                                        )}
                                    </fieldset>
                                </div>

                                <div className={"col"}>
                                    <fieldset className="scheduler-border">
                                        <legend className="scheduler-border" style={{color: "red"}}>Info</legend>

                                        {this.boxInputVertical(
                                            "Subject",
                                            "Subject",
                                            "text",
                                            "",
                                            true,
                                            this.handleInput,
                                            "")}

                                        {this.boxTextareaVertical(
                                            "Message",
                                            "Body",
                                            "text",
                                            "",
                                            true,
                                            this.handleInput,
                                            "")}

                                    </fieldset>
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col"}>
                                    <button
                                        type={"button"}
                                        onClick={() => fnClose()}
                                        className={"btn btn-danger pull-left"}
                                    >Cancel
                                    </button>
                                    <button
                                        type={"submit"}
                                        className={"btn btn-primary pull-right"}
                                    >Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Dialog>


            </MuiThemeProvider>
        )
    }

}
