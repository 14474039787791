import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import "../../asset/css/fieldset-lengend.css";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";


export default class CompPopupManageIssue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openStatus: true,
            tasks: {},
            newEntry: {
                Description : "",
                Author : "",
                AssignTo : "",
                NotifyAssignee : false,
                RespondOn : "",
                Priority : "",
                FaultStatus : "",
                Comments : "",
                ReportBy : "",
            }
        }
    }

    fnClose = () => {
        this.setState({
            openStatus: false,
        })
    }
    handleInput=async (e,key)=>{
        let tmp = this.state;
        tmp.newEntry[key]=e.target.value;
        this.setState(tmp)
    }

    submitFaultEntry=async (e)=>{
        e.preventDefault();
        if(!window.confirm("Are you sure that you want to submit this issue?")){
            return
        }

        let entry = {...this.state.newEntry};
        entry.Org = GetUserToken().OrgCode;
        entry.Vehicle =this.props.vehicle.ref;
        entry.NotifyAssignee = entry.NotifyAssignee===true?"yes":"no";

        //todo let add our default variable
        entry.Id = "";
        entry.Status="";
        entry.Date ="";
        entry.Time ="";
        entry.OrgDateTime ="";

        let hub =  entry;
        const fnClose = this.props.popFnClose;
        let _this = this;
        const parentProps = this.props._this;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/fleet/job/fault/new";

        console.log("submitFaultEntry obj *** send > ", hub);
        console.log("submitFaultEntry *** send > ", hub);


        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitFaultEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {

                    parentProps.loadInitialData();
                    alert("Thank you, Job schedule submitted");
                    fnClose()
                }
            }

        });

    }

    handleNotify=()=>{
        let tmp = this.state;
        tmp.newEntry.NotifyAssignee = !tmp.newEntry.NotifyAssignee;
        this.setState(tmp);
    }

    getOptions=(entityIn)=>{
        //todo define props keys
        const entityData=entityIn+"s";
        const entityAttributes = entityIn+"Attributes";

        //todo get props data
        const dataAttribute=(typeof this.props[entityAttributes]!=="undefined"?this.props[entityAttributes]:[]);
        const dataEntity=(typeof this.props[entityData]!=="undefined"?this.props[entityData]:[]);

        const cat="names";
        let keyList=[];
        for(let i in dataAttribute){
            if(dataAttribute[i].section===cat){
                keyList.push(dataAttribute[i].fieldname);
            }
        }

        let ls=[];
        for(let e in dataEntity){
            const rowParrent=dataEntity[e];
            const key = rowParrent.ref;
            let val="";
            let x=0;
            for(let i in keyList){
                const field=keyList[i];
                const v=rowParrent.data[field]||"--"

                val+=x==0?" "+v:", "+v
            }
            ls.push({
                key:key,
                val:val
            })
        }

        return ls.map((row,index)=>{
            return(
                <option value={row.val}>{row.val}</option>
            )
        })

    }

    getDatabaseOptions=(cat,type)=>{
        const options=this.props.DatabaseOptions;
        const keyOne = "position";
        options.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)

        let ls=[];
        for(let i in options){
            const row = options[i];
            if(row.category===cat && row.type ===type){
                ls.push({
                    key:row.name,
                    val:row.name.toLocaleUpperCase(),
                })
            }
        }

        return ls.map((row,index)=>{
            return(
                <option value={row.key}>{row.val}</option>
            )
        })
    }

    getVehicleId=()=>{
        const vehicle=this.props.vehicle;
        console.log("getVehicleId ###--> ",this.props);
        if(typeof vehicle ==="undefined"){
            return "---"
        }
        return vehicle.ref;
    }

    render() {

        const title = this.props.popTitle;
        const _this = this.props._this;
        const openStatus = this.props.popStatus;
        const fnClose = this.props.popFnClose;
        const vehicle=this.props.vehicle;



        if(vehicle===null){
            return null
        }
        if(!openStatus){
            return null
        }

        console.log("getVehicleId ###--> ",this.props.vehicle);


        const actions = [
            <FlatButton
                label={"Close"}
                primary={true}
                onClick={fnClose}
            />,
        ];

        return (
            <MuiThemeProvider>
                <Dialog
                    title={"New Issue"}
                    actions={actions}
                    modal={false}
                    fullWidth={true}
                    fullScreen
                    open={openStatus}
                    onRequestClose={fnClose}
                    autoScrollBodyContent={true}
                    style={{marginTop: 100,minWidth:500,maxWidth:"none"}}
                >


                    <div className={"container"} >
                        <form onSubmit={this.submitFaultEntry}>
                        <br/>
                        <p className={"alert alert-info"}>New Vehicle Issue
                            <span className={"pull-right"} style={{color:"red"}}>

                            {" # "+this.getVehicleId()}</span>
                        </p>
                        <div className="row container">
                            <div className="col col-xs-12 col-lg-6">

                                    <div className="form-group col-md-12 row">
                                        <div className="col-sm-4">
                                        <label className="col-form-label col-form-label-sm pull-right">Reported on</label>
                                        </div>
                                        <div className="col-sm-8 pull-right">
                                            <input type="date" className="form-control"
                                                   onChange={(e)=>this.handleInput(e,"RespondOn")}
                                                   required={true}
                                                   value={this.state.newEntry["RespondOn"]}
                                            />
                                        </div>
                                    </div>


                                    {/*<div className="form-group col-md-12 row">
                                        <div className="col-sm-4">
                                        <label className="col-form-label col-form-label-sm pull-right">Vehicle</label>
                                        </div>
                                        <div className="col-sm-8 pull-right">
                                            <select className="form-control"
                                                    onChange={(e)=>this.handleInput(e,"Vehicle")}
                                                    required={true}
                                                    value={this.state.newEntry["Vehicle"]}
                                            >
                                                <option>{this.getVehicleId()}</option>
                                            </select>
                                        </div>
                                    </div>*/}

                                    <div className="form-group col-md-12 row">
                                        <div className="col-sm-4">
                                        <label className="col-form-label col-form-label-sm pull-right">Reported by</label>
                                        </div>
                                        <div className="col-sm-8 pull-right">
                                            <select
                                                className="form-control"
                                                onChange={(e)=>this.handleInput(e,"ReportBy")}
                                                required={true}
                                                value={this.state.newEntry["ReportBy"]}
                                            >
                                                <option>Choose (Driver)...</option>
                                                {this.getOptions("Driver")}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group col-md-12 row">
                                        <div className="col-sm-4">
                                        <label className=" col-form-label col-form-label-sm  pull-right">Assign to</label>
                                        </div>
                                        <div className="col-sm-8 pull-left">

                                            <select className="form-control">
                                                <option>Choose (Personnel)...</option>
                                                {this.getOptions("Personnel")}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="form-group col-md-12 row">
                                        <div className="col-sm-4">
                                        <label className="col-form-label col-form-label-sm  pull-right">Notify
                                            assignee</label>
                                        </div>

                                        <div className="col-sm-8">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <input
                                                className="form-check-input pull-left" type={"checkbox"}
                                                   style={{float: "left", marginTop: 10, marginLef: 50}}
                                                onChange={()=>this.handleNotify()}
                                            />
                                            <br/>
                                            <small style={{fontSize: 9}}>Check this box to notify assignee that he is in
                                                charge of the issue
                                            </small>
                                        </div>
                                    </div>

                                    <div className="form-group col-md-12 row">
                                        <div className="col-sm-4">
                                        <label className="col-sm-4 col-form-label col-form-label-sm pull-right">Priority</label>
                                        </div>
                                        <div className="col-sm-8 pull-left">
                                            <select className="form-control"
                                                    onChange={(e)=>this.handleInput(e,"Priority")}
                                                    required={true}
                                                    value={this.state.newEntry["Priority"]}
                                            >
                                                <option value={""}>Choose...</option>
                                                {this.getDatabaseOptions("priority","issue")}
                                            </select>
                                        </div>
                                    </div>


                            </div>

                            <div className="col col-xs-12 col-lg-6">

                                <div className="form-group col-md-12 row">
                                    <div className="col-sm-4 pull-left">
                                        <label
                                            className="col-sm-4 col-form-label col-form-label-sm  pull-right">Status</label>
                                    </div>
                                    <div className="col-sm-8 pull-left">
                                        <select className="form-control"
                                                onChange={(e)=>this.handleInput(e,"FaultStatus")}
                                                required={true}
                                                value={this.state.newEntry["FaultStatus"]}
                                        >
                                            <option value={""}>Choose...</option>
                                            {this.getDatabaseOptions("status","issue")}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group col-md-12 row">
                                    <div className="col-sm-4 pull-left">
                                        <label
                                            className="col-form-label col-form-label-sm  pull-right">Description</label>
                                    </div>
                                    <div className="col-sm-8 pull-right">
                                        <input type="text" className="form-control" id="inputCity"
                                               onChange={(e)=>this.handleInput(e,"Description")}
                                               required={true}
                                               value={this.state.newEntry["Description"]}
                                        />
                                    </div>
                                </div>

                                <div className="form-group col-md-12 row">
                                    <div className="col-sm-4 pull-left">
                                        <label className=" col-form-label col-form-label-sm pull-right">Comments</label>
                                    </div>
                                    <div className="col-sm-8 pull-left">
                                        <textarea size="20" className="form-control" style={{minHeight: 210}}
                                                  onChange={(e)=>this.handleInput(e,"Comments")}
                                                  required={true}
                                                  value={this.state.newEntry["Comments"]}
                                        >

                                        </textarea>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="row container">
                            <div className="col col-xs-12 col-lg-12" style={{paddingRight:50}}>
                                <button type={"submit"} className={"btn btn-primary pull-right"}>Save</button>
                                <button className={"btn btn-danger pull-right"} onClick={()=>fnClose()}>Cancel</button>
                            </div>
                        </div>

                        </form>


                    </div>


                </Dialog>
            </MuiThemeProvider>
        )
    }

}
