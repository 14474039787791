
import React from "react";

const FindDriverFullName=({ref,Drivers,DriverAttributes,AssignDrivers})=>{
    let names="";
    let driverRef="";
    for(let i in AssignDrivers){
        const row=AssignDrivers[i];
        if(row.vehicle===ref){
            driverRef=row.driver;
        }
    }
    let driver=null;
    for(let i in Drivers){
        const row =Drivers[i];
        if(row.ref===driverRef){
            driver=row;
        }
    }

    if(driver===null){
        return names;
    }
    const attributes=DriverAttributes;
    for(let i in attributes){
        const row =attributes[i];
        if(row.section==="names"){
            if(typeof driver.data!=="undefined"){
                names = names + driver.data[row.fieldname]+" ";
            }

        }
    }
    return names.toLocaleUpperCase();
}

export default FindDriverFullName;


