import React, {Component} from 'react';
import '../../asset/css/login.css';
import '../../asset/css/stepbystepform.css';
import 'bootstrap/dist/css/bootstrap.css';
import {Card, ListGroup, ListGroupItem, Nav} from 'react-bootstrap';
import CancelIcon from '../../asset/img/cancel-24px.svg';
import Calendar from "./Calendar";
import eConfig from "../../config";
import {PostToBackend} from "../../api/services";

export default class ComUtilMaintenanceActivityType extends Component {
    constructor(props) {
        super(props);
        this.state = {

            invoiceItems: [],
            invoiceVat: 0,
            invoiceSubtotal: 0,
            invoiceTax: 0,
            invoiceDue: 0,

            invoiceSupplyCompany: "Easipath Solutions",
            invoiceSupplyAddressLine1: "01 Kensington Road",
            invoiceSupplyAddressLine2: "Milnerton",//suburb
            invoiceSupplyAddressLine3: "Cape town",//city
            invoiceSupplyAddressLine4: "Western Cape",//province
            invoiceSupplyAddressLine5: "South Africa",//Country

            invoiceSupplyNumber: "",
            invoiceSupplyContactPhone: "+27 72 913 9504",
            invoiceNumber: "",
            invoiceDate: "",

            isCapturing: false,


        }
    }

    async componentDidMount() {

    }

    setInvDate = (dateIn) => {
        this.setState({
            invoiceDate: dateIn
        })
    }
    setValueBox = (e) => {
        const val = e.target.value;
        const key = e.target.name;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    getValue = (key) => {
        return this.state[key]
    }

    submitEntryInvoice=async (e)=>{
        e.preventDefault();
        const job = this.props.state.selectedJob;
        let entry={invoice:{},items:[]};
        let state=this.state;

        /*
        invoiceVat: 0,
            invoiceSubtotal: 0,
            invoiceTax: 0,
            invoiceDue: 0,
            */

        entry.invoice={
            JobNumber  :parseFloat(job.jobnumber),
            InvNumber  : state.invoiceNumber,
            OderNumber :state.invoiceSupplyNumber,
            Vehicle    : job.vehicle,
            SourceWork :"external",
            Supplier   :state.invoiceSupplyCompany,
            InvDate    :state.invoiceDate,
            Subtotal :parseFloat(state.invoiceSubtotal),
            Tax      :parseFloat(state.invoiceTax),
            TaxRate  :parseFloat(state.invoiceVat),
            TotalDue :parseFloat(state.invoiceDue)
        };

        for(let i in state.invoiceItems){
            const row=state.invoiceItems[i];
            let o={};

            let total = parseFloat(o.Quantity) * parseFloat(row.price);
            let tax= (total / 100)* parseFloat(state.invoiceVat);
            if(isNaN(tax)){
                tax=0
            }
            o.JobNumber = job.jobnumber;
            o.InvNumber   = state.invoiceNumber;
            o.Item        =row.item;
            o.Description =row.description;
            o.Quantity     = parseFloat(row.qty);
            o.Price       =parseFloat(row.price);
            o.Tax         = parseFloat(tax.toFixed(2));
            o.TaxRate     =parseFloat(state.invoiceVat);
            o.Total       =parseFloat(total.toFixed(2));

            entry.items.push(o);
        }


        let hub={
            Data:entry,
            Field:"invoice",
            Job:job
        }
        console.log("LOGGER SUBMIT :> ", hub);

        const fnClose = this.props.fnClose;
        let _this = this;
        const parentProps = this.props._this;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/fleet/expense/new";

        console.log("submitEntryLabout obj *** send > ", hub);

        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitEntryLabout *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    if (dataIn.status !== "OK") {
                        parentProps.loadInitialData();
                        alert("Thank you, Labour Expense submitted");
                        fnClose()
                    }

                }
            }

        });
    }


    renderFilterBoxSupplier = () => {
        let ls = [];
        for (let i in this.state.Suppliers) {
            const row = this.state.Suppliers[i];
            ls.push(row.ref);
        }
        return (
            <div className="form-group col-md-12 col-lg-12">
                <label htmlFor="inputState">Supplier</label>
                <select
                    name={"selectedSupplier"}
                    className="form-control"
                    onChange={(e) => this.setValueBox(e)}
                    value={this.getValue("selectedSupplier")}
                    required={true}
                >
                    <option value="" selected>Choose...[{ls.length}]</option>
                    {ls.map((item, index2) => {
                        let key = item;
                        let desc = item.toLocaleUpperCase();
                        return (
                            <option key={index2} value={key}>{desc}</option>
                        )
                    })}

                </select>
            </div>
        )
    }
    getOptions=(entityIn)=>{
        //todo define props keys
        const entityData=entityIn+"s";
        const entityAttributes = entityIn+"Attributes";

        //todo get props data
        const dataAttribute=(typeof this.props.state[entityAttributes]!=="undefined"?this.props.state[entityAttributes]:[]);
        const dataEntity=(typeof this.props.state[entityData]!=="undefined"?this.props.state[entityData]:[]);

        const cat="names";
        let keyList=[];
        for(let i in dataAttribute){
            if(dataAttribute[i].section===cat){
                keyList.push(dataAttribute[i].fieldname);
            }
        }

        let ls=[];
        for(let e in dataEntity){
            const rowParrent=dataEntity[e];
            const key = rowParrent.ref;
            let val="";
            let x=0;
            for(let i in keyList){
                const field=keyList[i];
                const v=rowParrent.data[field]||"--"

                val+=x==0?" "+v:", "+v
            }
            ls.push({
                key:key,
                val:val
            })
        }

        return ls.map((row,index)=>{
            return(
                <option value={row.val}>{row.val}</option>
            )
        })

    }


    renderInvoiceForm = () => {

        const innerStyle = {
            companyBoxFrame: {
                borderWidth: 1,
                borderColor: "#dee2e6",
                background: "#e9ecef",
                color: "black",
                borderRadius: 4,
            },
            rightBoxTopLeft: {
                textAlign: "left",
                fontWeight: "bold"
            },
            rightBoxTopRight: {
                textAlign: "right",
            }
        };
        let _this = this;
        const taskList = [
            "Oil change",
            "Brakes",
            "Front while check",
        ];

        const addItem = (category) => {
            const item = {
                category: category,
                name: "",
                description: "",
                qty: 1,
                price: 0,
                total: 0
            };
            let tmp = _this.state;
            tmp.invoiceItems.push(item)
            _this.setState(tmp);
        }
        const removeItem = (index) => {
            let tmp = _this.state;
            let invoiceItems = [];
            for (let i in tmp.invoiceItems) {
                const row = tmp.invoiceItems[i];
                if (parseInt(i) !== parseInt(index)) {
                    invoiceItems.push(row)
                }
            }
            tmp.invoiceItems = invoiceItems;
            tmp = clcTotals(tmp);
            _this.setState(tmp);
        }
        const getItemValue = (index, key) => {
            let tmp = _this.state;
            let qty = "";
            if (key === "qty" || key === "price") {
                qty = 0
            }
            for (let i in tmp.invoiceItems) {
                const row = tmp.invoiceItems[i];
                if (parseInt(i) === parseInt(index)) {
                    //console.log("getItemValue ):(--> ",key," > ",row);
                    qty = row[key];
                }
            }
            return qty;
        }
        const setItemValue = (e, index) => {
            e.preventDefault();
            const key = e.target.name;
            const val = e.target.value;

            let tmp = _this.state;
            let invoiceItems = [];
            for (let i in tmp.invoiceItems) {
                const row = tmp.invoiceItems[i];
                if (parseInt(i) === parseInt(index)) {
                    row[key] = val;
                    invoiceItems.push(row)
                } else {
                    invoiceItems.push(row)
                }
            }
            tmp = clcTotals(tmp);
            _this.setState(tmp);
        }
        const setInnerFloatValue = (e) => {
            e.preventDefault();
            const key = e.target.name;
            const val = e.target.value;
            const myValue = parseFloat(val);

            let vat = 0;
            if (!isNaN(myValue)) {
                vat = parseInt(myValue);
            }
            let tmp = _this.state;
            tmp[key] = vat;
            tmp = clcTotals(tmp);
            _this.setState(tmp);
        }
        const setInnerStringValue = (e) => {
            e.preventDefault();
            const key = e.target.name;
            const val = e.target.value;
            let tmp = _this.state;
            tmp[key] = val;
            tmp = clcTotals(tmp);
            _this.setState(tmp);
        }
        const clcTotals = (tmp) => {

            //todo let calculate subtotal and tax
            let totalTax = 0;
            let subtotal = 0;
            let totalCost = 0;

            for (let i in tmp.invoiceItems) {
                const row = tmp.invoiceItems[i];
                let qty = parseFloat(row.qty);
                let price = parseFloat(row.price);
                if (isNaN(qty)) {
                    qty = 0
                }
                if (isNaN(price)) {
                    price = 0
                }
                //const cost  = qty * price;
                subtotal = subtotal + (qty * price);
            }


            const vat = parseFloat(tmp.invoiceVat);


            if (vat > 0) {
                totalTax = (subtotal / 100) * vat;
                totalCost = subtotal - totalTax;
            } else {

            }

            tmp.invoiceVat = vat;
            tmp.invoiceSubtotal = subtotal.toFixed(2);
            tmp.invoiceDue = totalCost.toFixed(0);
            ;
            tmp.invoiceTax = totalTax.toFixed(2);
            tmp.invoiceDue = tmp.invoiceSubtotal - tmp.invoiceTax;
            return tmp;
        }

        let ls = [];

        return (
            <div className={"container"}>
                <div className={"row"}>
                    {/*<div className={"col col-lg-4"} style={innerStyle.companyBoxFrame}>
                        <h4>
                            {this.state.invoiceSupplyCompany}
                        </h4>
                        <p>{this.state.invoiceSupplyAddressLine1}</p>
                        <p>
                            {this.state.invoiceSupplyAddressLine2},
                            &nbsp;{this.state.invoiceSupplyAddressLine3},
                            &nbsp;{this.state.invoiceSupplyAddressLine4}
                        </p>
                        <p>{this.state.invoiceSupplyContactPhone}</p>
                    </div>*/}
                    <div className={"col col-lg-4"} style={innerStyle.companyBoxFrame}>
                        <h4>
                            <select className={"from-control"}>
                                <option value={""}>Choose Supplier....</option>
                                {this.getOptions("Supplier")}
                            </select>
                        </h4>
                        {/*<p>{this.state.invoiceSupplyAddressLine1}</p>
                        <p>
                            {this.state.invoiceSupplyAddressLine2},
                            &nbsp;{this.state.invoiceSupplyAddressLine3},
                            &nbsp;{this.state.invoiceSupplyAddressLine4}
                        </p>
                        <p>{this.state.invoiceSupplyContactPhone}</p>*/}
                    </div>
                    <div className={"col col-lg-4"}>

                    </div>
                    <div className={"col col-lg-4"}>
                        <div className="card">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Order #</span>
                                    <input
                                        size={5}
                                        name={"invoiceSupplyNumber"}
                                        className={"pull-right"}
                                        style={innerStyle.rightBoxTopRight}
                                        value={this.state.invoiceSupplyNumber}
                                        onChange={(e) => setInnerStringValue(e)}
                                        placeholder={"------------"}
                                    />
                                </li>
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Invoice #</span>
                                    <input
                                        size={5}
                                        name={"invoiceNumber"}
                                        className={"pull-right"}
                                        style={innerStyle.rightBoxTopRight}
                                        value={this.state.invoiceNumber}
                                        onChange={(e) => setInnerStringValue(e)}
                                        placeholder={"------------"}
                                    />


                                </li>

                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Date</span>
                                    <Calendar

                                        style={{width:"55%",float:"right",marginTop:1}}
                                        setDate={this.setInvDate}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col col-lg-12"}>
                        <br/>
                        <h2>Invoice Details</h2>
                        <br/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col col-lg-12"}>
                        <table className={"table table-bordered"}>
                            <thead class="thead-light">
                            <th scope="col">#</th>
                            <th scope="col">Item</th>
                            <th scope="col">Description</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Price</th>
                            <th scope="col">Total</th>
                            <th scope="col"></th>
                            </thead>
                            <tbody>
                            {this.state.invoiceItems.map((row, index) => {

                                let total = parseInt(row.qty) * parseFloat(row.price);
                                if (isNaN(total)) {
                                    total = 0
                                }
                                //total = parseFloat(total);
                                return (
                                    <tr key={"ttr" + index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {row.category === "task" ?
                                                <select
                                                    name={"item"}
                                                    defaultValue={getItemValue(index, "item")}
                                                    onChange={(e) => setItemValue(e, index)}
                                                >
                                                    <option value={""}>--Select Task--</option>
                                                    {taskList.map((item, aIndex) => {
                                                        return (
                                                            <option key={"opp" + aIndex} value={item}>{item}</option>
                                                        )
                                                    })}
                                                </select> :
                                                <input
                                                />
                                            }
                                        </td>
                                        <td>
                                            <input
                                                name="description"
                                                value={getItemValue(index, "description")}
                                                onChange={(e) => setItemValue(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name={"qty"}
                                                value={getItemValue(index, "qty")}
                                                onChange={(e) => setItemValue(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name={"price"}
                                                value={getItemValue(index, "price")}
                                                onChange={(e) => setItemValue(e, index)}
                                            />
                                        </td>
                                        <th>
                                            R {total}
                                        </th>
                                        <td>
                                            <a onClick={() => removeItem(index)}>
                                                <img src={CancelIcon} width={30} style={{color: "red"}}/>
                                            </a>
                                        </td>

                                    </tr>
                                )
                            })}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th colSpan={7}>
                                    <button
                                        type="button"
                                        className="btn btn-outline-success pull-right"
                                        onClick={() => addItem("task")}
                                    >
                                        Add Task Item
                                    </button>

                                    {/*<button
                                        type="button"
                                        className="btn btn-outline-secondary pull-right"
                                        onClick={() => addItem("new")}
                                    >
                                        Add New Item
                                    </button>*/}
                                </th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col col-lg-4"}>
                        <div className="card">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Tax Rate (%)</span>
                                    <input
                                        name={"invoiceVat"}
                                        className={"pull-right"}
                                        style={{width: 50}}
                                        value={this.state.invoiceVat}
                                        onChange={(e) => setInnerFloatValue(e)}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={"col col-lg-4"}>

                    </div>
                    <div className={"col col-lg-4"}>
                        <div className="card">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Subtotal</span>
                                    <span className={"pull-right"}
                                          style={innerStyle.rightBoxTopRight}>R {this.state.invoiceSubtotal}</span>
                                </li>
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Tax</span>
                                    <span className={"pull-right"}
                                          style={innerStyle.rightBoxTopRight}>R {this.state.invoiceTax}</span>
                                </li>
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Total Due</span>
                                    <span className={"pull-right"}
                                          style={innerStyle.rightBoxTopRight}>R {this.state.invoiceDue}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        return (


            <div className="row ">
                <div className="col col-xs-12 col-lg-12">
                    <Card border="primary" style={{height: '100%'}}>{/*
                                    <Card.Header>
                                        <Card.Title style={{color: "black"}}>
                                            Invoice
                                        </Card.Title>
                                    </Card.Header>*/}
                        <Card.Body>
                            {this.renderInvoiceForm()}
                        </Card.Body>
                    </Card>
                </div>
                <div className={"col col-xs-12 col-lg-12"}>
                    <button
                        className={"btn btn-primary pull-right"}
                        onClick={(e)=>this.submitEntryInvoice(e)}
                    >
                        Submit
                    </button>
                </div>
            </div>


        )
    }

}
