import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {
    fetchUtilData,
    renderList,
    renderFormWithSubmit, removeEntry, renderListInnerData, setValueBox, getValue, setValue
} from "./api";
import {GetUserToken, PostToBackend} from "../../api/services";
import {Badge} from "react-bootstrap";
import eConfig from "../../config";
import 'bootstrap/dist/css/bootstrap.css';
import {Card} from 'react-bootstrap';
import {submitEntry} from "../util/api-util";
import MyPopupDisplay from "./popup-display";
import MyPopupForm from "./popup-form";
import MyPopupManageService from "./popup-manage-service";


export default class ComUtilMaintenanceActivityType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Activities: [],
            Services: [],
            Tasks: [],
            Vehicles: [],
            PersonnelAttributes:[],
            Personnel:[],
            newEntry: {},
            selectedVehicle: "",
            selectedActivity: "",
            selectedService: "",
            selectedTask: "",
            showList: true,
            cqlTable: "MaintenanceTask",
            currentView: null,
            showPopup: false,
            showPopupNewService: false,
            showPopupNewTask: false,
            showPopupTaskView: false,
            popupTaskViewData: [],
            popupTaskViewCols: [],
            popupTaskViewTitle: "",
            popupTaskViewDisplay: "",
            popupTaskDeleteKey: {},
            popupTaskDeleteTable: "",
            showPopupTaskNew: false,
        }
    }

    async componentDidMount() {
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await fetchUtilData(this, this.state.cqlTable, "Data");
        await fetchUtilData(this, "UtilActivityList", "Activities");
        //await fetchUtilData(this, "Vehicle", "Vehicles");
        await fetchUtilData(this,  "GeneralTableData", "Vehicles", "vehicle");
        await fetchUtilData(this, "maintenanceplaning", "Services");
        await fetchUtilData(this, "GeneralTableField", "PersonnelAttributes", "personnel");
        await fetchUtilData(this, "GeneralTableData", "Personnel", "personnel");
        //await fetchUtilData(this, "MaintenancePlanning", "Tasks");
        await this.setState({
            showPopup: false,
            showPopupNewService: false,
            showPopupNewTask: false,
            showPopupTaskView: false,
            popupTaskViewData: [],
            popupTaskViewCols: [],
            popupTaskViewTitle: "",
            popupTaskViewDisplay: "",
            popupTaskDeleteKey: {},
            popupTaskDeleteTable: "",
            showPopupTaskNew: false,
        })

    }
    setValueBox = (e) => {
        const val = e.target.value;
        const key = e.target.name;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    getValue = (key) => {
        return this.state[key]
    }
    openNewTask = (rowIn) => {
        const state = this.state;
        let tmp = this.state;
        tmp.newEntry.VehicleID = state.selectedVehicle;
        tmp.newEntry.Activity = state.selectedActivity;
        tmp.newEntry.Service = rowIn.name;
        tmp.showList = false;


        const options = ["yes", "no"];
        const categories = ["fixed mileage", "recurring mileage", "time period"];
        const taskTypes = ["Both Part & Labour", "part", "labour"];
        let ls = [
            {
                field: "Type",
                label: "Type oF Task",
                options: taskTypes,
                type: "option",
                required: true,
                dataType: "string"
            },
            {field: "Name", label: "Name of Task", options: options, type: "text", required: true, dataType: "string"},
            {
                field: "CostParts",
                label: "Cost Parts",
                options: options,
                type: "text",
                required: true,
                dataType: "float"
            },
            {
                field: "CostLabour",
                label: "Cost Labour",
                options: options,
                type: "text",
                required: true,
                dataType: "float"
            },
        ]

        tmp.showPopupTaskNew = true;
        tmp.showPopupTaskView = false;
        tmp.popupTaskViewData = ls;
        tmp.popupTaskViewCols = [];
        tmp.popupTaskViewDisplay = "";
        tmp.popupTaskViewTitle = "New Task for  Veh:[" + this.state.selectedVehicle + "] Activity:[" + this.state.selectedActivity + "] " +
            "Service [" + rowIn.name + "]";
        this.setState(tmp);

        console.log("******--> ", this.state);

    }
    closeNewTask = () => {
        const state = this.state;
        let tmp = this.state;
        tmp.newEntry.VehicleID = "";
        tmp.newEntry.Activity = "";
        tmp.newEntry.Service = "";
        tmp.showList = true;
        this.setState(tmp);
    }
    closeShowPopup = () => {
        this.setState({
            currentView: null,
            showPopup: false
        })
    }
    submitTask = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        const _this = this;
        const table = "MaintenanceTask";

        let entry = {...this.state.newEntry};
        let o = {};
        o.Org = GetUserToken().OrgCode;
        o.CostParts = parseFloat(entry.CostParts);
        o.CostPartsDiscount = parseFloat(entry.CostPartsDiscount);
        o.CostLabour = parseFloat(entry.CostLabour);
        o.CostLabourDiscount = parseFloat(entry.CostLabourDiscount);
        o.LastDueMileage = parseFloat(entry.LastDueMileage);
        o.NextDueMileage = parseFloat(entry.NextDueMileage);
        o.Activity = entry.Activity;
        o.Category = entry.Category;
        o.CostLabourVat = entry.CostLabourVat;
        o.CostPartsVat = entry.CostPartsVat;
        o.LastDueDate = entry.LastDueDate;
        o.Manager = entry.Manager;
        o.Name = entry.Name;
        o.Service = entry.Service;
        o.VehicleID = entry.VehicleID;


        //todo let add our default variable
        o.Id = "";
        o.Status = "";
        o.Date = "";
        o.Time = "";
        o.OrgDateTime = "";

        let hub = {
            Fields: [],
            DbName: "fleetminder",
            TableName: table,
            In: o,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/add";

        console.log("submitTask send > ", hub, " > ", o);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitTask response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    alert("Thank you Record submitted!");
                    let tmp = _this.state;
                    tmp.showList = true;
                    tmp.newEntry = {};
                    _this.setState(tmp);
                    _this.loadInitialData();
                }
            }

        });
    }
    renderList = () => {
        const keyList = [
            {field: "name", label: "Name"},
            {field: "activity", label: "Activity"},
            {field: "orgdatetime", label: "Last Updated"}
        ];
        const deleteKey = ["org", "service", "name"];
        const deleteTable = "MaintenanceTask";
        return renderList(this, "Data", keyList, deleteKey, deleteTable, "showList", removeEntry)
    }
    closeTaskView = () => {
        this.setState({
            showPopupNewTask: false,
            showPopupTaskView: false,
            popupTaskViewData: [],
            popupTaskViewCols: [],
            popupTaskViewTitle: "",
            showPopupTaskNew: false,
        })
    }
    closeTaskNew = () => {
        this.setState({
            showPopupNewTask: false,
            showPopupTaskView: false,
            popupTaskViewData: [],
            popupTaskViewCols: [],
            popupTaskViewTitle: "",
            showPopupTaskNew: false,
        })
    }
    openNewServiceForm = () => {
        const state = this.state;
        let tmp = this.state;
        tmp.newEntry.VehicleID = state.selectedVehicle;
        tmp.newEntry.Activity = state.selectedActivity;
        tmp.showList = false;
        tmp.showPopupTaskNew = true;
        this.setState(tmp);
    }
    closeNewServiceForm = () => {
        this.setState({
            showPopupNewService: false
        })
    }
    renderServiceList = () => {

        const _this = this;

        const openServiceView = (rowIn) => {
            _this.setState({
                showPopupTaskNew: false,
                showPopupTaskView: true,
                popupTaskViewData: rowIn,
                popupTaskViewCols: [],
                popupTaskViewDisplay: "single",
                popupTaskViewTitle: "Service [" + rowIn.name + "]"
            })
        }
        const openLinkPopup = (field, data) => {
            const deleteKey = ["org", "vehicleid", "activity", "service", "name"];
            const deleteTable = "MaintenanceTask";
            let cols = [
                {field: "name", label: "Name"},
                {field: "type", label: "Type"},
                {field: "costparts", label: "Cost Parts"},
                {field: "costlabour", label: "Cost Labour"},
            ];

            _this.setState({
                showPopupTaskNew: false,
                showPopupTaskView: true,
                popupTaskViewData: data,
                popupTaskViewCols: cols,
                popupTaskViewDisplay: "list",
                popupTaskViewTitle: "Task List of  Veh:[" + this.state.selectedVehicle + "] Activity:[" + this.state.selectedActivity + "]",
                popupTaskDeleteKey: deleteKey,
                popupTaskDeleteTable: deleteTable,
            })
        }

        const getNumberOfTask = (rowIn) => {
            let dataIn = [];
            for (let i in _this.state.Tasks) {
                let row = _this.state.Tasks[i];
                if (row.activity === rowIn.activity &&
                    row.vehicleid === rowIn.vehicleid &&
                    row.service === rowIn.name) {
                    dataIn.push(row);
                }
            }
            return dataIn;
        }

        let dataIn = [];
        for (let i in this.state.Services) {
            const row = this.state.Services[i];
            if (row.activity === this.state.selectedActivity &&
                row.vehicle === this.state.selectedVehicle) {
                let tasks = getNumberOfTask(row);
                row.numbertask = tasks.length;
                row.tasks = tasks;
                dataIn.push(row);
            }
        }
        const keyList = [
            {field: "vehicle", label: "Vehicle", link: false, linkData: null},
            {field: "activity", label: "Activity", link: false, linkData: null},
            {field: "service", label: "Service", link: false, linkData: null},
            {field: "recurrencecategory", label: "Recurrence", link: false, linkData: null},
            {field: "mileagerecurrencenextdue", label: "MileageNextDue", link: false, linkData: null},
            {field: "timerecurrencenextdue", label: "TimeNextDue", link: false, linkData: null},
            {field: "mileagerecurrencerepeating", label: "RepeatM", link: false, linkData: null},
            {field: "timerecurrencerepeating", label: "RepeatT", link: false, linkData: null},
            {field: "orgdatetime", label: "Updated", link: false, linkData: null},
        ];
        const deleteKey = ["org", "service", "name"];
        const deleteTable = "MaintenanceTask";
        //todo -----------------------------------------
        let ls = dataIn;
        if (ls.length === 0) {
            return (
                <div className={"alert alert-danger"}>
                    Service list is Empty ! click
                    <a
                        onClick={() => this.openNewServiceForm()}
                        className={""}
                        style={{cursor: "pointer", color: "blue"}}
                    >
                        &nbsp; here to add new service
                    </a>
                </div>
            )
        }

        return (
            <div className={"container"}>
                <div className={"alert alert-danger"}>
                    Available Service list, click
                    <a
                        onClick={() => this.openNewServiceForm()}
                        className={""}
                        style={{cursor: "pointer", color: "blue"}}
                    >
                        &nbsp; here to add new service
                    </a>
                </div>
                <table
                    className={"table"}
                    style={{
                        border: '3px solid gray',
                        borderRightColor: 'gray',
                    }}
                >
                    <thead>

                    <tr>
                        {keyList.map((item) => {
                            return (
                                <td>{item.label}</td>
                            )
                        })}
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ls.map((row, index) => {

                        return (
                            <tr key={"xkdkd" + index}>
                                {keyList.map((item) => {


                                    if (item.link) {
                                        let data = row[item.linkData];
                                        return (
                                            <td>

                                                <a className={"badge"} onClick={() => openLinkPopup(item.field, data)}>

                                                    <Badge variant="primary" style={{fontSize: 18}}>
                                                        {row[item.field]}
                                                    </Badge>
                                                </a>
                                            </td>
                                        )
                                    }


                                    return (
                                        <td>{row[item.field]}</td>
                                    )
                                })}
                                <th style={{width: 150, textAlign: "right"}}>

                                    <a
                                        onClick={(e) => removeEntry(e, row, ["org","vehicle","activity","name"], "MaintenancePlaning", this)}
                                        className={""}
                                        style={{color: "blue", fontSize: 16, fontWeight: "bold"}}
                                    >
                                        <span className={"fa fa-trash"}></span>
                                    </a>
                                    &nbsp;|&nbsp;
                                    <a
                                        onClick={(e) => openServiceView(row)}
                                        className={""}
                                        style={{color: "blue", fontSize: 16, fontWeight: "bold"}}
                                    >
                                        <span className={"fa fa-list"}></span>
                                    </a>



                                </th>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )


        //return renderListInnerData(this, dataIn,"Data", keyList, deleteKey, deleteTable, "showList", removeEntry)
    }
    renderNewServiceForm = () => {
        if (!this.state.showPopupNewService) {
            return null;
        }

        const _this = this;

        const cqlTable = "MaintenanceService";
        const options = ["yes", "no"];
        const categories = ["fixed mileage", "recurring mileage", "time period"];
        const vats = ["include", "exclude"];

        let ls = [
            {
                field: "Category",
                label: "Service Frequency",
                options: categories,
                type: "option",
                required: true,
                dataType: "string"
            },
            {
                field: "Name",
                label: "Name of Service",
                options: options,
                type: "text",
                required: true,
                dataType: "string"
            },
            {
                field: "Manager",
                label: "Assign To",
                options: options,
                type: "text",
                required: true,
                dataType: "string"
            },
            {
                field: "DueMileage",
                label: "Due Mileage",
                options: options,
                type: "text",
                required: true,
                dataType: "float"
            },
            {
                field: "LastDueMileage",
                label: "Last Due Mileage",
                options: options,
                type: "text",
                required: true,
                dataType: "float"
            },
            {
                field: "NotifyMileage",
                label: "Notification Mileage",
                options: options,
                type: "text",
                required: true,
                dataType: "float"
            },
        ]

        const actions = [
            <FlatButton
                label="Close"
                primary={true}
                onClick={this.closeNewServiceForm}
            />,
        ];

        return (
            <Dialog
                title={"View Task Detail"}
                actions={actions}
                modal={false}
                open={this.state.showPopupNewService}
                onRequestClose={this.closeNewServiceForm}
                autoScrollBodyContent={true}
            >

                <br/>

                <h3>
                    &nbsp;
                    <a
                        className={"btn btn-danger pull-right"}
                        onClick={() => this.closeNewServiceForm()}
                    >Cancel</a>
                </h3>
                <br/>
                <div className={"container"}>
                    <p className={"alert alert-success"}>New Record</p>
                    <form onSubmit={(e) => submitEntry(e, cqlTable, ls, _this)}>
                        {ls.map((row, index) => {
                            if (row.type === "option") {
                                return (
                                    <div className="row" key={"zty" + index}>
                                        <div className="form-group col-md-12 col-lg-12">
                                            <label htmlFor="inputState">{row.label}</label>
                                            <select
                                                name={row.field}
                                                className="form-control"
                                                onChange={(e) => setValueBox(e, _this, row.dataType)}
                                                value={getValue(row.field, _this)}
                                                required={row.required}
                                            >
                                                <option value="" selected>Choose...</option>
                                                {row.options.map((item, index2) => {
                                                    let key = item;
                                                    let desc = item.toLocaleUpperCase();
                                                    return (
                                                        <option key={index2} value={key}>{desc}</option>
                                                    )
                                                })}

                                            </select>
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <div className="row" key={"zty" + index}>
                                    <div className="form-group col-md-12  col-lg-12">
                                        <label htmlFor="inputState">{row.label}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => setValue(e, row.field, _this, row.dataType)}
                                            value={getValue(row.field, _this)}
                                            required={true}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                        <button
                            type={"submit"}
                            className={"btn btn-default pull-right "}
                            style={{backgroundColor: " #00b3ee"}}
                        > Submit
                        </button>
                    </form>
                </div>


            </Dialog>
        )


    }
    renderForm = () => {
        if (this.state.showList) {
            return null;
        }

        const options = ["yes", "no"];
        const categories = ["fixed mileage", "recurring mileage", "time period"];
        const vats = ["include", "exclude"];
        let ls = [
            {field: "NextDueMileage", label: "Mileage Due", options: options, type: "text", required: true},
            {field: "Category", label: "Service Frequency", options: categories, type: "option", required: true},
            {field: "Manager", label: "Manager of this task", options: options, type: "text", required: true},
            {field: "Name", label: "Name of Task", options: options, type: "text", required: true},
            {field: "CostParts", label: "Cost Parts", options: options, type: "text", required: true},
            {field: "CostPartsDiscount", label: "Cost Parts Discount", options: options, type: "text", required: true},
            {field: "CostPartsVat", label: "Cost Parts Vat", options: vats, type: "option", required: true},
            {field: "CostLabour", label: "Cost Labour", options: options, type: "text", required: true},
            {
                field: "CostLabourDiscount",
                label: "Cost Labour Discount",
                options: options,
                type: "text",
                required: true
            },
            {field: "CostLabourVat", label: "Cost Labour Vat", options: vats, type: "option", required: true},
            {field: "LastDueMileage", label: "Last Due Mileage", options: options, type: "text", required: false},
            {field: "LastDueDate", label: "Last Due Date", options: options, type: "text", required: false},
        ]

        return (
            <div>
                <h3>
                    &nbsp;
                    <a
                        className={"btn btn-danger pull-right"}
                        onClick={() => this.closeNewTask()}
                    >Cancel</a>
                </h3>
                <br/>
                {renderFormWithSubmit(ls, "showList", this.state.cqlTable, this, this.submitTask)}
            </div>
        )

    }
    renderFilterBoxVehicle = () => {
        let ls = [];
        for (let i in this.state.Vehicles) {
            const row = this.state.Vehicles[i];
            ls.push(row.ref);
        }
        return (
            <div className="form-group col-md-12 col-lg-12">
                <label htmlFor="inputState">Vehicles</label>
                <select
                    name={"selectedVehicle"}
                    className="form-control"
                    onChange={(e) => this.setValueBox(e)}
                    value={this.getValue("selectedVehicle")}
                    required={true}
                >
                    <option value="" selected>Choose...[{ls.length}]</option>
                    {ls.map((item, index2) => {
                        let key = item;
                        let desc = item.toLocaleUpperCase();
                        return (
                            <option key={index2} value={key}>{desc}</option>
                        )
                    })}

                </select>
            </div>
        )
    }
    renderFilterBoxActivity = () => {
        let ls = [];
        for (let i in this.state.Activities) {
            const row = this.state.Activities[i];
            ls.push(row.name);
        }
        let showActivity = false;
        if (this.state.selectedVehicle === "") {
            showActivity = true;
        }
        return (
            <div className="form-group col-md-12 col-lg-12">
                <label htmlFor="inputState">Activities</label>
                <select
                    name={"selectedActivity"}
                    className="form-control"
                    onChange={(e) => this.setValueBox(e, this)}
                    value={this.getValue("selectedActivity", this)}
                    required={true}
                    disabled={showActivity}
                >
                    <option value="" selected>Choose...[{ls.length}]</option>
                    {ls.map((item, index2) => {
                        let key = item;
                        let desc = item.toLocaleUpperCase();
                        return (
                            <option key={index2} value={key}>{desc}</option>
                        )
                    })}

                </select>
            </div>
        )
    }
    renderFilterBoxService = () => {
        let ls = [];
        //console.log(":)-->Service list: ", this.state.Services, " > ", this.state);
        for (let i in this.state.Services) {
            const row = this.state.Services[i];
            if (row.activity === this.state.selectedActivity) {
                ls.push(row.name);
            }
        }
        let showActivity = false;
        if (this.state.selectedVehicle === "" || this.state.selectedActivity === "") {
            showActivity = true;
        }
        return (
            <div className="form-group col-md-12 col-lg-12">
                <label htmlFor="inputState">Type of Service</label>
                <select
                    name={"selectedService"}
                    className="form-control"
                    onChange={(e) => this.setValueBox(e, this)}
                    value={this.getValue("selectedService", this)}
                    required={true}
                    disabled={showActivity}
                >
                    <option value="" selected>Choose...[{ls.length}]</option>
                    {ls.map((item, index2) => {
                        let key = item;
                        let desc = item.toLocaleUpperCase();
                        return (
                            <option key={index2} value={key}>{desc}</option>
                        )
                    })}

                </select>
            </div>
        )
    }
    renderTaskList = () => {

        if (!this.state.showList) {
            return null
        }

        let ls = [];
        for (let i in this.state.Data) {
            const row = this.state.Data[i];
            if (row.activity === this.state.selectedActivity &&
                row.service === this.state.selectedService &&
                row.vehicleid === this.state.selectedVehicle) {
                ls.push(row)
            }
        }

        if (ls.length === 0) {
            return (
                <h3>
                    <small>
                        Empty Task list! you dont have task setup for this filter
                    </small>
                    <a
                        className={"btn btn-primary pull-right"}
                        onClick={(e) => this.openNewTask()}
                    >New Task</a>
                </h3>
            )
        }

        const keyList = [
            {field: "vehicleid", label: "Vehicle"},
            {field: "activity", label: "Activity"},
            {field: "service", label: "Service"},
            {field: "name", label: "Name"},
            {field: "category", label: "Category"},
            {field: "costparts", label: "Cost Parts"},
            {field: "lastduemileage", label: "Last Mileage"},
            {field: "lastduedate", label: "Last DueDate"},
            {field: "orgdatetime", label: "Last Updated"}
        ];
        const deleteKey = ["org", "vehicleid", "activity", "service", "name"];
        const deleteTable = "MaintenanceTask";
        const sortKey = "position";
        return renderList(this, "Data", keyList, deleteKey, deleteTable, "showList", removeEntry, sortKey, this.openNewTask)

    }
    renderShowTaskDetail() {

        const actions = [
            <FlatButton
                label="Close"
                primary={true}
                onClick={this.closeShowPopup}
            />,
        ];

        let ls = [];
        const avoidList = (keyIn) => {
            let boo = false;
            const ls = ["date", "id", "org", "status", "profile", "orgdatetime", "type", "time"];
            for (let i in ls) {
                const item = ls[i];
                if (item === keyIn) {
                    return true;
                }
            }
        }
        for (let i in this.state.currentView) {
            const val = this.state.currentView[i];
            if (!avoidList(i)) {
                ls.push({
                    key: i,
                    val: val
                })
            }

        }
        return (
            <Dialog
                title={"View Task Detail"}
                actions={actions}
                modal={false}
                open={this.state.showPopup}
                onRequestClose={this.closeShowPopup}
                autoScrollBodyContent={true}
            >

                <br/>

                <div className="col-lg-12">
                    <table className={"table"}>
                        <thead>

                        </thead>
                        <tbody>
                        {ls.map((row, index) => {
                            return (
                                <tr>
                                    <th>{row.key}:</th>
                                    <td>{row.val}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>


            </Dialog>
        )

    }

    renderDashboard = () => {
        const DashBox = ({size, color, icon, boxNumber, boxName,subtitle}) => {
            // https://react-bootstrap.netlify.com/components/cards/
            const boxSize = "col-lg-" + size + " col-md-" + (size - 1);
            return (
                <div className={boxSize}>
                    <Card bg="dark" text="white" style={{width: '18rem'}}>
                        <Card.Header>{boxName}</Card.Header>
                        <Card.Body>
                            <Card.Title style={{textAlign: "center",fontSize:55}}>{boxNumber}</Card.Title>
                            <Card.Text types={{textAlign:"center"}}>{subtitle}</Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            )

        }

        let totalVehicle = this.state.Vehicles.length;
        let totalPlanned = 0;
        let totalUnplanned = 0;

        for (let i in this.state.Vehicles) {
            const vehicle = this.state.Vehicles[i].ref;
            let boo = false;
            for (let h in this.state.Services) {
                const row = this.state.Services[h];
                if (vehicle === row.vehicle) {
                    boo = true
                }
            }

            if (boo) {
                totalPlanned++
            }
        }

        totalUnplanned = totalVehicle - totalPlanned;

        let DashboardVehicle = {
            size: 4,
            color: "success",
            icon: "glyphicon glyphicon-headphones",
            boxNumber: totalVehicle,
            boxName: "Fleet Total",
            subtitle:"Vehicle(s)"
        }
        let DashboardPlanned = {
            size: 4,
            color: "warning",
            icon: "glyphicon glyphicon-volume-up",
            boxNumber: totalPlanned,
            boxName: "Jobs Assigned",
            subtitle:"Vehicle(s)"
        }
        let DashboardUnPlanned = {
            size: 4,
            color: "danger",
            icon: "glyphicon glyphicon-ban-circle",
            boxNumber: totalUnplanned,
            boxName: "No Job Assigned",
            subtitle:"Vehicle(s)"
        }

        return (
            <div className="row container">

                {DashBox(DashboardVehicle)}

                {DashBox(DashboardPlanned)}

                {DashBox(DashboardUnPlanned)}

            </div>
        )
    }

    render() {

        let _this = this;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row container">
                        <div className="col col-xs-12">
                            <h3>
                                Assign Job to Vehicle
                            </h3>
                        </div>
                    </div>

                    {this.renderDashboard()}

                    <div className="row container">
                        <div className="col col-xs-12 col-lg-12">
                            <hr/>
                        </div>
                    </div>

                    <div className="row container">
                        <div className="col col-xs-12 col-lg-6 ">
                            {this.renderFilterBoxVehicle()}
                        </div>
                        <div className="col col-xs-12 col-lg-6 ">
                            {this.renderFilterBoxActivity()}
                        </div>
                        {/*<div className="col col-xs-12 col-lg-4">
                            {this.renderFilterBoxService()}
                        </div>*/}
                    </div>

                    <div className="row container">
                        <div className="col col-xs-12 col-lg-12">
                            <hr/>
                        </div>
                    </div>

                    <div className="row container">
                        <div className="col col-xs-12 col-lg-12">
                            {this.renderServiceList()}
                        </div>

                    </div>

                    <div className="row container">
                        <div className="col col-xs-12 col-lg-12">
                            <MuiThemeProvider>
                                {this.renderNewServiceForm()}
                            </MuiThemeProvider>


                            {this.state.showPopupTaskView && (
                                <MyPopupDisplay
                                    popTitle={this.state.popupTaskViewTitle}
                                    popData={this.state.popupTaskViewData}
                                    propCols={this.state.popupTaskViewCols}
                                    popStatus={this.state.showPopupTaskView}
                                    popFnClose={this.closeTaskView}
                                    popDisplay={this.state.popupTaskViewDisplay}
                                    popupDeleteKey={this.state.popupTaskDeleteKey}
                                    popupDeleteTable={this.state.popupTaskDeleteTable}
                                    _this={this}
                                />
                            )}



                            <MyPopupManageService
                                _this={this}
                                popStatus={this.state.showPopupTaskNew}
                                popFnClose={this.closeTaskNew}
                                vehicles={this.state.Vehicles}
                                activities={this.state.Activities}
                            />



                        </div>
                    </div>


                </div>
            </PageContentWallpaper>
        )
    }

}
