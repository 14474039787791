import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {
    fetchUtilData,
    renderList,
    renderFormWithSubmit, removeEntry
} from "./api";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";
import 'bootstrap/dist/css/bootstrap.css';
/*import url("//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css");*/
import {Card, ListGroup, ListGroupItem, Nav} from 'react-bootstrap';
import CancelIcon from '../../asset/img/cancel-24px.svg';

export default class ComUtilMaintenanceActivityType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Activities: [],
            Services: [],
            Tasks: [],
            Vehicles: [],
            Suppliers: [],
            newEntry: {},
            selectedVehicle: "",
            selectedActivity: "",
            selectedService: "",
            selectedSupplier: "",
            showList: true,
            cqlTable: "OperationPlanned",
            currentView: null,
            showPopup: false,
            dashActivityService: "activity",
            invoiceItems: [],
            invoiceVat: 0,
            invoiceSubtotal: 0,
            invoiceTax: 0,
            invoiceDue: 0,

            invoiceSupplyCompany: "Easipath Solutions",
            invoiceSupplyAddressLine1: "01 Kensington Road",
            invoiceSupplyAddressLine2: "Milnerton",//suburb
            invoiceSupplyAddressLine3: "Cape town",//city
            invoiceSupplyAddressLine4: "Western Cape",//province
            invoiceSupplyAddressLine5: "South Africa",//Country

            invoiceSupplyNumber: 123456,
            invoiceSupplyContactPhone: "+27 72 913 9504",
            invoiceNumber: "INV0051",
            invoiceDate: new Date().toDateString(),

            isCapturing: false,


        }
    }

    async componentDidMount() {
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await fetchUtilData(this, this.state.cqlTable, "Data");
        await fetchUtilData(this, "UtilActivityList", "Activities");
        await fetchUtilData(this, "UtilServiceList", "Services");
        await fetchUtilData(this, "MaintenanceTask", "Tasks");
        await fetchUtilData(this, "Vehicle", "Vehicles");
        await fetchUtilData(this, "Supplier", "Suppliers");
    }

    setValueBox = (e) => {
        const val = e.target.value;
        const key = e.target.name;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    getValue = (key) => {
        return this.state[key]
    }

    closeShowPopup = () => {
        this.setState({
            currentView: null,
            showPopup: false
        })
    }

    renderFilterBoxSupplier = () => {
        let ls = [];
        for (let i in this.state.Suppliers) {
            const row = this.state.Suppliers[i];
            ls.push(row.ref);
        }
        return (
            <div className="form-group col-md-12 col-lg-12">
                <label htmlFor="inputState">Supplier</label>
                <select
                    name={"selectedSupplier"}
                    className="form-control"
                    onChange={(e) => this.setValueBox(e)}
                    value={this.getValue("selectedSupplier")}
                    required={true}
                >
                    <option value="" selected>Choose...[{ls.length}]</option>
                    {ls.map((item, index2) => {
                        let key = item;
                        let desc = item.toLocaleUpperCase();
                        return (
                            <option key={index2} value={key}>{desc}</option>
                        )
                    })}

                </select>
            </div>
        )
    }
    renderFilterBoxVehicle = () => {
        let ls = [];
        for (let i in this.state.Vehicles) {
            const row = this.state.Vehicles[i];
            ls.push(row.ref);
        }
        return (
            <div className="form-group col-md-12 col-lg-12">
                <label htmlFor="inputState">Vehicles</label>
                <select
                    name={"selectedVehicle"}
                    className="form-control"
                    onChange={(e) => this.setValueBox(e)}
                    value={this.getValue("selectedVehicle")}
                    required={true}
                >
                    <option value="" selected>Choose...[{ls.length}]</option>
                    {ls.map((item, index2) => {
                        let key = item;
                        let desc = item.toLocaleUpperCase();
                        return (
                            <option key={index2} value={key}>{desc}</option>
                        )
                    })}

                </select>
            </div>
        )
    }
    renderFilterBoxActivity = () => {
        let ls = [];
        for (let i in this.state.Activities) {
            const row = this.state.Activities[i];
            ls.push(row.name);
        }
        let showActivity = false;
        if (this.state.selectedVehicle === "") {
            showActivity = true;
        }
        return (
            <div className="form-group col-md-12 col-lg-12">
                <label htmlFor="inputState">Activities</label>
                <select
                    name={"selectedActivity"}
                    className="form-control"
                    onChange={(e) => this.setValueBox(e, this)}
                    value={this.getValue("selectedActivity", this)}
                    required={true}
                    disabled={showActivity}
                >
                    <option value="" selected>Choose...[{ls.length}]</option>
                    {ls.map((item, index2) => {
                        let key = item;
                        let desc = item.toLocaleUpperCase();
                        return (
                            <option key={index2} value={key}>{desc}</option>
                        )
                    })}

                </select>
            </div>
        )
    }
    renderFilterBoxService = () => {
        let ls = [];
        //console.log(":)-->Service list: ", this.state.Services, " > ", this.state);
        for (let i in this.state.Services) {
            const row = this.state.Services[i];
            if (row.activity === this.state.selectedActivity) {
                ls.push(row.name);
            }
        }
        let showActivity = false;
        if (this.state.selectedVehicle === "" || this.state.selectedActivity === "") {
            showActivity = true;
        }
        return (
            <div className="form-group col-md-12 col-lg-12">
                <label htmlFor="inputState">Services</label>
                <select
                    name={"selectedService"}
                    className="form-control"
                    onChange={(e) => this.setValueBox(e, this)}
                    value={this.getValue("selectedService", this)}
                    required={true}
                    disabled={showActivity}
                >
                    <option value="" selected>Choose...[{ls.length}]</option>
                    {ls.map((item, index2) => {
                        let key = item;
                        let desc = item.toLocaleUpperCase();
                        return (
                            <option key={index2} value={key}>{desc}</option>
                        )
                    })}

                </select>
            </div>
        )
    }
    renderTaskList = () => {

        if (!this.state.showList) {
            return null
        }

        let ls = [];
        for (let i in this.state.Data) {
            const row = this.state.Data[i];
            if (row.activity === this.state.selectedActivity &&
                row.service === this.state.selectedService &&
                row.vehicleid === this.state.selectedVehicle) {
                ls.push(row)
            }
        }

        if (ls.length === 0) {
            return (
                <h3>
                    <small>
                        Empty Task list! you dont have task setup for this filter
                    </small>
                    <a
                        className={"btn btn-primary pull-right"}
                        onClick={(e) => this.openNewTask()}
                    >New Task</a>
                </h3>
            )
        }

        const keyList = [
            {field: "name", label: "Name"},
            {field: "category", label: "Category"},
            {field: "costparts", label: "Cost Parts"},
            {field: "lastduemileage", label: "Last Mileage"},
            {field: "lastduedate", label: "Last DueDate"},
            {field: "orgdatetime", label: "Last Updated"}
        ];
        const deleteKey = ["org", "vehicleid", "activity", "service", "name"];
        const deleteTable = "MaintenanceTask";
        const sortKey = "position";
        return renderList(this, "Data", keyList, deleteKey, deleteTable, "showList", removeEntry, sortKey, this.openNewTask)

    }
    renderInvoiceForm = () => {

        const innerStyle = {
            companyBoxFrame: {
                borderWidth: 1,
                borderColor: "#dee2e6",
                background: "#e9ecef",
                color: "black",
                borderRadius: 4,
            },
            rightBoxTopLeft: {
                textAlign: "left",
                fontWeight: "bold"
            },
            rightBoxTopRight: {
                textAlign: "right",
            }
        };
        let _this = this;
        const taskList = [
            "Oil change",
            "Brakes",
            "Front while check",
        ];

        const addItem = (category) => {
            const item = {
                category: category,
                name: "",
                description: "",
                qty: 1,
                price: 0,
                total: 0
            };
            let tmp = _this.state;
            tmp.invoiceItems.push(item)
            _this.setState(tmp);
        }
        const removeItem = (index) => {
            let tmp = _this.state;
            let invoiceItems = [];
            for (let i in tmp.invoiceItems) {
                const row = tmp.invoiceItems[i];
                if (parseInt(i) !== parseInt(index)) {
                    invoiceItems.push(row)
                }
            }
            tmp.invoiceItems = invoiceItems;
            tmp = clcTotals(tmp);
            _this.setState(tmp);
        }
        const getItemValue = (index, key) => {
            let tmp = _this.state;
            let qty = "";
            if (key === "qty" || key === "price") {
                qty = 0
            }
            for (let i in tmp.invoiceItems) {
                const row = tmp.invoiceItems[i];
                if (parseInt(i) === parseInt(index)) {
                    //console.log("getItemValue ):(--> ",key," > ",row);
                    qty = row[key];
                }
            }
            return qty;
        }
        const setItemValue = (e, index) => {
            e.preventDefault();
            const key = e.target.name;
            const val = e.target.value;

            let tmp = _this.state;
            let invoiceItems = [];
            for (let i in tmp.invoiceItems) {
                const row = tmp.invoiceItems[i];
                if (parseInt(i) === parseInt(index)) {
                    row[key] = val;
                    invoiceItems.push(row)
                } else {
                    invoiceItems.push(row)
                }
            }
            tmp = clcTotals(tmp);
            _this.setState(tmp);
        }
        const setInnerFloatValue = (e) => {
            e.preventDefault();
            const key = e.target.name;
            const val = e.target.value;
            const myValue = parseFloat(val);

            let vat = 0;
            if (!isNaN(myValue)) {
                vat = parseInt(myValue);
            }
            let tmp = _this.state;
            tmp[key] = vat;
            tmp = clcTotals(tmp);
            _this.setState(tmp);
        }
        const setInnerStringValue = (e) => {
            e.preventDefault();
            const key = e.target.name;
            const val = e.target.value;
            let tmp = _this.state;
            tmp[key] = val;
            tmp = clcTotals(tmp);
            _this.setState(tmp);
        }
        const clcTotals = (tmp) => {

            //todo let calculate subtotal and tax
            let totalTax = 0;
            let subtotal = 0;
            let totalCost = 0;

            for (let i in tmp.invoiceItems) {
                const row = tmp.invoiceItems[i];
                let qty = parseFloat(row.qty);
                let price = parseFloat(row.price);
                if (isNaN(qty)) {
                    qty = 0
                }
                if (isNaN(price)) {
                    price = 0
                }
                //const cost  = qty * price;
                subtotal = subtotal + (qty * price);
            }


            const vat = parseFloat(tmp.invoiceVat);


            if (vat > 0) {
                totalTax = (subtotal / 100) * vat;
                totalCost = subtotal - totalTax;
            } else {

            }

            tmp.invoiceVat = vat;
            tmp.invoiceSubtotal = subtotal.toFixed(2);
            tmp.invoiceDue = totalCost.toFixed(0);
            ;
            tmp.invoiceTax = totalTax.toFixed(2);
            tmp.invoiceDue = tmp.invoiceSubtotal - tmp.invoiceTax;
            return tmp;
        }

        let ls = [];

        return (
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col col-lg-4"} style={innerStyle.companyBoxFrame}>
                        <h4>{this.state.invoiceSupplyCompany}</h4>
                        <p>{this.state.invoiceSupplyAddressLine1}</p>
                        <p>
                            {this.state.invoiceSupplyAddressLine2},
                            &nbsp;{this.state.invoiceSupplyAddressLine3},
                            &nbsp;{this.state.invoiceSupplyAddressLine4}
                        </p>
                        <p>{this.state.invoiceSupplyContactPhone}</p>
                    </div>
                    <div className={"col col-lg-4"}>

                    </div>
                    <div className={"col col-lg-4"}>
                        <div className="card">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Oder #</span>
                                    <span className={"pull-right"} style={innerStyle.rightBoxTopRight}>
                                        {this.state.invoiceSupplyNumber}
                                    </span>
                                </li>
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Invoice #</span>
                                    <input

                                        size={5}
                                        name={"invoiceNumber"}
                                        className={"pull-right"}
                                        style={innerStyle.rightBoxTopRight}
                                        value={this.state.invoiceNumber}
                                        onChange={(e)=>setInnerStringValue(e)}
                                    />


                                </li>
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Job #</span>
                                    <input

                                        size={5}
                                        name={"invoiceNumber"}
                                        className={"pull-right"}
                                        style={innerStyle.rightBoxTopRight}
                                        value={this.state.invoiceNumber}
                                        onChange={(e)=>setInnerStringValue(e)}
                                    />


                                </li>
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Date</span>
                                    <span className={"pull-right"} style={innerStyle.rightBoxTopRight}>
                                        {this.state.invoiceDate}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col col-lg-12"}>
                        <br/>
                        <h2>Invoice Details</h2>
                        <br/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col col-lg-12"}>
                        <table className={"table table-bordered"}>
                            <thead class="thead-light">
                            <th scope="col">#</th>
                            <th scope="col">Item</th>
                            <th scope="col">Description</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Price</th>
                            <th scope="col">Total</th>
                            <th scope="col"></th>
                            </thead>
                            <tbody>
                            {this.state.invoiceItems.map((row, index) => {

                                let total = parseInt(row.qty) * parseFloat(row.price);
                                if (isNaN(total)) {
                                    total = 0
                                }
                                //total = parseFloat(total);
                                return (
                                    <tr key={"ttr" + index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {row.category === "task" ?
                                                <select
                                                    name={"item"}
                                                    defaultValue={getItemValue(index, "item")}
                                                    onChange={(e) => setItemValue(e, index)}
                                                >
                                                    <option value={""}>--Select Task--</option>
                                                    {taskList.map((item, aIndex) => {
                                                        return (
                                                            <option key={"opp" + aIndex} value={item}>{item}</option>
                                                        )
                                                    })}
                                                </select> :
                                                <input
                                                />
                                            }
                                        </td>
                                        <td>
                                            <input
                                                name="description"
                                                value={getItemValue(index, "description")}
                                                onChange={(e) => setItemValue(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name={"qty"}
                                                value={getItemValue(index, "qty")}
                                                onChange={(e) => setItemValue(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                name={"price"}
                                                value={getItemValue(index, "price")}
                                                onChange={(e) => setItemValue(e, index)}
                                            />
                                        </td>
                                        <th>
                                            R {total}
                                        </th>
                                        <td>
                                            <a onClick={() => removeItem(index)}>
                                                <img src={CancelIcon} width={30} style={{color: "red"}}/>
                                            </a>
                                        </td>

                                    </tr>
                                )
                            })}
                            </tbody>
                            <tfoot>
                            <tr>
                                <th colSpan={7}>
                                    <button
                                        type="button"
                                        className="btn btn-outline-success pull-right"
                                        onClick={() => addItem("task")}
                                    >
                                        Add Task Item
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary pull-right"
                                        onClick={() => addItem("new")}
                                    >
                                        Add New Item
                                    </button>
                                </th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col col-lg-4"}>
                        <div className="card">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Tax Rate (%)</span>
                                    <input
                                        name={"invoiceVat"}
                                        className={"pull-right"}
                                        style={{width: 50}}
                                        value={this.state.invoiceVat}
                                        onChange={(e) => setInnerFloatValue(e)}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={"col col-lg-4"}>

                    </div>
                    <div className={"col col-lg-4"}>
                        <div className="card">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Subtotal</span>
                                    <span className={"pull-right"}
                                          style={innerStyle.rightBoxTopRight}>R {this.state.invoiceSubtotal}</span>
                                </li>
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Tax</span>
                                    <span className={"pull-right"}
                                          style={innerStyle.rightBoxTopRight}>R {this.state.invoiceTax}</span>
                                </li>
                                <li className="list-group-item">
                                    <span className={"pull-left"} style={innerStyle.rightBoxTopLeft}>Total Due</span>
                                    <span className={"pull-right"}
                                          style={innerStyle.rightBoxTopRight}>R {this.state.invoiceDue}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row ">
                        <div className="col col-xs-12">
                            <h3>
                                Invoice to be Capture
                            </h3>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col col-xs-12 col-lg-12">
                            <hr/>
                        </div>
                    </div>
                    {!this.state.isCapturing ?
                        <div>
                            <div className="row container alert alert-info">
                                <div className="col col-xs-12 col-lg-4">
                                    {this.renderFilterBoxSupplier()}
                                </div>
                                <div className="col col-xs-12 col-lg-4 ">
                                    {this.renderFilterBoxVehicle()}
                                </div>
                                <div className="col col-xs-12 col-lg-4 ">
                                    {this.renderFilterBoxActivity()}
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col col-xs-12 col-lg-12">
                                    <hr/>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col col-xs-12 col-lg-9">
                                    <select className={"form-control"}>
                                        <option>--Select Maintenance Job Number--</option>
                                        <option>Planned Service | Service A | JOB250587 | 2019-11-02</option>
                                    </select>
                                </div>
                                <div className="col col-xs-12 col-lg-23">
                                    <button
                                        className={"btn btn-primary pull-right"}
                                        onClick={()=>this.setState({isCapturing:true})}
                                    >Capture Invoice
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row ">
                            <div className="col col-xs-12 col-lg-7">
                                &nbsp;
                            </div>
                            <div className="col col-xs-12 col-lg-5">
                                <button
                                    className={"btn btn-danger pull-right"}
                                    onClick={()=>this.setState({isCapturing:false})}
                                >Cancel Capturing Invoice
                                </button>
                            </div>
                        </div>
                    }


                    <div className="row ">
                        <div className="col col-xs-12 col-lg-12">
                            <hr/>
                        </div>
                    </div>

                    {this.state.isCapturing ?
                        <div className="row ">
                            <div className="col col-xs-12 col-lg-12">
                                <Card border="primary" style={{height: '100%'}}>
                                    <Card.Header>
                                        <Card.Title style={{color: "black"}}>
                                            Invoice
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                {this.renderInvoiceForm()}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div> : null
                    }

                </div>
            </PageContentWallpaper>
        )
    }

}