import React from "react";
import FlatButton from "material-ui/FlatButton";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Dialog from "@material-ui/core/Dialog";
import {
    GetModelListOption,
} from "../generic/find-data";
import {
    BoxSelectOption,
    BoxTextareaVertical,
    BoxInputVertical
} from "../generic/input-control-function";
import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";


export default class PopupPartsInventoryNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Profile: {},
            currKey: "",
            currVal: "",
        }
    }

    handleInput = (e) => {
        const key = e.target.name;
        const dataType = this.getFieldDataType(key).type;
        let val = e.target.value;
        if (dataType === "number") {
            val = parseFloat(val)
        }
        if (dataType === "checkbox") {
            let storeVal = false;
            if (typeof this.state[key] !== "undefined") {
                storeVal = this.state[key]
            }
            val = !storeVal;
            console.log("checkbox storeVal --> ", storeVal, " > ", this.state[key])
        }
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    handleState = async (e) => {
        let tmp = this.state;
        let key = e.target.name;
        tmp[key] = e.target.value;
        this.setState(tmp)
    }
    addTask = (key, keyList) => {
        let tmp = this.state;
        if (tmp.currVal !== "" || tmp.currKey !== "") {
            tmp.Profile[tmp.currKey] = tmp.currVal;
            tmp.currKey = "";
            tmp.currVal = "";
            this.setState(tmp)
        } else {
            alert("please provide info key and val")
        }
    }
    closeMe = () => {
        const title = this.props.popTitle;
        const statusKey = this.props.popStatus;
        let status = this.props.state[statusKey];
        this.props.popFnClose(statusKey);
    }
    removeListItem = (key) => {
        let tmp = this.state;
        delete tmp.Profile[key];
        this.setState(tmp)
    }


    getFieldDataType = (fieldName) => {
        const dataModel = this.getModelData();
        for (let sessionName in dataModel) {
            const rows = dataModel[sessionName];
            for (let e in rows) {
                const row = rows[e];
                if (row.name === fieldName) {
                    return row;
                }
            }
        }
        return {type: "text"}
    }
    buildDataFromModelState = () => {
        const state = this.state;
        let hub = {};
        const dataModel = this.getModelData();
        for (let sessionName in dataModel) {
            const rows = dataModel[sessionName];
            for (let e in rows) {
                const row = rows[e];
                const key = row.name;
                let val = "";
                if (typeof state[key] === "undefined") {
                    val = row.type === "number" ? parseFloat("0") : 0;
                } else {
                    val = row.type === "number" ? parseFloat(state[key]) : state[key];
                }
                hub[key] = val
            }
        }
        hub.Profile = state.Profile
        return hub;
    }
    getLocationList = () => {
        const _this = this.props._this;
        return GetModelListOption(_this, "fleet-location", "identity", "array")
    }
    getManufacturerList = () => {
        const _this = this.props._this;
        return GetModelListOption(_this, "manufacturer", "identity", "array")
    }
    getSupplierList = () => {
        const _this = this.props._this;
        return GetModelListOption(_this, "supplier", "names", "array")
    }
    getModelData = () => {
        const _this = this.props._this;
        return {
            "General": [
                {
                    label: "Part Number(Usually provided by the vendor or manufacturer)",
                    name: "PartNumber",
                    type: "text",
                    placeholder: "Part number",
                    required: true,
                    smallText: "",
                },
                {
                    label: "Barcode",
                    name: "Barcode",
                    type: "text",
                    placeholder: "Barcode",
                    required: true,
                    smallText: "",
                },
                {
                    label: "Description",
                    name: "Description",
                    type: "text",
                    placeholder: "Description",
                    required: true,
                    smallText: "",
                },
                {
                    label: "Vendor",
                    name: "Vendor",
                    type: "option",
                    placeholder: "Vendor",
                    required: true,
                    smallText: "",
                    options: this.getSupplierList(),
                },
                {
                    label: "Manufacturer",
                    name: "Manufacturer",
                    type: "option",
                    placeholder: "Manufacturer",
                    required: true,
                    smallText: "",
                    options: this.getManufacturerList(),
                },
                {
                    label: "Note",
                    name: "Note",
                    type: "textarea",
                    placeholder: "Note",
                    required: true,
                    smallText: "",
                },
            ],
            "Inventory": [
                {
                    label: "Location",
                    name: "Location",
                    type: "option",
                    placeholder: "Location",
                    required: true,
                    smallText: "The place where your part is physically located in your inventory",
                    options: this.getLocationList(),
                },
                {
                    label: "Low stock alert",
                    name: "LowStockAlert",
                    type: "checkbox",
                    placeholder: "Low stock alert",
                    required: false,
                    smallText: "",
                },
                {
                    label: "Qty on hand",
                    name: "QuantityHand",
                    type: "number",
                    placeholder: "Qty on hand",
                    required: true,
                    smallText: "The quantity you actually have in inventory",
                },
                {
                    label: "Threshold inventory quantity",
                    name: "QuantityThreshold",
                    type: "number",
                    placeholder: "Threshold inventory quantity",
                    required: true,
                    smallText: "The minimum amount of items you have to maintain in your inventory. " +
                        "a reminder is created when Qty falls below this threshold.",
                },
                {
                    label: "Min. Order",
                    name: "MinOrder",
                    type: "number",
                    placeholder: "Min. Order",
                    required: false,
                    smallText: "This is the minimum quantity to order when ordering this item",
                },
                {
                    label: "Cost/Unit",
                    name: "Cost",
                    type: "number",
                    placeholder: "Cost/Unit",
                    required: false,
                    smallText: "The Item cost ",
                },

                {
                    label: "Unit",
                    name: "Unit",
                    type: "text",
                    placeholder: "Unit",
                    required: true,
                    smallText: "The measuring unit used for this item. " +
                        "E.g. pounds,kg, meter, etc.",
                },
            ]
        }
    }
    submitEntry = async (e) => {
        e.preventDefault();
        let hub = this.buildDataFromModelState();
        hub.Org = GetUserToken().OrgCode;
        hub.Username = GetUserToken().Username;
        console.log("LOGGER SUBMIT :> ", hub);

        const fnClose = this.closeMe;
        let _this = this;
        const parentProps = this.props._this;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/fleet/part/new";

        console.log("submitSchedule obj *** send > ", hub);
        console.log("submitSchedule *** send > ", hub);


        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitSchedule *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    if (dataIn.status !== "OK") {
                        parentProps.loadInitialData();
                        alert("Thank you, Part submitted");
                        fnClose()
                    }

                }
            }

        });

    }
    renderModelSection = (sessionName) => {
        const data = this.getModelData()[sessionName];
        if (typeof data === "undefined") {
            return null
        }

        return (
            <fieldset className="scheduler-border">
                <legend className="scheduler-border" style={{color: "red"}}>{sessionName}</legend>
                <div className={"row"}>
                    <div className={"col"}>
                        {data.map((row, index) => {
                            if (row.type === "option") {
                                //(label, name, required, options, handFunc, smallText)
                                return BoxSelectOption(
                                    row.label,
                                    row.name,
                                    row.required,
                                    row.options,
                                    this.handleInput,
                                    row.smallText
                                )
                            }
                            if (row.type === "textarea") {
                                //(label, name, type, placeholder, required, handFunc, smallText)
                                return BoxTextareaVertical(
                                    row.label,
                                    row.name,
                                    row.type,
                                    row.placeholder,
                                    row.required,
                                    this.handleInput,
                                    row.smallText
                                )
                            }


                            return (
                                BoxInputVertical(
                                    row.label,
                                    row.name,
                                    row.type,
                                    row.placeholder,
                                    row.required,
                                    this.handleInput,
                                    row.smallText
                                )
                            )
                        })}
                    </div>
                </div>
            </fieldset>
        )
    }

    renderAdditionalInfo = () => {

        let ls = [];
        for (let i in this.state.Profile) {
            const key = i;
            const val = this.state.Profile[i];
            ls.push({
                key: key,
                val: val
            })
        }

        return (
            <div>
                <fieldset className="scheduler-border">
                    <legend className="scheduler-border" style={{color: "red"}}>Additional Info</legend>
                    <div className={"row"}>
                        <div className={"col-lg-5"}>
                            <input type="text"
                                   className="form-control"
                                   placeholder="Type your Field name..."
                                   name={"currKey"}
                                   onChange={(e) => this.handleState(e)}
                                   value={this.state.currKey}
                            />
                        </div>
                        <div className={"col-lg-5"}>
                            <input type="text"
                                   className="form-control"
                                   placeholder="Type your Field name..."
                                   name={"currVal"}
                                   onChange={(e) => this.handleState(e)}
                                   value={this.state.currVal}
                            />
                        </div>
                        <div className={"col-lg-1"}>
                            <button
                                type="button"
                                className="btn  pull-left"
                                style={{marginTop: 0, marginLeft: -20}}
                                onClick={() => this.addTask("currentTask", "tasks")}
                            >Add
                            </button>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-lg-12"}>
                            <ul
                                style={{minHeight: 170, maxHeight: 150, overflow: "scroll", listStyle: "none"}}
                                className="table"
                            >

                                {ls.map((item) => {
                                    return (
                                        <li style={{decoration: "none"}}>
                                        <span style={{width: 20}}>
                                            <a
                                                style={{color: "red", cursor: "pointer"}}
                                                onClick={() => this.removeListItem(item.key)}
                                            ><i className={"fa fa-trash-o"}/></a>
                                        </span>
                                            &nbsp;&nbsp;
                                            <span>
                                            {item.key + " : " + item.val}
                                        </span>
                                        </li>
                                    )
                                })}

                            </ul>
                        </div>
                    </div>
                </fieldset>
            </div>
        )
    }

    render() {
        const title = this.props.popTitle;
        const statusKey = this.props.popStatus;
        let status = this.props.state[statusKey];
        const fnClose = () => {
            this.props.popFnClose(statusKey);
        }
        if (!status) {
            return null
        }
        const actions = [
            <FlatButton
                label={"Close"}
                primary={true}
                onClick={fnClose}
            />,
        ];

        return (
            <MuiThemeProvider>
                <Dialog
                    title={title}
                    actions={actions}
                    modal={false}
                    fullWidth={true}
                    fullScreen
                    open={status}
                    onRequestClose={fnClose}
                    autoScrollBodyContent={true}
                    style={{marginTop: 100, minWidth: 500, maxWidth: "none"}}
                >

                    <div className={"container"}>
                        <h1 className={"alert alert-info"} style={{marginTop: 10}}>
                            {title}
                            <a className={"btn btn-danger pull-right"} onClick={() => fnClose()}>Cancel</a>
                        </h1>
                    </div>
                    <div className={"container"}>
                        <form onSubmit={this.submitEntry}>
                            <div className={"row"}>
                                <div className={"col col-log-6"}>
                                    {this.renderModelSection("General")}
                                    {this.renderAdditionalInfo()}
                                </div>
                                <div className={"col col-log-6"}>
                                    {this.renderModelSection("Inventory")}
                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col col-log-12"}>

                                </div>
                            </div>

                            <div className={"row"}>
                                <div className={"col col-log-6"}>
                                    <button type={"submit"} className={"btn btn-primary pull-right"}>
                                        Submit
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </Dialog>
            </MuiThemeProvider>
        )
    }
}
