import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {FindImagesAny} from "../generic/find-data";
import {ServiceDataLoadderSubscribe} from "../../api/service-data-loader"
import {removeEntry} from "../generic/find-data";
import PopupNewSpec from "./popup-add-spec";

import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../common/redux-dispatch-to-props";
import Wallpaper from "../common/ef-wallper";
import {getValue, renderForm, setValue, setValueBox} from "../setting-maintenance/api";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";


class ComFuelSpec extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPart: null,
            showUploadImage: false,
            showAddSpec: false,
            newEntry: {},
            cqlTableRef:"SpecFuel"
        }

    }

    componentDidMount = async () => {
        await this.loadInitialData();
    }

    loadInitialData = async () => {
        await ServiceDataLoadderSubscribe(this, [
            "vehicle",
            "fuel-spec",
            "files"
        ])
    }
    openPopup = (stateKey, selectedDataKey, selectedDataValue) => {
        let tmp = this.state;
        tmp[stateKey] = true;
        if (typeof selectedDataKey !== "undefined") {
            tmp[selectedDataKey] = selectedDataValue;
        }
        this.setState(tmp);

    }
    closePopup = (stateKey, selectedDataKey) => {
        this.setState({
            [stateKey]: false,
            [selectedDataKey]: null
        })
    }
    setParentStateKey = (key, val) => {
        this.setState({
            [key]: val,
        })
    }

    removeEntry = async (e, row) => {
        const table = "SpecFuel";
        const deleteKey = ["org", "vehicle"];
        await removeEntry(e, row, deleteKey, table, this)
    }
    submitEntry = async (e, table, addData, _this) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        let entry = {..._this.state.newEntry};
        entry.Org = GetUserToken().OrgCode;
        for (let i in addData) {
            const row = addData[i];
            if (typeof row.type !== "undefined") {
                if (row.type === "hidden") {
                    entry[row.field] = row.defaultValue;
                }
                if (row.type === "float") {
                    entry[row.field] = parseFloat(entry[row.field]);
                }
            }
        }
        //todo let add our default variable

        let hub = entry;
        hub.Org = GetUserToken().OrgCode;
        hub.OperationCostKm = {};

        let backend = eConfig.mainBackend;
        let endpoint = "/api/fuel/spec/new";

        console.log("submitEntry *** send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    alert("Thank you Record submitted!");
                    let tmp = _this.state;
                    tmp.showAddSpec = false;
                    tmp.newEntry={};
                    _this.setState(tmp);
                    _this.loadInitialData();
                }
            }

        });
    }
    getVehicleOption = () => {
        const vehicles = this.state.Vehicles;
        if (typeof vehicles === "undefined") {
            return []
        }
        let ls = [];
        vehicles.map((row) => {
            ls.push(row.ref);
        });
        return ls
    }
    renderPartList = () => {

        let ls = [];
        if (typeof this.state.SpecFuel !== "undefined") {
            ls = this.state.SpecFuel
        }

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>Reg.#</th>
                    <th>Litter</th>
                    <th>Distance</th>
                    <th>Allowance</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    let link = FindImagesAny(row.vehicle, this.state.Files, "vehicle");

                    return (
                        <tr key={index}>
                            <td width={120}>
                                <img src={link} width={"100%"}/>

                            </td>
                            <td>{row.vehicle}</td>
                            <td>{row.litter}</td>
                            <td>{row.distancekm}</td>
                            <td>{row.allowance}</td>
                            <td>
                                <a
                                    style={{color: "#33b8ff", cursor: "pointer"}}
                                    onClick={(e) => this.removeEntry(e, row)}
                                >
                                    Delete
                                </a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )

    }

    renderForm = () => {
        let ls = [
            {
                field: "vehicle",
                label: "Vehicle",
                options: this.getVehicleOption(),
                type: "option",
                required: true
            },
            {
                field: "litter",
                label: "Liter",
                options: [],
                type: "float",
                required: true
            }, {
                field: "DistanceKm",
                label: "Distance",
                options: [],
                type: "float",
                required: true
            },
            {
                field: "Allowance",
                label: "Allowance",
                options: [],
                type: "float",
                required: true
            },
        ];

        let _this = this;

        return (
            <div className={"container"}>
                <p
                    className={"alert alert-success"}>
                    FUEL SPEC [New Record form]
                    <a
                        onClick={() => this.setState({showAddSpec:false})}
                        className={"pull-right"}
                        style={{color: "red", cursor: "pointer"}}
                    >Cancel
                    </a>
                </p>
                <form onSubmit={(e) => this.submitEntry(e, "SpecFuel", ls, _this)}>
                    {ls.map((row, index) => {
                        let dataType = "string";
                        if (typeof row.dataType !== "undefined") {
                            dataType = row.dataType;
                        }
                        if (row.type === "option") {
                            return (
                                <div className="row" key={"zty" + index}>
                                    <div className="form-group col-md-12 col-lg-12">
                                        <label htmlFor="inputState">{row.label}</label>
                                        <select
                                            datatype={dataType}
                                            name={row.field}
                                            className="form-control"
                                            onChange={(e) => setValueBox(e, _this, dataType)}
                                            defaultValue={getValue(row.field, _this)}
                                            required={row.required}
                                        >
                                            <option value="" selected>Choose...</option>
                                            {row.options.map((item, index2) => {
                                                let key = item;
                                                let desc = item.toLocaleUpperCase();
                                                return (
                                                    <option key={index2} value={key}>{desc}</option>
                                                )
                                            })}

                                        </select>
                                    </div>
                                </div>
                            )
                        }

                        if (row.type === "hidden") {
                            //setValueHidden(row.defaultValue, row.field,_this,dataType)
                            return (
                                <div className="row" key={"zty" + index} style={{display: true}}>
                                    <div className="form-group col-md-12  col-lg-12">
                                        <label htmlFor="inputState">{row.label}</label>
                                        <input
                                            datatype={dataType}
                                            type="text"
                                            className="form-control"
                                            onChange={(e) => setValue(e, row.field, _this, dataType)}
                                            value={row.defaultValue}
                                            required={row.required}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div className="row" key={"zty" + index}>
                                <div className="form-group col-md-12  col-lg-12">
                                    <label htmlFor="inputState">{row.label}</label>
                                    <input
                                        datatype={dataType}
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => setValue(e, row.field, _this, dataType)}
                                        value={getValue(row.field, _this)}
                                        required={row.required}
                                    />
                                </div>
                            </div>
                        )
                    })}
                    <button
                        type={"submit"}
                        className={"btn btn-default pull-right "}
                        style={{backgroundColor: " #00b3ee"}}
                    > Submit
                    </button>
                </form>
            </div>
        );

    }

    openForm = () => {
        let tmp = this.state;
        tmp.showAddSpec = true;
        this.setState(tmp);
        console.log("(:)-->openForm--> ", this.state.showAddSpec);
    }

    render() {

        return (
            <PageContentWallpaper {...this.props} >
                <div>
                    {!this.state.showAddSpec&&(
                        <div className="row">
                            <div className="col col-xs-12">
                                <h4
                                    className={"alert alert-info"}
                                    style={{color: "gray", fontSize: 18, textAlign: "center"}}
                                >
                                    <i className={"fa fa-setting"}>
                                        &nbsp;
                                    </i>&nbsp;
                                    {"Fuel Spec".toLocaleUpperCase()}
                                    <small>
                                        <a
                                            onClick={() => this.openForm()}
                                            className={"pull-right"}
                                            style={{color: "#33b8ff", cursor: "pointer"}}
                                        >Add New
                                        </a>
                                    </small>
                                </h4>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col col-xs-12">
                            {!this.state.showAddSpec?this.renderPartList(): this.renderForm()}
                        </div>
                    </div>
                </div>
            </PageContentWallpaper>
        )
    }


}


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(class ConFleetFaults extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        return (
            <Wallpaper {...this.props} hasHeader={true} hasFooter={true}>
                <ComFuelSpec {...this.props}/>

            </Wallpaper>
        );
    }
})
