import React, {Component} from 'react';

import PageHeading from './header1';
import PageMenu from './menu1';
import PageFooter from './footer';
import "../../asset/css/wallpaper.css"
import Paper from 'material-ui/Paper';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';


export default class Wallpaper extends Component {

    constructor(props){
        super(props)
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    getPer=(per,sider)=>{
        if(sider==="width"){
            let tot = (this.state.width / 100) * per;
            return tot
        }
        if(sider==="height"){
            let tot = (this.state.height / 100) * per;
            return tot
        }
        return 0;
    }
    render() {

        const menuWidth = this.getPer(15,"width");
        const contentWidth = this.getPer(80,"width");
        const pageHeight = this.getPer(100,"height");
        const style = {
            margin: 0,
        };

        return (

            <div className="" style={{
                width:this.state.width,
                minWidth:this.state.width,
                overflow:"auto",
                minHeight:pageHeight,
                height:"auto",
                padding:10
            }}>
                {this.props.hasHeader &&(
                    <PageHeading {...this.props}/>
                )}


                {this.props.hasHeader ?
                    <div className="row" style={{minHeight:pageHeight}}>
                        <div className="col-lg-3" style={{
                            /*width:menuWidth,
                            minWidth:menuWidth,
                            maxWidth:menuWidth,*/
                            backgroundColor:"white",
                            marginTop:0,
                            paddingTop:-10
                        }}>
                            <PageMenu {...this.props}/>
                        </div>

                        <div className="col-lg-9" style={{
                            /*width:contentWidth,
                            minWidth:contentWidth,
                            maxWidth:contentWidth,*/
                            marginRight:0,

                        }}>
                            {this.props.children}
                        </div>

                    </div> :
                    <div className="row">
                        <div
                            className="col col-lg-12"
                            style={{marginRight:50}}
                        >
                            {this.props.children}
                        </div>
                    </div>

                }



                {this.props.hasFooter &&(
                    <div className="row"
                    >
                        <div className="col col-lg-12">
                    <PageFooter {...this.props}/>
                        </div>
                    </div>
                )}
            </div>


        );
    }
}



