import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {FindImagesAny} from "../generic/find-data";
import {ServiceDataLoadderSubscribe} from "../../api/service-data-loader"
import {removeEntry} from "../generic/find-data";
import PopupNewSpec from "./popup-add-spec";

import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../common/redux-dispatch-to-props";
import Wallpaper from "../common/ef-wallper";
import {getValue, renderForm, setValue, setValueBox} from "../setting-maintenance/api";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";
import moment from "moment";


class ComFuelUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPart: null,
            showUploadImage: false,
            showAddSpec: false,
            newEntry: {},
            cqlTableRef: "FillingFuel"
        }

    }

    componentDidMount = async () => {
        await this.loadInitialData();
    }

    loadInitialData = async () => {
        await ServiceDataLoadderSubscribe(this, [
            "vehicle",
            "driver",
            "fuel-spec",
            "files"
        ])
    }

    getOptions = (entityIn, cat) => {
        //todo define props keys
        const entityData = entityIn + "s";
        const entityAttributes = entityIn + "Attributes";

        //todo get props data
        const dataAttribute = (typeof this.state[entityAttributes] !== "undefined" ? this.state[entityAttributes] : []);
        const dataEntity = (typeof this.state[entityData] !== "undefined" ? this.state[entityData] : []);


        let keyList = [];
        for (let i in dataAttribute) {
            if (dataAttribute[i].section === cat) {
                keyList.push(dataAttribute[i].fieldname);
            }
        }

        let ls = [];
        for (let e in dataEntity) {
            const rowParrent = dataEntity[e];
            const key = rowParrent.ref;
            let val = "";
            let x = 0;
            for (let i in keyList) {
                const field = keyList[i];
                const v = rowParrent.data[field] || "--"

                val += x == 0 ? " " + v : ", " + v
            }
            ls.push({
                key: key,
                val: val
            })
        }

        return ls

    }
    getVehicleOption = () => {
        const vehicles = this.state.Vehicles;
        if (typeof vehicles === "undefined") {
            return []
        }
        let ls = [];
        vehicles.map((row) => {
            ls.push({key: row.ref, val: row.ref});
        });
        return ls
    }
    getDriverOption = () => {
        const vehicles = this.state.Vehicles;
        if (typeof vehicles === "undefined") {
            return []
        }
        let ls = [];
        vehicles.map((row) => {
            ls.push(row.ref);
        });
        return ls
    }
    renderPartList = () => {

        let ls = [];
        if (typeof this.state.UsageFuel !== "undefined") {
            ls = this.state.UsageFuel
        }

        console.log("=====> ",ls);
        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>Reg.#</th>
                    <th>Driver</th>
                    <th>Start</th>
                    <th>Cost</th>
                    <th>Fuel Spec</th>
                    <th>Rand/Km</th>
                    <th>Allowance</th>
                    <th>Target</th>
                    <th>&nbsp;Flag</th>
                    <th>Actual</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    let link = FindImagesAny(row.vehicle, this.state.Files, "vehicle");

                    let flagColor="green";
                    if(row.flagColor==="orange"){
                        flagColor = "yellow"
                    }
                    if(row.flagcolor==="red"){
                        flagColor = "red"
                    }
                    let cost="R"+row.totalcost;
                    let spec= row.specliter+"/"+row.speckm+"";
                    let randKm = parseFloat(row.costperkmtarget)-parseFloat(row.allowance);
                    let allowance = row.allowance;
                    let target = row.costperkmtarget;
                    let actual=row.costperkmactual;
                    let colorStatus="black";
                    let status = moment(row.dateend).format("D MMM") ;
                    if(row.dateend===""){
                         cost="---";
                         spec= "---";
                         randKm = "---";
                         allowance = "---";
                         target = "---";
                         actual="---";
                        colorStatus="red";
                        status = row.usagestatus;
                    }

                    let dataStart = moment(row.datestart).format("D MMM");

                    return (
                        <tr key={index}>
                            <td width={80}>
                                <img src={link} width={"100%"}/>
                            </td>
                            <td>{row.vehicle}</td>
                            <td>--D--</td>
                            <td>{dataStart}</td>
                            <td>{cost}</td>
                            <td>{spec}</td>
                            <td>{randKm}</td>
                            <td>{allowance}</td>
                            <td>{target}</td>
                            <td>
                                {row.dateend !== "" ?
                                    <i className={"fa fa-flag"} style={{color: flagColor}}>&nbsp;</i>:
                                    "---"
                                }
                            </td>
                            <td>{actual}</td>
                            <td style={{color:colorStatus}}>{status}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )

    }

    openForm = () => {
        let tmp = this.state;
        tmp.showAddSpec = true;
        this.setState(tmp);
        console.log("(:)-->openForm--> ", this.state.showAddSpec);
    }

    render() {

        return (
            <PageContentWallpaper {...this.props} >
                <div>
                    {!this.state.showAddSpec && (
                        <div className="row">
                            <div className="col col-xs-12">
                                <h4
                                    className={"alert alert-info"}
                                    style={{color: "gray", fontSize: 18, textAlign: "center"}}
                                >
                                    <i className={"fa fa-setting"}>
                                        &nbsp;
                                    </i>&nbsp;
                                    {"Fuel Filling".toLocaleUpperCase()}
                                </h4>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        <div className="col col-xs-12">
                            { this.renderPartList()}
                        </div>
                    </div>
                </div>
            </PageContentWallpaper>
        )
    }


}


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(class ConFuelUsage extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        return (
            <Wallpaper {...this.props} hasHeader={true} hasFooter={true}>
                <ComFuelUsage {...this.props}/>
            </Wallpaper>
        );
    }
})
