import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import {
    fetchUtilData,
    handleInput,
    renderList,
    renderForm, removeEntry
} from "./api-util";

export default class ComUtilMaintenanceActivityType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            newEntry: {
                Name: "",
            },
            showList: true,
            cqlTable: "UtilActivityList"
        }
    }

    async componentDidMount() {
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await fetchUtilData(this, this.state.cqlTable, "Data");
    }
    renderList = () => {
        const keyList = [
            {field: "name", label: "Name"},
            {field: "orgdatetime", label: "Last Updated"}
        ];
        const deleteKey=["org","name"];
        const deleteTable="UtilActivityList";
        return renderList(this, "Data", keyList,deleteKey,deleteTable, "showList", removeEntry)
    }
    renderForm = () => {
        const options = ["yes", "no"];
        let ls = [
            {field: "Name", label: "Name of Activity", options: options, type: "text", required: true},
        ]
        return renderForm(ls, "showList", this.state.cqlTable, this)
    }

    render() {

        let _this = this;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row">
                        <div className="col col-xs-12">
                            <h3>
                                Maintenance Job Categories


                                {this.state.showList ?
                                    <a className={"btn btn-primary pull-right"}
                                       onClick={(e) => handleInput(e, "showList", false, _this)}>Add New Category</a>
                                    :
                                    <a className={"btn btn-danger pull-right"}
                                       onClick={(e) => handleInput(e, "showList", true, _this)}>Cancel Adding new
                                        Record</a>
                                }
                            </h3>
                        </div>
                    </div>

                    {this.state.showList ? this.renderList() : this.renderForm()}
                </div>
            </PageContentWallpaper>
        )
    }

}