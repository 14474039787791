import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {FindImagesAny, GetSectionFromDatabaseEntity} from "../generic/find-data";
import PopupPartsInventoryNew from "./popup-parts-new-inventoty";
import {ServiceDataLoadder} from "../../api/service-data-loader"
import PopupUploadFile from "./popup-upload-image";
import PopupAddStock from "./popup-add-stock";

export default class ComPartsInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInventoryNew: false,
            showProfilePicture: false,
            selectedPart: null,
            showUploadImage: false,
            showAddStock:false,
        }

    }

    async componentDidMount() {
        await this.loadInitialData();
    }

    loadInitialData = async () => {
        ServiceDataLoadder(this)
    }
    openPopup = (stateKey, selectedDataKey, selectedDataValue) => {
        let tmp = this.state;
        tmp[stateKey] = true;
        if (typeof selectedDataKey !== "undefined") {
            tmp[selectedDataKey] = selectedDataValue;
        }
        this.setState(tmp);

    }
    closePopup = (stateKey, selectedDataKey) => {
        this.setState({
            [stateKey]: false,
            [selectedDataKey]: null
        })
    }
    setParentStateKey = (key, val) => {
        this.setState({
            [key]: val,
        })
    }
    renderPartList = () => {

        let ls = [];
        if (typeof this.state.Parts !== "undefined") {
            ls = this.state.Parts
        }

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>#</th>
                    <th>PartNumber</th>
                    <th>Desc</th>
                    <th>Location</th>
                    <th>Qty</th>
                    <th>Cost/Unit</th>
                    <th>Qty Threshold</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    let link = FindImagesAny(row.partnumber, this.state.Files,"parts");
                    let lowStock=false;
                    if(parseFloat(row.quantityhand)<=parseFloat(row.quantitythreshold)){
                        lowStock=true
                    }
                    return (
                        <tr>
                            <td width={120}>
                                <img src={link} width={"100%"}/>

                            </td>
                            <td>{row.partnumber}</td>
                            <td>
                                {row.description}
                                {lowStock?
                                    <div>
                                    <span className={"btn-danger"}
                                       style={{fontSize:14,fontWeight:'bold',borderRadius:5}}
                                    >
                                        &nbsp;Low Stock&nbsp;
                                    </span>
                                    </div>:null }
                            </td>
                            <td>{row.location}</td>
                            <td style={{textAlign:"right"}}>


                                <a
                                    style={{color: "#33b8ff", cursor: "pointer"}}
                                    onClick={() => this.openPopup(
                                        "showAddStock",
                                        "selectedPart",
                                        row
                                    )}
                                >
                                    <i className={"fa fa-plus pull-left"}>&nbsp;</i>
                                </a>&nbsp;&nbsp;
                                {row.quantityhand}
                            </td>
                            <td style={{textAlign:"right"}}>{row.unit+""+row.cost}</td>
                            <td style={{textAlign:"right"}}>{row.quantitythreshold}</td>
                            <td>
                                <a
                                    style={{color: "#33b8ff", cursor: "pointer"}}
                                    onClick={() => this.openPopup(
                                        "showUploadImage",
                                        "selectedPart",
                                        row
                                    )}
                                >
                                    Change Picture
                                </a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
        return ls.map((row, index) => {

        })

        return (
            <p className={"alert"}>Available list of parts Not implement yet</p>
        )
    }

    getSelectedPartRef = () => {
        if (this.state.selectedPart !== null) {
            return this.state.selectedPart.partnumber
        }
        return "---";
    }

    render() {

        return (
            <PageContentWallpaper {...this.props} >
                <div>
                    <div className="row">
                        <div className="col col-xs-12">
                            <h4
                                className={"alert alert-info"}
                                style={{color: "gray", fontSize: 18, textAlign: "center"}}
                            >
                                <i className={"fa fa-setting"}>
                                    &nbsp;
                                </i>&nbsp;
                                {"Critical Parts Inventory".toLocaleUpperCase()}
                                <small>
                                    <a
                                        onClick={() => this.openPopup("statusPartNewInventory")}
                                        className={"pull-right"}
                                        style={{color: "#33b8ff", cursor: "pointer"}}
                                    >Add New Part
                                    </a>
                                </small>
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12">
                            {this.renderPartList()}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col col-xs-12"}>
                            <PopupPartsInventoryNew
                                popTitle={"New Inventory"}
                                _this={this}
                                popStatus={"statusPartNewInventory"}
                                popFnClose={this.closePopup}
                                popSelectedKey={""}
                                state={this.state}
                                setKey={this.setParentStateKey}
                            />

                            <PopupUploadFile
                                popTitle={"Upload File"}
                                _this={this}
                                popStatus={"showUploadImage"}
                                popFnClose={this.closePopup}
                                popSelectedKey={"selectedPart"}
                                ref={this.getSelectedPartRef()}
                                typeOptions={["document", "gallery", "profile"]}
                                tableRef={"parts"}
                                selectedRecord={this.state.selectedPart}
                                selectedRecordKey={"partnumber"}
                                state={this.state}
                                setKey={this.setParentStateKey}
                            />

                            <PopupAddStock
                                popTitle={"Add New Stock"}
                                _this={this}
                                popStatus={"showAddStock"}
                                popFnClose={this.closePopup}
                                popSelectedKey={"selectedPart"}
                                selectedRecord={this.state.selectedPart}
                                selectedRecordKey={"partnumber"}
                                state={this.state}
                                setKey={this.setParentStateKey}
                            />

                        </div>
                    </div>
                </div>
            </PageContentWallpaper>
        )
    }


}
