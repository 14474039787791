import React from "react";
import {fetchUtilData} from "../component/dashboard/api";
import {GetUserToken, PostToBackend} from "./services";
import eConfig from "../config";

const ServiceDataLoadderSubscribe = async (_this,modules) => {

    let hub={
        Org:GetUserToken().OrgCode
    }
    let backend = eConfig.mainBackend;
    let endpoint = "/api/fleet/data/loader";

    await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
        console.log("ServiceDataLoadder response > ", dataIn);
        let my={}
        if (dataIn === null) {
            my={
                Activities:[],
                Attributes:[],
                Databases:[],
                Documents:[],
                DriverAttributes:[],
                Drivers:[],
                Faults:[],
                Manufacturers:[],
                ManufacturerAttributes:[],
                Parts:[],
                Personnel:[],
                PersonnelAttributes:[],
                Services:[],
                SupplierAttributes:[],
                Suppliers:[],
                VehicleAttributes:[],
                Vehicles:[],
                Vendors:[],
                "Fleet-LocationAttributes":[],
                "Fleet-Locations":[],
            }
        }
        if (typeof dataIn.RESULT !== "undefined") {
            const data = dataIn.RESULT;
            my=data
        }

        let tmp = _this.state;
        tmp={...tmp,...my}
        _this.setState(tmp)
    });


    /*for(let e in modules){
        const i = modules[e];
        if(i==="vehicle"){
            await fetchUtilData(_this, "Vehicle", "Vehicles");
            await fetchUtilData(_this, "UtilDatabaseOptions", "DatabaseOptions", "vehicle");
            await fetchUtilData(_this, "GeneralTableField", "VehicleAttributes", "vehicle");
            await fetchUtilData(_this,  "GeneralTableData", "Databases");
        }
        if(i==="fuel-spec"){
            await fetchUtilData(_this, "SpecFuel", "SpecFuel");
            await fetchUtilData(_this, "FillingFuel", "FillingFuel");
            await fetchUtilData(_this, "UsageFuel", "UsageFuel");

        }
        if(i==="files"){
            await fetchUtilData(_this, "Documents", "Files");
        }
        if(i==="driver"){
            await fetchUtilData(_this, "GeneralTableField", "DriverAttributes", "driver");
            await fetchUtilData(_this,  "GeneralTableData", "Drivers", "driver");
        }
        if(i==="personnel"){
            await fetchUtilData(_this, "GeneralTableField", "PersonnelAttributes", "personnel");
            await fetchUtilData(_this,  "GeneralTableData", "Personnel", "personnel");
        }
        if(i===""){
            await fetchUtilData(_this, "GeneralTableField", "SupplierAttributes", "supplier");
            await fetchUtilData(_this,  "GeneralTableData", "Suppliers", "supplier");
        }
        if(i===""){

        }
        if(i===""){

        }
    }
*/


}

const ServiceDataLoadder = async (_this) => {

   // "/fleet/data/loader"
    let hub={
        Org:GetUserToken().OrgCode
    }
    let backend = eConfig.mainBackend;
    let endpoint = "/api/fleet/data/loader";

    await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
        console.log("ServiceDataLoadder response > ", dataIn);
        let my={}
        if (dataIn === null) {
            my={
                Activities:[],
                Attributes:[],
                Databases:[],
                Documents:[],
                DriverAttributes:[],
                Drivers:[],
                Faults:[],
                Manufacturers:[],
                ManufacturerAttributes:[],
                Parts:[],
                Personnel:[],
                PersonnelAttributes:[],
                Services:[],
                SupplierAttributes:[],
                Suppliers:[],
                VehicleAttributes:[],
                Vehicles:[],
                Vendors:[],
                "Fleet-LocationAttributes":[],
                "Fleet-Locations":[],
            }
        }
        if (typeof dataIn.RESULT !== "undefined") {
            const data = dataIn.RESULT;
            my=data
        }

        let tmp = _this.state;
        tmp={...tmp,...my}
        _this.setState(tmp)
    });


    /*await fetchUtilData(_this, "UtilActivityList", "Activities");
    await fetchUtilData(_this, "UtilServiceList", "Services");
    await fetchUtilData(_this, "Vehicle", "Vehicles");
    await fetchUtilData(_this, "Documents", "Files");
    await fetchUtilData(_this, "GeneralTableField", "DriverAttributes", "driver");
    await fetchUtilData(_this,  "GeneralTableData", "Drivers", "driver");
    await fetchUtilData(_this, "GeneralTableField", "PersonnelAttributes", "personnel");
    await fetchUtilData(_this,  "GeneralTableData", "Personnel", "personnel");
    await fetchUtilData(_this, "UtilDatabaseOptions", "DatabaseOptions", "vehicle");
    await fetchUtilData(_this, "GeneralTableField", "SupplierAttributes", "supplier");
    await fetchUtilData(_this,  "GeneralTableData", "Suppliers", "supplier");
    await fetchUtilData(_this, "GeneralTableField", "Attributes");
    await fetchUtilData(_this,  "GeneralTableData", "Databases");
    await fetchUtilData(_this,  "Parts", "Parts");
    await fetchOperationPlanned(_this);
    await fetchMaintenancePlan(_this);
    await fetchJobScheduleProcessing(_this);
    await fetchCompletedJobs(_this);
    await fetchFaults(_this);*/

}

const fetchCompletedJobs = async (_this) => {

    let conds = [{
        Key: "org",
        Val: GetUserToken().OrgCode,
        Type: "string"
    }];
    await fetchingAll("assignjobhistory", conds, (data) => {
        console.log("fetchCompletedJobs :> ", data);
        //const keyOne = "position";
        /*data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)*/
        _this.setState({
            CompletedJobs: data
        });

    });
}

const fetchOperationPlanned = async (_this) => {

    let conds = [{
        Key: "org",
        Val: GetUserToken().OrgCode,
        Type: "string"
    }];
    await fetchingAll("AssignJob", conds, (data) => {
        console.log("fetchOperationPlanned :> ", data);
        //const keyOne = "position";
        /*data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)*/
        _this.setState({
            Data: data,
            AssignJobs:data,
        });

    });
}
const fetchMaintenancePlan = async (_this) => {
    let conds = [{
        Key: "org",
        Val: GetUserToken().OrgCode,
        Type: "string"
    }];
    await fetchingAll("maintenanceplaning", conds, (data) => {
        console.log("fetchMaintenancePlan :> ", data);
        //const keyOne = "position";
        /*data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)*/
        _this.setState({
            Plannings: data
        });

    });
}
const fetchFaults= async (_this) => {
    let conds = [{
        Key: "org",
        Val: GetUserToken().OrgCode,
        Type: "string"
    }];
    await fetchingAll("Faults", conds, (data) => {
        console.log("fetchFaults :> ", data);
        //const keyOne = "position";
        /*data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)*/
        _this.setState({
            Faults: data
        });

    });
}


const fetchJobScheduleProcessing = async (_this) => {

    const user = GetUserToken();
    const hub = {
        Org: user.OrgCode,
        /*JobFrom string
        Vehicle string
        JobStatus string*/
        Stage: "processing"
    }

    let backend = eConfig.mainBackend;
    let endpoint = "/api/fleet/job/schedule/list";

    console.log("fetchJobScheduleProcessing send > ", hub);
    await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
        console.log("fetchJobScheduleProcessing response > ", dataIn);
        if (dataIn === null) {
            return null
        }
        if (typeof dataIn.RESULT !== "undefined") {
            const data = dataIn.RESULT;
            _this.setState({
                JobSchedules: data
            })

        } else {
            return null
        }
    });


}
const fetchingAll = async (table, conds, callback) => {
    let hub = {
        Fields: [],
        DbName: "fleetminder",
        TableName: table,
        Conditions: conds,
    }
    let backend = eConfig.mainBackend;
    let endpoint = "/api/global-service/list";

    console.log("fetchingAll send > ", table, hub);
    await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
        //console.log("fetchingAll response > ", table, dataIn);
        if (dataIn === null) {
            callback([]);
            return null
        }
        if (typeof dataIn.RESULT !== "undefined") {
            const data = dataIn.RESULT;

            callback(data);
        } else {
            callback([]);
        }
    });
}

export {
    ServiceDataLoadder,
    ServiceDataLoadderSubscribe,
    fetchOperationPlanned,
    fetchMaintenancePlan,
    fetchFaults,
    fetchJobScheduleProcessing
}
