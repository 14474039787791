import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";
import React from "react";
import {FindImages} from "../generic/find-data";
import {Card} from "react-bootstrap";

const VehicleImageCard = ({Files, dataChooseService}) => {
    let defaultLink = "https://www.efleets.com/content/efleets/us/en/how-it-works/maintenance-management/_jcr_content/par/interior_hero_copy/largeImage.img.jpg/1530820884503.jpg";

    if (dataChooseService !== null) {

        defaultLink = FindImages(dataChooseService.vehicle, Files);

    }
    return (
        <Card.Img
            variant="top"
            src={defaultLink}
        />

    )

}
const findRecordGeneral = (ref, fieldName, dataIn) => {
    for (let i in dataIn) {
        const row = dataIn[i];
        if (typeof row[fieldName] !== "undefined") {
            if (row[fieldName] === ref) {
                return row
            }
        }
    }
    return null;
}

const buildDashActivityDue = (opData, planDdata) => {
    let output = {};
    for (let z in planDdata) {
        const activityName = planDdata[z].activity;
        let totVehicle = 0;//opData.length;
        let vehicles = [];
        for (let i in opData) {
            const row = opData[i];
            if (row.activity === activityName) {
                let veh = {}
                veh.vehicle = row.vehicle;
                veh.service = row.service;
                veh.jobNumber = row.jobnumber;
                veh.tasks = [];
                for (let task in row.tasks) {
                    veh.tasks.push(task);
                }
                vehicles.push(veh)
            }
        }
        output[activityName] = {
            activity: activityName,
            selectedDash: "activity",
            total: vehicles.length,
            vehicles: vehicles
        };
    }
    let my = [];
    for (let i in output) {
        const row = output[i];
        my.push(row);
    }
    return my;

}

const fetchGeneralData = async (_this, tableName, stateField, conds) => {
    /* let conds = [{
         Key: "org",
         Val: GetUserToken().OrgCode,
         Type: "string"
     }];*/
    await fetchingAll(_this, tableName, conds, (data) => {
        //console.log("fetchUtilData :> ", tableName, " > ", data);
        _this.setState({
            [stateField]: data
        });

    });
}

const fetchUtilData = async (_this, tableName, stateField, tableRef) => {
    let conds = [{
        Key: "org",
        Val: GetUserToken().OrgCode,
        Type: "string"
    }];
    if (typeof tableRef !== "undefined") {
        conds.push({
            Key: "TableRef",
            Val: tableRef,
            Type: "string"
        })
    }
    await fetchingAll(_this, tableName, conds, (data) => {
        //console.log("fetchUtilData :> ", tableName, " > ", tableRef, " > ",conds," > ",conds," > ", data);
        _this.setState({
            [stateField]: data
        });

    });
}
const fetchingAll = async (_this, table, conds, callback) => {
    let hub = {
        Fields: [],
        DbName: "fleetminder",
        TableName: table,
        Conditions: conds,
    }
    let backend = eConfig.mainBackend;
    let endpoint = "/api/global-service/list";

    console.log("fetchingAll send > ", table, hub);
    await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
        //console.log("fetchingAll response > ", table, dataIn);
        if (dataIn !== null) {
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        }

    });
}

const handleInput = (e, key, val, _this) => {
    e.preventDefault();
    let tmp = _this.state;
    tmp[key] = val;
    _this.setState(tmp);
}
const getValue = (key, _this) => {
    return _this.state.newEntry[key]
}
const setValueHidden = (val, key, _this, dataType) => {
    const state = _this.state;
    let newEntry = state.newEntry;
    newEntry[key] = val;
    if (dataType === "float") {
        let myNumber = parseFloat(val);
        if (isNaN(myNumber)) {
            myNumber = 0
        }
        newEntry[key] = myNumber;
    }
    state.newEntry = newEntry;
    _this.setState(state);
}
const setValue = (e, key, _this, dataType) => {
    let val = "";
    if (typeof e.target !== "undefined") {
        val = e.target.value;
    }
    const state = _this.state;
    let newEntry = state.newEntry;
    newEntry[key] = val;
    if (dataType === "float") {
        let myNumber = parseFloat(val);
        if (isNaN(myNumber)) {
            myNumber = 0
        }
        newEntry[key] = myNumber;
    }
    state.newEntry = newEntry;
    _this.setState(state);
}
const setValueBox = (e, _this, dataType) => {
    const val = e.target.value;
    const key = e.target.name;

    console.log("setValueBox ^^^--> ", key, " > ", val);
    const state = _this.state;
    let newEntry = state.newEntry;
    newEntry[key] = val;
    if (dataType === "float") {
        let myNumber = parseFloat(val);
        if (isNaN(myNumber)) {
            myNumber = 0
        }
        newEntry[key] = myNumber;
    }
    state.newEntry = newEntry;
    _this.setState(state);
}
const removeEntry = async (e, row, deleteKey, table, _this) => {
    e.preventDefault();
    if (!window.confirm("Are you sure that you want to remove?")) {
        return
    }
    //Conditions
    let conds = [
        /*{Key: "org", Val: row.org, Type: "string"},
        {Key: "name", Val: row.name, Type: "string"},*/
    ]
    for (let i in deleteKey) {
        const key = deleteKey[i];
        conds.push({
            Key: key,
            Val: row[key],
            Type: "string"
        });
    }
    let hub = {
        Fields: [],
        DbName: "fleetminder",
        TableName: table,
        Conditions: conds,
    }
    let backend = eConfig.mainBackend;
    let endpoint = "/api/global-service/remove";

    console.log("removeEntry send > ", hub);
    await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
        console.log("removeEntry response > ", dataIn);
        if (dataIn !== null) {
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Record  removed!");
                _this.setState({showList: true});
                _this.loadInitialData();
            }
        }
    });
}
const submitEntry = async (e, table, addData, _this) => {
    e.preventDefault();
    if (!window.confirm("Are you sure that you want to submit?")) {
        return
    }

    let entry = {..._this.state.newEntry};
    entry.Org = GetUserToken().OrgCode;
    for (let i in addData) {
        const row = addData[i];
        if (typeof row.type !== "undefined") {
            if (row.type === "hidden") {
                entry[row.field] = row.defaultValue;
            }
        }
    }
    if (typeof _this.state.isCombineKey !== "undefined") {
        entry = {};
        entry.Org = GetUserToken().OrgCode;
        let uniqueKey = "Ref";
        const attributes = _this.state[_this.state.combineAttributeListKey];
        for (let i in attributes) {
            const row = attributes[i];
            if (row.unique === "yes") {
                uniqueKey = row.name;
            }
        }
        entry.UniqueKey = uniqueKey;
        entry[_this.state.combineFieldName] = _this.state.newEntry[uniqueKey];
        entry[_this.state.combineDataEntry] = {"Identify": _this.state.newEntry[uniqueKey], ..._this.state.newEntry};
    }
    //todo let add our default variable
    entry.Id = "";
    entry.Status = "";
    entry.Date = "";
    entry.Time = "";
    entry.OrgDateTime = "";

    let hub = {
        Fields: [],
        DbName: "fleetminder",
        TableName: table,
        In: entry,
    }
    let backend = eConfig.mainBackend;
    let endpoint = "/api/global-service/add";

    console.log("submitUploadEntry send > ", hub);
    await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
        console.log("submitUploadEntry response > ", dataIn);
        if (dataIn !== null) {
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Record submitted!");
                let tmp = _this.state;
                tmp.showList = true;
                _this.setState(tmp);
                _this.loadInitialData();
            }
        }

    });
}

const renderListInner = (_this, stateFieldStore, keyList, deleteKey, deleteTable, stateFieldShowList, fnRemoveEntry, sortKey) => {
    let state = _this.state;
    let data = state[stateFieldStore];
    if (!state[stateFieldShowList]) {
        return null
    }

    let ls = data;


    if (ls.length === 0) {
        return (
            <div className={"alert alert-danger"}>Empty list!</div>
        )
    }

    const firstRow = ls[0];
    const firstTh = firstRow.uniquekey;
    let rowAttribute = [];
    for (let i in firstRow.data) {
        if (i !== "TableRef" && i !== firstTh) {
            rowAttribute.push(i);
        }

    }


    return (
        <div className={"container "}>
            <p className={"alert alert-primary"}>Available Records</p>
            <table
                className={"table thead-light table-bordered "}
                style={{
                    border: '3px solid gray',
                    borderRightColor: 'gray',
                }}
            >
                <thead className={"thead-light "}>

                <tr>
                    <th>{firstTh}</th>
                    {rowAttribute.map((item) => {
                        if (item === "Identify") {
                            return null
                        }
                        return (
                            <th>{item}</th>
                        )
                    })}
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {

                    return (
                        <tr key={"xkdkd" + index}>
                            <th>{row.ref}</th>
                            {rowAttribute.map((item) => {
                                if (item === firstTh) {
                                    return null
                                }
                                return (
                                    <td>{row.data[item]}</td>
                                )
                            })}
                            <th style={{width: 100}}>
                                <a
                                    onClick={(e) => fnRemoveEntry(e, row, deleteKey, deleteTable, _this)}
                                    className={"pull-right"}
                                    style={{color: "blue", fontSize: 16, fontWeight: "bold"}}
                                >
                                    <span className={"fa fa-trash"}>&nbsp;Remove</span>
                                </a>

                            </th>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
const renderListInnerData = (_this, dataIn, stateFieldStore, keyList, deleteKey, deleteTable, stateFieldShowList, fnRemoveEntry, sortKey) => {
    let state = _this.state;
    let data = state[stateFieldStore];
    if (!state[stateFieldShowList]) {
        return null
    }

    let ls = data;


    if (ls.length === 0) {
        return (
            <div className={"alert alert-danger"}>Empty list!</div>
        )
    }

    const firstRow = ls[0];
    const firstTh = firstRow.uniquekey;
    let rowAttribute = [];
    for (let i in firstRow.data) {
        rowAttribute.push(i);
    }


    return (
        <div className={"container"}>
            <p className={"alert alert-primary"}>
                Available Records

            </p>
            <table
                className={"table"}
                style={{
                    border: '3px solid gray',
                    borderRightColor: 'gray',
                }}
            >
                <thead>

                <tr>
                    <th>Identify</th>
                    {rowAttribute.map((item) => {
                        if (item === "Identify") {
                            return null
                        }
                        return (
                            <td>{item}</td>
                        )
                    })}
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {

                    return (
                        <tr key={"xkdkd" + index}>
                            <th>{row.data["Identify"]}</th>
                            {rowAttribute.map((item) => {
                                if (item === "Identify") {
                                    return null
                                }
                                return (
                                    <td>{row.data[item]}</td>
                                )
                            })}
                            <th style={{width: 100}}>
                                <a
                                    onClick={(e) => fnRemoveEntry(e, row, deleteKey, deleteTable, _this)}
                                    className={"pull-right"}
                                    style={{color: "blue", fontSize: 16, fontWeight: "bold"}}
                                >
                                    <span className={"fa fa-trash"}>&nbsp;Remove</span>
                                </a>

                            </th>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}

const renderList = (_this, stateFieldStore, keyList, deleteKey, deleteTable, stateFieldShowList, fnRemoveEntry, sortKey, openNewTask) => {
    let state = _this.state;
    let data = state[stateFieldStore];
    if (!state[stateFieldShowList]) {
        return null
    }

    if (sortKey !== "") {
        const keyOne = sortKey;
        data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)
    }

    let ls = data;


    if (ls.length === 0) {
        return (
            <div className={"alert alert-danger"}>
                Empty list!
                {deleteTable === "MaintenanceTask" && (
                    <a
                        className={"btn btn-primary pull-right"}
                        onClick={(e) => openNewTask()}
                    >New Task</a>
                )}

            </div>
        )
    }

    return (
        <div className={"container"}>
            <h3 className={""}>
                <small>Available Records</small>

                {deleteTable === "MaintenanceTask" && (
                    <a
                        className={"btn btn-primary pull-right"}
                        onClick={(e) => openNewTask()}
                    >New Task</a>
                )}


            </h3>
            <table
                className={"table"}
                style={{
                    border: '3px solid gray',
                    borderRightColor: 'gray',
                }}
            >
                <thead>

                <tr>
                    {keyList.map((item) => {
                        return (
                            <td>{item.label}</td>
                        )
                    })}
                    <th>#</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {

                    return (
                        <tr key={"xkdkd" + index}>
                            {keyList.map((item) => {
                                return (
                                    <td>{row[item.field]}</td>
                                )
                            })}
                            <th style={{width: 100}}>
                                &nbsp;|&nbsp;
                                <a
                                    onClick={(e) => {
                                        handleInput(e, "currentView", row, _this);
                                        handleInput(e, "showPopup", true, _this);
                                    }}
                                    className={"pull-right"}
                                    style={{color: "blue", fontSize: 16, fontWeight: "bold"}}
                                >
                                    View
                                </a>
                            </th>
                            <th style={{width: 100}}>
                                &nbsp;|&nbsp;
                                <a
                                    onClick={(e) => fnRemoveEntry(e, row, deleteKey, deleteTable, _this)}
                                    className={"pull-right"}
                                    style={{color: "blue", fontSize: 16, fontWeight: "bold"}}
                                >
                                    {/*<span className={"fa fa-trash"}>&nbsp;Remove</span>*/}
                                    Remove
                                </a>
                            </th>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
const renderFormWithSubmit = (ls, stateFieldShow, cqlTable, _this, fnSubmit) => {
    let state = _this.state;
    if (state[stateFieldShow]) {
        return null
    }

    return (
        <div className={"container"}>
            <p className={"alert alert-success"}>New Record</p>
            <form onSubmit={(e) => fnSubmit(e)}>
                {ls.map((row, index) => {
                    if (row.type === "option") {
                        return (
                            <div className="row" key={"zty" + index}>
                                <div className="form-group col-md-12 col-lg-12">
                                    <label htmlFor="inputState">{row.label}</label>
                                    <select
                                        name={row.field}
                                        className="form-control"
                                        onChange={(e) => setValueBox(e, _this)}
                                        value={getValue(row.field, _this)}
                                        required={row.required}
                                    >
                                        <option value="" selected>Choose...</option>
                                        {row.options.map((item, index2) => {
                                            let key = item;
                                            let desc = item.toLocaleUpperCase();
                                            return (
                                                <option key={index2} value={key}>{desc}</option>
                                            )
                                        })}

                                    </select>
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div className="row" key={"zty" + index}>
                            <div className="form-group col-md-12  col-lg-12">
                                <label htmlFor="inputState">{row.label}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setValue(e, row.field, _this)}
                                    value={getValue(row.field, _this)}
                                    required={true}
                                />
                            </div>
                        </div>
                    )
                })}
                <button
                    type={"submit"}
                    className={"btn btn-default pull-right "}
                    style={{backgroundColor: " #00b3ee"}}
                > Submit
                </button>
            </form>
        </div>
    )
}
const renderForm = (ls, stateFieldShow, cqlTable, _this, fnSubmit) => {
    let state = _this.state;
    if (state[stateFieldShow]) {
        return null
    }

    let submitMe = submitEntry;
    if (typeof fnSubmit !== "undefined") {
        submitMe = fnSubmit;
    }

    return (
        <div className={"container"}>
            <p className={"alert alert-success"}>New Record</p>
            <form onSubmit={(e) => submitMe(e, cqlTable, ls, _this)}>
                {ls.map((row, index) => {
                    let dataType = "string";
                    if (typeof row.dataType !== "undefined") {
                        dataType = row.dataType;
                    }
                    if (row.type === "option") {
                        return (
                            <div className="row" key={"zty" + index}>
                                <div className="form-group col-md-12 col-lg-12">
                                    <label htmlFor="inputState">{row.label}</label>
                                    <select
                                        dataType={dataType}
                                        name={row.field}
                                        className="form-control"
                                        onChange={(e) => setValueBox(e, _this, dataType)}
                                        value={getValue(row.field, _this)}
                                        required={row.required}
                                    >
                                        <option value="" selected>Choose...</option>
                                        {row.options.map((item, index2) => {
                                            let key = item;
                                            let desc = item.toLocaleUpperCase();
                                            return (
                                                <option key={index2} value={key}>{desc}</option>
                                            )
                                        })}

                                    </select>
                                </div>
                            </div>
                        )
                    }

                    if (row.type === "hidden") {
                        //setValueHidden(row.defaultValue, row.field,_this,dataType)
                        return (
                            <div className="row" key={"zty" + index} style={{display: true}}>
                                <div className="form-group col-md-12  col-lg-12">
                                    <label htmlFor="inputState">{row.label}</label>
                                    <input
                                        dataType={dataType}
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => setValue(e, row.field, _this, dataType)}
                                        value={row.defaultValue}
                                        quired={row.required}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div className="row" key={"zty" + index}>
                            <div className="form-group col-md-12  col-lg-12">
                                <label htmlFor="inputState">{row.label}</label>
                                <input
                                    dataType={dataType}
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => setValue(e, row.field, _this, dataType)}
                                    value={getValue(row.field, _this)}
                                    quired={row.required}
                                />
                            </div>
                        </div>
                    )
                })}
                <button
                    type={"submit"}
                    className={"btn btn-default pull-right "}
                    style={{backgroundColor: " #00b3ee"}}
                > Submit
                </button>
            </form>
        </div>
    )
}

export {
    fetchUtilData,
    handleInput,
    getValue,
    setValue,
    setValueBox,
    removeEntry,
    submitEntry,
    renderList,
    renderListInner,
    renderForm,
    renderFormWithSubmit,
    renderListInnerData,
    fetchGeneralData,
    buildDashActivityDue,
    findRecordGeneral,
    VehicleImageCard
}
