import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import "../../asset/css/fieldset-lengend.css";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";
import {GetSectionFromDatabaseEntityOptions} from "../generic/find-data";


export default class CompPopupTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openStatus: true,
            tasks: {},
            newTask: {
                name: "",
                costParts: "",
                costLabour: "",
                category: "",
            },

            Vehicle: "",
            Activity: "",
            RecurrenceCategory: "mileage",
            Service: "",

            TimeRecurrenceNextDue: "",
            TimeRecurrenceRepeating: false,
            TimeRecurrenceRepeatValue: 1,
            TimeRecurrenceRepeatUnit: "",
            TimeRecurrenceReminder: "",
            TimeRecurrenceRemindValue: 1,
            TimeRecurrenceRemindUnit: "",

            MileageRecurrenceNextDue: "",
            MileageRecurrenceRepeating: false,
            MileageRecurrenceRepeatValue: 1,
            MileageRecurrenceReminder: "",
            MileageRecurrenceRemindValue: 1,

            NotifyOnCreate:false,
            Manager:"",
        }
    }

    handleInput = async (e) => {
        let tmp = this.state;
        let key = e.target.name;
        tmp[key] = e.target.value;

        this.setState(tmp)
    }
    handleInputCheckbox = async (e) => {
        let tmp = this.state;
        let key = e.target.name;
        tmp[key] = !tmp[key];
        this.setState(tmp)
    }
    fnClose = () => {
        this.setState({
            openStatus: false,
        })
    }
    addTask = (e) => {
        e.preventDefault();
        const task = this.state.newTask;
        if(task.name ===""||task.category===""||task.costLabour===""||task.costParts===""){
            return alert("Error we can't add this task because one of the field is empty! make select your category for provide the name" +
                " or put zero on the cost")
        }

        let tmp = this.state;
        tmp.tasks[tmp.newTask.name] = tmp.newTask;
        tmp.newTask = {
            name: "",
            costParts: "",
            costLabour: "",
            category: "",
        }
        this.setState(tmp);
    }
    removeTask = (name) => {
        let tmp = this.state;
        delete tmp.tasks[name];
        this.setState(tmp);
    }
    getTaskValue = (key) => {
        return this.state.newTask[key];
    }
    getValue = (key) => {
        return this.state[key];
    }
    setTaskValue = (e, key) => {
        let tmp = this.state;
        tmp.newTask[key] = e.target.value;
        this.setState(tmp);
    }
    submitMaintenanceService =async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to plan this vehicle?")) {
            return
        }
        console.log("state -(:)-> ",this.state);
        const state = this.state;
        const user =GetUserToken();
        let tasks=[];
        const fnClose = this.props.popFnClose;
        const loadInitialData = this.props._this.loadInitialData

        for(let i in state.Tasks){
             const row = state.Tasks[i];
             row.costLabour = parseFloat(row.costLabour );
             row.costParts = parseFloat(row.costLabour );
            tasks.push(row);
        }
        let hub={
            "Org": user.OrgCode,
            "Tasks":tasks,
            "Vehicle": state.Vehicle,
            "Activity":  state.Activity,
            "RecurrenceCategory":  state.RecurrenceCategory,
            "Service":  state.Service,
            "TimeRecurrenceNextDue":  state.TimeRecurrenceNextDue,
            "TimeRecurrenceRepeating": !state.TimeRecurrenceRepeating?"no":"yes",
            "TimeRecurrenceRepeatValue": parseFloat(state.TimeRecurrenceRepeatValue),
            "TimeRecurrenceRepeatUnit": state.TimeRecurrenceRepeatUnit,
            "TimeRecurrenceReminder": state.TimeRecurrenceReminder,
            "TimeRecurrenceRemindValue": parseFloat(state.TimeRecurrenceRemindValue),
            "TimeRecurrenceRemindUnit": state.TimeRecurrenceRemindUnit,
            "MileageRecurrenceNextDue": parseFloat(state.MileageRecurrenceNextDue),
            "MileageRecurrenceRepeating": !state.MileageRecurrenceRepeating?"no":"yes",
            "MileageRecurrenceRepeatValue": parseFloat(state.MileageRecurrenceRepeatValue),
            "MileageRecurrenceReminder": !state.MileageRecurrenceReminder?"no":"yes",
            "MileageRecurrenceRemindValue": parseFloat(state.MileageRecurrenceRemindValue),

            "NotifyOnCreate": !state.NotifyOnCreate?"no":"yes",
            "Manager": state.Manager,
        }

        // /fleet/job/planning/new

        let backend = eConfig.mainBackend;
        let endpoint = "/api/fleet/job/planning/new";



        console.log("submitService *** send > ", hub);


        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitService *** response > ", dataIn);
            if(dataIn===null){
                return
            }
            const data = dataIn.RESULT;
            if(data.status==="OK"){
                alert("Thank you, Job plan submitted");
                fnClose();
                loadInitialData();
                return null
            }else{
                alert("Sorry something wrong happened : "+data.status)
            }


        });

    }

    renderTaskBody = () => {
        let ls = [];
        for (let i in this.state.tasks) {
            const row = this.state.tasks[i];
            ls.push(row);
        }

        return ls.map((row, index) => {
            return (
                <tr>
                    <td>{row.name}</td>
                    <td>{row.category}</td>
                    <td>{row.costParts}</td>
                    <td>{row.costLabour}</td>
                    <td>
                        <a
                            onClick={() => this.removeTask(row.name)}
                            style={{cursor: "pointer", color: "blue"}}
                        >Remove</a>
                    </td>
                </tr>
            )
        })
    }

    render() {

        const fnClose =this.props.popFnClose;
        const popStatus=this.props.popStatus;
        const _this=this.props._this;

        const lsPersonnel=GetSectionFromDatabaseEntityOptions(
            _this.state.Personnel,
            _this.state.PersonnelAttributes,
            "names",
            null,
            "array"
        );

        const actions = [
            <FlatButton
                label={"Close"}
                primary={true}
                onClick={fnClose}
            />,
        ];

        return (
            <MuiThemeProvider>
                <Dialog
                    fullScreen
                    title={"Test NEw"}
                    actions={actions}
                    modal={false}
                    open={popStatus}
                    onRequestClose={fnClose}
                    autoScrollBodyContent={true}

                >
                    <AppBar className={""}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={fnClose} aria-label="close">
                                <CloseIcon/>
                            </IconButton>
                            <Typography variant="h6" className={""}>
                                Add New Service
                            </Typography>
                            <Button autoFocus color="inherit" onClick={this.fnClose}>
                                Cancel
                            </Button>
                        </Toolbar>
                    </AppBar>

                    <form onSubmit={this.submitMaintenanceService}>

                    <div className={"container"} style={{marginTop: 100}}>

                        <div className="row container">
                            <div className="col">
                                <select
                                    className="form-control"
                                    name={"Vehicle"}
                                    onChange={(e) => this.handleInput(e)}
                                >
                                    <option value={""}>--Select Vehicle--</option>
                                    {this.props.vehicles.map((row)=>{
                                        return(
                                            <option value={row.ref}>{row.ref}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="col">
                                <select
                                    className="form-control"
                                    name={"Activity"}
                                    onChange={(e) => this.handleInput(e)}
                                    value={this.getValue("Activity")}
                                >
                                    <option value={""}>--Select Activity--</option>
                                    {this.props.activities.map((row)=>{
                                        return(
                                            <option value={row.name}>{row.name}</option>
                                        )
                                    })}
                                </select>
                            </div>

                            <div className="col">
                                <select
                                    className="form-control"
                                    name={"RecurrenceCategory"}
                                    onChange={(e) => this.handleInput(e)}
                                    value={this.getValue("RecurrenceCategory")}
                                >
                                    <option value={""}>--Select Recurrence Category--</option>
                                    <option value={"mileage"}>Mileage recurrence</option>
                                    <option value={"time"}>Time recurrence</option>
                                </select>
                            </div>


                            <div className="col">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder={"Type your Service Name ..."}
                                    name={"Service"}
                                    onChange={(e) => this.handleInput(e)}
                                    value={this.getValue("Service")}
                                />
                            </div>

                        </div>

                        <div className="row container">
                            <div className="col col-xs-12 col-lg-12">
                                <hr style={{color: "red", backgroundColor: "red"}}/>
                            </div>
                        </div>


                        <div className="row container">

                            {this.state.RecurrenceCategory === "time" ?
                                <div className="col col-xs-12 col-lg-12">
                                    <fieldset className="scheduler-border">
                                        <legend className="scheduler-border">Time recurrence</legend>
                                        <div className="form-group row">
                                            <label htmlFor="inputPassword" className="col-sm-4 col-form-label">Next
                                                Due(Date):</label>
                                            <div className="col-sm-8">
                                                <input
                                                    type={"date"}
                                                    className="form-control"
                                                    name={"TimeRecurrenceNextDue"}
                                                    onChange={(e) => this.handleInput(e)}
                                                    value={this.getValue("TimeRecurrenceNextDue")}
                                               />

                                            </div>
                                        </div>

                                        <div className=" row">
                                            <div className={"col col-sm-6"}>
                                                <fieldset className="scheduler-border">
                                                    <legend className="scheduler-border">Repeat every</legend>
                                                    <div className="form-check" style={{textAlign: "left"}}>
                                                        <input
                                                            className="pull-left form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                            name={"TimeRecurrenceRepeating"}
                                                            onChange={(e) => this.handleInputCheckbox(e)}

                                                        />
                                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                                            Apply this option
                                                        </label>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className="form-control form-control-lg"
                                                        name={"TimeRecurrenceRepeatValue"}
                                                        onChange={(e) => this.handleInput(e)}
                                                        value={this.getValue("TimeRecurrenceRepeatValue")}
                                                    />
                                                    <select
                                                        className="form-control"
                                                        name={"TimeRecurrenceRepeatUnit"}
                                                        onChange={(e) => this.handleInput(e)}
                                                        value={this.getValue("TimeRecurrenceRepeatUnit")}
                                                    >
                                                        <option value={""}>Choose...</option>
                                                        <option value={"day"}>day(s)</option>
                                                        <option value={"week"}>week(s)</option>
                                                        <option value={"month"}>month(s</option>
                                                        <option value={"year"}>year(s)</option>
                                                    </select>
                                                    <small style={{color: "gray"}}>due date</small>
                                                </fieldset>
                                            </div>

                                            <div className={"col col-sm-6"}>
                                                <fieldset className="scheduler-border">
                                                    <legend className="scheduler-border">Show reminder</legend>
                                                    <div className="form-check" style={{textAlign: "left"}}>
                                                        <input
                                                            className="pull-left form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            name={"TimeRecurrenceReminder"}
                                                            onChange={(e) => this.handleInputCheckbox(e)}
                                                        />
                                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                                            Apply this option
                                                        </label>
                                                    </div>

                                                    <input
                                                        type="number"
                                                        className="form-control form-control-lg"
                                                        name={"TimeRecurrenceRemindValue"}
                                                        onChange={(e) => this.handleInput(e)}
                                                        value={this.getValue("TimeRecurrenceRemindValue")}
                                                    />
                                                    <select
                                                        className="form-control"
                                                        name={"TimeRecurrenceRemindUnit"}
                                                        onChange={(e) => this.handleInput(e)}
                                                        value={this.getValue("TimeRecurrenceRemindUnit")}
                                                    >
                                                        <option value={""}>Choose...</option>
                                                        <option value={"day"}>day(s)</option>
                                                        <option value={"week"}>week(s)</option>
                                                        <option value={"month"}>month(s</option>
                                                        <option value={"year"}>year(s)</option>
                                                    </select>
                                                    <small style={{color: "gray"}}>before due date</small>

                                                </fieldset>
                                            </div>

                                        </div>
                                    </fieldset>

                                </div>

                                :

                                <div className="col col-xs-12 col-lg-12">

                                    <fieldset className="scheduler-border">
                                        <legend className="scheduler-border">Meter recurrence</legend>
                                        <div className="form-group row">
                                            <label htmlFor="inputPassword" className="col-sm-4 col-form-label">Next
                                                Due(meter):</label>
                                            <div className="col-sm-8">
                                                <input
                                                    type={"number"}
                                                    className="form-control"
                                                    name={"MileageRecurrenceNextDue"}
                                                    value={this.getValue("MileageRecurrenceNextDue")}
                                                    onChange={(e) => this.handleInput(e)}
                                                />
                                            </div>
                                        </div>

                                        <div className=" row">
                                            <div className={"col col-sm-6"}>
                                                <fieldset className="scheduler-border">
                                                    <legend className="scheduler-border">Repeat every</legend>
                                                    <div className="form-check" style={{textAlign: "left"}}>
                                                        <input
                                                            className="pull-left form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                            name={"MileageRecurrenceRepeating"}
                                                            onChange={(e) => this.handleInputCheckbox(e)}
                                                        />
                                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                                            Apply
                                                        </label>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className="form-control form-control-lg"
                                                        name={"MileageRecurrenceRepeatValue"}
                                                        value={this.getValue("MileageRecurrenceRepeatValue")}
                                                        onChange={(e) => this.handleInput(e)}
                                                    />
                                                    <small style={{color: "gray"}}>(meter unit)</small>

                                                </fieldset>
                                            </div>

                                            <div className={"col col-sm-6"}>
                                                <fieldset className="scheduler-border">
                                                    <legend className="scheduler-border">Show reminder</legend>
                                                    <div className="form-check" style={{textAlign: "left"}}>
                                                        <input
                                                            className="pull-left form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                            name={"MileageRecurrenceReminder"}
                                                            onChange={(e) => this.handleInputCheckbox(e)}
                                                        />
                                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                                            Apply
                                                        </label>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className="form-control form-control-lg"
                                                        name={"MileageRecurrenceRemindValue"}
                                                        value={this.getValue("MileageRecurrenceRemindValue")}
                                                        onChange={(e) => this.handleInput(e)}
                                                    />

                                                    <small style={{color: "gray"}}>(meter unit) before due</small>


                                                </fieldset>
                                            </div>

                                        </div>

                                        <br/>

                                    </fieldset>

                                </div>
                            }


                        </div>

                        <div className="row container">
                            <div className="col col-xs-12 col-lg-12">

                                <fieldset className="scheduler-border">
                                    <legend className="scheduler-border">Task</legend>


                                        <div className="row">
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Task Name"
                                                    onChange={(e) => this.setTaskValue(e, "name")}
                                                    value={this.getTaskValue("name")}

                                                />
                                            </div>
                                            <div className="col">
                                                <select
                                                    className="form-control"
                                                    onChange={(e) => this.setTaskValue(e, "category")}
                                                    value={this.getTaskValue("category")}

                                                >
                                                    <option value={""}>--Select Category--</option>
                                                    <option value={"parts"}>Parts</option>
                                                    <option value={"labour"}>Labour</option>
                                                    <option value={"both"}>Both</option>
                                                </select>
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Cost Parts"
                                                    onChange={(e) => this.setTaskValue(e, "costParts")}
                                                    value={this.getTaskValue("costParts")}

                                                />
                                            </div>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Cost Labour"
                                                    onChange={(e) => this.setTaskValue(e, "costLabour")}
                                                    value={this.getTaskValue("costLabour")}

                                                />
                                            </div>
                                            <div className="col">
                                                <button onClick={(e)=>this.addTask(e)} className="btn  pull-right">Add
                                                    task
                                                </button>
                                            </div>
                                        </div>


                                    <hr/>

                                    <table className={"table"}>
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Category</th>
                                            <th>Cost Parts</th>
                                            <th>Cost Labour</th>
                                            <th>#</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.renderTaskBody()}
                                        </tbody>
                                    </table>
                                </fieldset>
                            </div>
                        </div>

                        <div className={"row container"}>
                            <div className="col col-xs-12 col-lg-12">
                                <fieldset className="scheduler-border">
                                    <legend className="scheduler-border">Notification</legend>
                                    <div className="form-check" style={{textAlign: "left"}}>
                                        <input
                                            className="pull-left form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="defaultCheck1"
                                            name={"NotifyOnCreate"}
                                            onChange={(e) => this.handleInputCheckbox(e)}

                                        />
                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                            Apply this option(Notify assign personnel when due)
                                        </label>
                                    </div>

                                    <select
                                        className="form-control"
                                        name={"Manager"}
                                        onChange={(e) => this.handleInput(e)}
                                        value={this.getValue("TimeRecurrenceRepeatUnit")}
                                    >
                                        <option value={""}>---Select  Personnel Assign to this Job---</option>
                                        {lsPersonnel.map((row)=>{
                                            return(
                                                <option value={row.key}>{row.val}</option>
                                            )
                                        })}

                                    </select>

                                </fieldset>

                            </div>
                        </div>

                        <div className="row container">
                            <div className="col col-xs-12 col-lg-12">
                                <button
                                    type={"submit"}
                                    className={"btn btn-primary pull-right"}
                                >Submit Service
                                </button>
                            </div>
                        </div>
                    </div>

                    </form>

                </Dialog>
            </MuiThemeProvider>
        )
    }

}
