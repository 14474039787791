import React, {Component} from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {getValue, setValue, setValueBox, submitEntry} from "./api";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";

export default class CompPopupUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newUpload: {
                type:"",
                name: "",
                base64string: "",
                filename: "",
            },
        }
    }

    submitUploadEntry = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        const _this = this.props._this;
        const fnClose = this.props.popFnClose;


        const user = GetUserToken();
        const vehicle=this.props.vehicle;
        const inFileName=this.props.fileCategory;

        //todo let add our default variable

        let hub = {};
        hub.Org    = user.OrgCode;
        hub.TableRef =this.props.tableRef;
        hub.Ref            =vehicle.ref;
        hub.Category      =this.state.newUpload.type;
        hub.Type  =this.state.newUpload.type;
        hub.Name           =inFileName;// this.state.newEntry.name;
        if(hub.Category ==="gallery"){
            hub.Name           = this.state.newUpload.name;
        }
        hub.Username      = user.Username;
        hub.Filename      =this.state.newUpload.filename;
        hub.Base64String = this.state.newUpload.base64string;


        let backend = eConfig.mainBackend;
        let endpoint = "/api/upload/new";

        console.log("submitUploadEntry obj *** send > ", _this.state.newEntry);
        console.log("submitUploadEntry *** send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitUploadEntry *** response > ", dataIn);
            if (dataIn !== null) {
                if (typeof dataIn.RESULT !== "undefined") {
                    _this.loadInitialData();
                    fnClose();

                }
            }

        });
    }

    handleInputFile = (e, id) => {
        let inputUpload = document.getElementById(id);
        let myfile = inputUpload.files[0];
        if (!myfile) {
            console.log("No file selected.");
            return;
        }
        let _this = this;
        let reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            let tmp = _this.state;
            tmp.newUpload.filename = myfile.name;
            tmp.newUpload.base64string = reader.result;
            _this.setState(tmp);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    handleFileTypeChange=(e,key)=>{
        let tmp = this.state;
        tmp.newUpload[key] = e.target.value;
        this.setState({
            tmp
        })
    }

    render(){
        const title = this.props.popTitle;
        const _this = this.props._this;
        const openStatus = this.props.popStatus;
        const fnClose = this.props.popFnClose;
        const vehicle=this.props.vehicle;
        const fileCategory=this.props.fileCategory;

        if(vehicle===null){
            return null
        }

        const actions = [
            <FlatButton
                label={"Close"}
                primary={true}
                onClick={fnClose}
            />,
        ];

        console.log("%%%%%%UPload popup event: ",this.props," > ",openStatus);

        return (
            <MuiThemeProvider>
                <Dialog
                    title={title +" Vehicle: "+vehicle.ref}
                    actions={actions}
                    modal={false}
                    open={openStatus}
                    onRequestClose={fnClose}
                    autoScrollBodyContent={true}
                >
                    <form onSubmit={this.submitUploadEntry}>
                    <p className={"alert alert-info"}>File Category: {fileCategory}</p>


                        <div className="form-group">
                            <label>File Category</label>
                            <br/>
                            <select
                                className="form-control"
                                onChange={(e) => this.handleFileTypeChange(e,"type")}
                                required={true}
                            >
                                <option selected value={""}>Choose...</option>
                                <option value={"document"}>Document</option>
                                <option value={"gallery"}>Gallery</option>
                                <option value={"profile"}>Profile Picture</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Name</label>
                            <br/>
                            <input
                                className="form-control"
                                onChange={(e) => this.handleFileTypeChange(e,"name")}
                                required={true}
                                value={this.state.newUpload.name}
                            />

                        </div>



                    <div className="form-group">
                        <label>Choose your file</label>
                        <input
                            id={"myFile"}
                            type="file"
                            className="form-control"
                            required={true}
                            onChange={(e) => this.handleInputFile(e, "myFile")}
                        />
                    </div>

                    <div className="form-group">
                        <button
                            type="submit"
                            className="btn-primary form-control"
                        >Submit
                        </button>
                    </div>

                    </form>


                </Dialog>
            </MuiThemeProvider>
        )

    }
}