import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {FindImages, GetSectionFromDatabaseEntity} from "../generic/find-data";
import PopupIssue from "./popup-issue";
import PopupIssueStatus from "./popup-issue-change-status";
import {ServiceDataLoadder} from "../../api/service-data-loader"

export default class ComFleetDashboardVehicle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showIssue: false,
            selectedVehicle:null,
            selectedVehicleId:"",

            showIssueStatus: false,
            selectedFault:null,

        }
    }
    async componentDidMount() {
        await this.loadInitialData();
    }

    loadInitialData = async () => {
        ServiceDataLoadder(this)
    }

    onChangeVehicles=(e)=>{
        const id = e.target.value;
        let rec = null;
        for(let i in this.state.Vehicles){
            const row = this.state.Vehicles[i];
            if(row.ref===id){
                rec = row
            }
        }

        this.setState({
            selectedVehicle:rec,
            selectedVehicleId:id,
        })
    }
    getSelectedVehicle=()=>{
        console.log("getSelectedVehicle ######--> ",this.state.selectedVehicle);
        return this.state.selectedVehicle
    }

    openPopupIssue = () => {
        this.setState({
            showIssue: true,
        })
    }
    closePopupIssue = () => {

        this.setState({
            showIssue: false,
            selectedVehicle:null,

        })
    }
    openPopupIssueStatus = (row) => {
        this.setState({
            showIssueStatus: true,
            selectedFault:row,
        })
    }

    closePopupIssueStatus = () => {

        this.setState({
            showIssueStatus: false,
            selectedFault:null,

        })
    }

    buildData = () => {

        let ls = [
        ];

        if(typeof this.state.Faults ==="undefined"){
            return [];
        }

        for (let i in this.state.Faults) {
            const fault = this.state.Faults[i];
            let inchargeName = "N/D";
            let inchargeEmail = "N/D";
            const o = GetSectionFromDatabaseEntity(
                this.state.Personnel,
                this.state.PersonnelAttributes,
                "names",
                fault.assignto,
                "object"
            );
            inchargeName = o.val;
            inchargeEmail = o.key;

            ls.push({
                imageLink: FindImages(fault.vehicle, this.state.Files),
                vehicle:fault.vehicle,
                description:fault.description,
                inChargeName:inchargeName,
                inChargeEmail:inchargeEmail,
                respondOn:fault.respondon,
                status:fault.faultstatus,
                comment:fault.comments,
                reportBy:fault.reportby,
                Priority:fault.priority,
                vehicleInfo: GetSectionFromDatabaseEntity(
                    this.state.Vehicles,
                    this.state.VehicleAttributes,
                    "general",
                    fault.vehicle,
                    "array"
                ),
                fault:fault,
            })

        }


        console.log("buildData -> ",ls,this.state.Faults);

        return ls

    }

    renderVehicleList = () => {
        const ls = this.buildData();

        const alertMsg = (inList) => {
            let msg = "";
            for (let h in inList) {
                const row = inList[h];
                for (let i in row) {
                    const inRow = row[i];
                    msg += "\n" + i + " : " + inRow
                }
            }
            return msg;
        }

        const innStyle = {
            color: "gray", fontSize: 11
        }


        return (
            <table className={"table"}>
                <thead>
                <tr style={{background: "gray", color: "white", maxHeight: 25, borderRadius: 1,}}>
                    <th>
                        &nbsp;
                    </th>
                    <th>Reg#</th>
                    <th>Desc</th>
                    <th>RespondOn</th>
                    <th>ReportBy</th>
                    <th>Status</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row) => {
                    return (
                        <tr>
                            <td width={120}>
                                <img src={row.imageLink} width={"100%"}/>
                            </td>

                            <td>
                                <span style={{...innStyle, fontWeight: "bold"}}>{row.vehicle}</span><br/>
                                <a
                                    onClick={() => alert(alertMsg(row.vehicleInfo))}
                                    style={{color: "#33b8ff", cursor: "pointer", fontSize: 11}}
                                >
                                    View Detail
                                </a>
                            </td>
                            <td>

                                <span style={{...innStyle}}>{row.description}</span>
                            </td>
                            <td>
                                <span style={{...innStyle}}>{row.respondOn}</span>
                            </td>
                            <td>
                                <span style={{...innStyle}}>{row.reportBy}</span>
                            </td>

                            <td style={{color: "gray", fontSize: 12}}>
                                <span style={{...innStyle}}>{row.status}</span><br/>
                                <a
                                    onClick={() => this.openPopupIssueStatus(row.fault)}
                                    style={{color: "#33b8ff", cursor: "pointer", fontSize: 11}}
                                >
                                    Edit Status
                                </a>
                            </td>
                            <td style={{color: "gray", fontSize: 12}}>
                                <span style={{...innStyle}}>{row.comment}</span><br/>

                            </td>

                        </tr>
                    )

                })}
                </tbody>
            </table>
        )



    }

    getVehicleOption=()=>{
        let ls=[];
        const vehicles=this.state.Vehicles;

        if(typeof vehicles ==="undefined"){
            return[]
        }

        return vehicles;


    }

    render() {

        let _this = this;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row">
                        <div className="col col-xs-12">
                            <h4
                                className={"alert alert-info"}
                                style={{color: "gray", fontSize: 18, textAlign: "center"}}
                            >
                                {"Log Your Fault".toLocaleUpperCase()}

                                <small>
                                    <a
                                        onClick={() => this.openPopupIssue()}
                                        className={"pull-right btn btn-primary"}
                                        style={{color: "#33b8ff", cursor: "pointer"}}
                                    >Click here to report your fault
                                    </a>
                                    <select
                                        className={"pull-right"}
                                        onChange={(e)=>this.onChangeVehicles(e)}
                                        defaultValue={this.state.selectedVehicleId}
                                        >
                                        <option value={""}>Choose vehicle---</option>
                                        {this.getVehicleOption().map((row)=>{
                                            return(
                                                <option value={row.ref}>{row.ref}</option>
                                            )
                                        })}
                                    </select>
                                </small>
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12">
                            {this.renderVehicleList()}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col col-xs-12"}>
                            <PopupIssue
                                popTitle={"New Issue"}
                                _this={this}
                                popStatus={this.state.showIssue}
                                popFnClose={this.closePopupIssue}
                                vehicle={this.getSelectedVehicle()}
                                Personnels={this.state.Personnel}
                                PersonnelAttributes={this.state.PersonnelAttributes}
                                Drivers={this.state.Drivers}
                                DriverAttributes={this.state.DriverAttributes}
                                DatabaseOptions={this.state.DatabaseOptions}
                                TableRef={"vehicle"}
                            />

                            <PopupIssueStatus
                                popTitle={"Change Issue Status"}
                                _this={this}
                                popStatus={this.state.showIssueStatus}
                                popFnClose={this.closePopupIssueStatus}
                                vehicle={this.getSelectedVehicle()}
                                fault={this.state.selectedFault}
                                Personnels={this.state.Personnel}
                                PersonnelAttributes={this.state.PersonnelAttributes}
                                Drivers={this.state.Drivers}
                                DriverAttributes={this.state.DriverAttributes}
                                DatabaseOptions={this.state.DatabaseOptions}
                                TableRef={"vehicle"}
                                options={this.state.DatabaseOptions}
                            />



                        </div>
                    </div>

                </div>
            </PageContentWallpaper>
        )
    }
}
