// #ff6d33
import React, {Component} from "react";
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "./redux-dispatch-to-props";
import {BrowserRouter as Router, Link, NavLink} from 'react-router-dom';

import {

    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse,
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem
} from "mdbreact";

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import {GetUserToken} from "../../api/services";

class PageHeading extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        isOpen: false
    };

    toggleCollapse = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    setModuleMenu = (module, menu, submenu) => {
        this.props.dispatch({
            type: "SAVE_MODULE_MENU",
            payload: {
                module: module,
                menu: menu,
                submenu
            }
        });

        localStorage.setItem("@fleetminder-module",module);
        localStorage.setItem("@fleetminder-menu",menu);
        localStorage.setItem("@fleetminder-submenu",submenu);
    }

    getLoginName=()=>{
        let user = this.props.login.User;
        if(user ===null){
            user = GetUserToken();
        }
        return {
            name:user.Surname[0].toLocaleUpperCase()+ ". "+user.Name,
            company:user.OrgCode,
            role:user.Role,
        };
    }
    getSelectedProject=()=>{
        const state = this.props.info;
        if(state.Project ==="" || state.Project ===null ){
            return {
                project: "----",
                category: "---"
            }
        }

        return {
            project: state.Project,
            category: state.Category
        }
    }

    /*
    color="indigo"
     */




    render() {
        const innerStyle = {
            color: "white",
            fontSize: 12,
            marginLeft: 5
        }
        const user = this.getLoginName();
        return (
            <div>
                <Router>
                    <MDBNavbar style={{background:"#795548"}} dark expand="md">
                        <MDBNavbarBrand>
                            <strong className="white-text" style={styles.brandTitle}>BizMinder</strong>
                        </MDBNavbarBrand>
                        <MDBNavbarToggler onClick={this.toggleCollapse}/>
                        <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                            <MDBNavbarNav left>
                                <MDBNavItem style={styles.brandSubTitle}>
                                    Fleet Management System
                                </MDBNavItem>
                            </MDBNavbarNav>
                            <MDBNavbarNav right>
                                <MDBNavItem active>
                                    <NavLink
                                        to="#home"
                                        style={styles.mainMenuText}
                                        onClick={() => this.setModuleMenu("home", "", "")}
                                        style={styles.mainMenuText}
                                    >
                                        Home
                                    </NavLink>
                                </MDBNavItem>



                                <MDBNavItem active>
                                    <NavLink
                                        to="#home"
                                        style={styles.mainMenuText}
                                        onClick={() => this.setModuleMenu("fleet", "", "")}
                                        style={styles.mainMenuText}
                                    >
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        Fleet Maintenance
                                    </NavLink>
                                </MDBNavItem>
                                <MDBNavItem active>
                                    <NavLink
                                        to="#home"
                                        style={styles.mainMenuText}
                                        onClick={() => this.setModuleMenu("fuel", "", "")}
                                        style={styles.mainMenuText}
                                    >
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        Fuel Management
                                    </NavLink>
                                </MDBNavItem>

                                <MDBNavItem active>
                                    <NavLink
                                        to="#home"
                                        style={styles.mainMenuText}
                                        onClick={() => this.setModuleMenu("work", "", "")}
                                        style={styles.mainMenuText}
                                    >
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        Works Schedule
                                    </NavLink>
                                </MDBNavItem>


                                {/*<MDBNavItem>
                                    <NavLink
                                        to="#home"
                                        style={styles.mainMenuText}
                                        onClick={() => this.setModuleMenu("setting", "", "")}
                                        style={styles.mainMenuText}
                                    >
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        Setting
                                    </NavLink>
                                </MDBNavItem>


                                <MDBNavItem>
                                    <NavLink
                                        to="#home"
                                        style={styles.mainMenuText}
                                        onClick={() => this.setModuleMenu("util", "", "")}
                                        style={styles.mainMenuText}
                                    >
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        Utilities
                                    </NavLink>
                                </MDBNavItem>*/}



                                <MDBNavItem>
                                    <MDBDropdown>
                                        <MDBDropdownToggle nav caret style={{paddingTop:0}}>
                                            &nbsp;&nbsp;|&nbsp;&nbsp;
                                            <i className="fa fa-user">&nbsp;</i>
                                            &nbsp;
                                            <span className="mr-2" style={{fontSize:11}}>{user.name}</span>
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            <MDBDropdownItem href="#!">&nbsp;</MDBDropdownItem>
                                            <MDBDropdownItem href="#!">Logout</MDBDropdownItem>
                                            <MDBDropdownItem href="#!">Role: {user.role}</MDBDropdownItem>
                                            <MDBDropdownItem href="#!">Company: {user.company}</MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavItem>
                            </MDBNavbarNav>

                        </MDBCollapse>
                    </MDBNavbar>


                </Router>
                <MDBNavbar  dark expand="md" style={styles.infoLine}>
                    <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                        <MDBNavbarNav left>
                            <MDBNavItem style={styles.brandSubTitle}>
                                &nbsp;<span className="fa fa-building" style={{color:"white"}}></span>&nbsp;
                                <span style={styles.infoMenuText}>{user.company.toLocaleUpperCase()}</span>
                            </MDBNavItem>
                        </MDBNavbarNav>
                        <MDBNavbarNav right>
                            <MDBNavItem style={styles.brandSubTitle}>
                                &nbsp;<span className="fa fa-check-square-o" style={{color:"white"}}></span>&nbsp;
                                <span style={styles.infoMenuText}>{this.getSelectedProject().project}</span>
                            </MDBNavItem>
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBNavbar>
            </div>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PageHeading)

//  https://mdbootstrap.com/docs/react/navigation/navbar/#basic
const styles = {
    infoLine: {
        marginTop: -10,
        borderColor: "white",
        borderTopWidth: 2,
        height: 30,
        minHeight: 30,
        maxHeight: 30,
        background:"#795548"
    },
    infoMenuText: {
        color: "#ffd9cc",
        fontWeight: "",
        fontSize:12
    },
    brandTitle: {
        color: "white",
        fontSize: 20,
    },
    brandSubTitle: {
        color: "white",
        fontSize: 12,
        fontWeight:"bold"
    },
    mainMenuText: {
        color: "white",
        fontSize: 12,
        marginLeft: 5
    },

}
