import React, {Component} from 'react';
import '../asset/css/login.css';
import PageContentWallpaper from '../component/common/ef-pageContent';

import imgChoose from '../asset/img/home.jpg';

export default class HomeComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const width = window.innerWidth, height = window.innerHeight;
        const myWidth = (width / 100) * 75;
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row" style={{width: "100%", minWidth: "100%"}}>

                    <div className="col-lg-10 col-xs-12">

                        <h3 style={{textAlign: "center", color: "#795548"}}>
                            <small style={{textAlign: "center", color: "#795548"}}>Welcome to Fleet Management
                                System!</small>
                            How can we help you?
                        </h3>
                    </div>


                </div>

                    <div className="row container">
                        <div className="col-xs-12">

                        </div>
                    </div>




            </PageContentWallpaper>


        );
    }
}

/*
Blue = #1F4E79  Pink  =  #F13E5F
 */

