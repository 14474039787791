import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import {
    fetchUtilData,
    handleInput,
    renderList,
    renderForm, removeEntry
} from "./api-util";

export default class ComUtilMaintenanceActivityType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            newEntry: {
                TableRef:"",
                Name: "",
                Category : "",
                Type : "",
                Position : "",
                Color : "",
                Score : "",
                Level : "",
            },
            showList: true,
            cqlTable: "UtilDatabaseOptions"
        }
    }

    async componentDidMount() {
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await fetchUtilData(this, this.state.cqlTable, "Data");
    }
    renderList = () => {
        const keyList = [
            {field: "name", label: "Name"},
            {field: "category", label: "Cat"},
            {field: "type", label: "Type"},
            {field: "position", label: "Position"},
            {field: "color", label: "Color"},
            {field: "score", label: "Score"},
            {field: "level", label: "Level"},

        ];
        const deleteKey=["org","tableref","category","type","name"];
        const deleteTable="UtilDatabaseOptions";
        return renderList(this, "Data", keyList,deleteKey,deleteTable, "showList", removeEntry)
    }
    renderForm = () => {
        const options = ["yes", "no"];
        const optionDatabases = ["vehicle", "driver","personnel","supplier"];
        const optionsCategory =["status","priority","file-name","file-type"];
        const optionType=["issue","alert","notification"];

        let ls = [
            {field: "TableRef", label: "Target Database", options: optionDatabases, type: "option", required: true},
            {field: "Category", label: "Category", options: optionsCategory, type: "option", required: true},
            {field: "Type", label: "Type", options: optionType, type: "option", required: true},
            {field: "Name", label: "Name Of Option", options: options, type: "text", required: true},
            {field: "Position", label: "Position", options: options, type: "text", required: true},
            {field: "Color", label: "Color", options: options, type: "text", required: false},
            {field: "Score", label: "Score", options: options, type: "text", required: false},
            {field: "Level", label: "Level", options: options, type: "text", required: false},
        ]
        return renderForm(ls, "showList", this.state.cqlTable, this)
    }

    render() {

        let _this = this;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row">
                        <div className="col col-xs-12">
                            <h3>
                                Maintenance Database Options Setup


                                {this.state.showList ?
                                    <a className={"btn btn-primary pull-right"}
                                       onClick={(e) => handleInput(e, "showList", false, _this)}>Add New Record</a>
                                    :
                                    <a className={"btn btn-danger pull-right"}
                                       onClick={(e) => handleInput(e, "showList", true, _this)}>Cancel Adding new
                                        Record</a>
                                }
                            </h3>
                        </div>
                    </div>

                    {this.state.showList ? this.renderList() : this.renderForm()}
                </div>
            </PageContentWallpaper>
        )
    }

}