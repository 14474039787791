import React from "react";


const BoxSelectOption = (label, name, required, options, handFunc, smallText) => {
    if(typeof options==="undefined"){
        options=[];
    }
    return (
        <div className="form-group row" style={{width: "100%"}}>
            <label style={{color: "gray"}}>
                <span style={{color:"red",marginRight:10}}>{required?"*":""}</span>
                {label}
            </label>
            <select
                name={name}
                className="form-control"
                required={required}
                onChange={(e) => handFunc(e)}
            >
                <option value={""}>Choose...</option>
                {options.map((row, index) => {
                    return (
                        <option key={index} value={row.key}>{row.val}</option>
                    )
                })}

            </select>
            {smallText !== "" ?
                <small className="form-text text-muted">{smallText}</small> : null
            }
        </div>
    )
}
const BoxTextareaVertical = (label, name, type, placeholder, required, handFunc, smallText) => {
    return (
        <div className="form-group row">
            <label style={{color: "gray"}}>
                <span style={{color:"red",marginRight:10}}>{required?"*":""}</span>
                {label}
            </label>
            <textarea
                name={name}
                className="form-control"
                aria-describedby={placeholder}
                required={required}
                onChange={(e) => handFunc(e)}
            />
            {smallText !== "" ?
                <small className="form-text text-muted">{smallText}</small> : null
            }
        </div>
    )
}
const BoxInputVertical = (label, name, type, placeholder, required, handFunc, smallText) => {
    return (
        <div className="form-group row">
            <label style={{color: "gray"}}>
                <span style={{color:"red",marginRight:10}}>{required?"*":""}</span>
                {label}
            </label>
            <input
                name={name}
                type={type}
                className="form-control"
                aria-describedby={placeholder}
                required={required}
                onChange={(e) => handFunc(e)}
            />
            {smallText !== "" ?
                <small className="form-text text-muted">{smallText}</small> : null
            }
        </div>
    )
}

export {
    BoxSelectOption,
    BoxTextareaVertical,
    BoxInputVertical
}
