import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import '../../asset/css/stepbystepform.css';
import {
    fetchUtilData,
    handleInput,
    renderList,
    renderForm, removeEntry
} from "./api";
import {renderFormWithSubmit} from "../setting-maintenance/api";
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from "../../config";

export default class ComVehicleSettingAttribute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Vehicles:[],
            Drivers:[],
            newEntry: {},
            showList: true,
            cqlTable: "CollectionMileage"
        }
    }

    async componentDidMount() {
        await this.loadInitialData()
    }

    loadInitialData = async () => {
        await fetchUtilData(this, this.state.cqlTable, "Data");
        await fetchUtilData(this,  "GeneralTableData", "Vehicles", "vehicle");
        await fetchUtilData(this, "Driver", "Drivers");
    }
    submitCollection=async (e)=>{
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        const _this = this;
        const table = "CollectionMileage";

        let entry = {...this.state.newEntry};
        let o={};
        entry.Org = GetUserToken().OrgCode;
        entry.Mileage = parseFloat(entry.Mileage);

        //todo let add our default variable
        entry.Id = "";
        entry.Status = "";
        entry.Date = "";
        entry.Time = "";
        entry.OrgDateTime = "";

        let hub = {...entry}
        let backend = eConfig.mainBackend;
        let endpoint = "/api/fleet/collection/new";

        console.log("submitCollection send > ", hub," > ",o);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {

            if (dataIn !== null) {
                const result = dataIn.RESULT;
                console.log("submitCollection response > ", result);
                if (typeof result !== "undefined") {
                    if (result.status === "OK") {
                        alert("Thank you Record submitted!");
                        let tmp = _this.state;
                        tmp.showList = true;
                        tmp.newEntry = {};
                        _this.setState(tmp);
                        _this.loadInitialData();
                    } else {
                        alert(result.status)
                    }

                }
            }

        });
    }
    renderList = () => {
        const keyList = [
            {field: "vehicle", label: "Vehicle"},
            {field: "mileage", label: "Mileage"},
            {field: "direction", label: "Type"},
            {field: "collectdate", label: "Date"},
            {field: "collectby", label: "Author"},
            {field: "orgdatetime", label: "Last Updated"}
        ];
        const deleteKey=["org","name"];
        const deleteTable="CollectionMileage";
        const sortKey="collectdate";
        return renderList(this, "Data", keyList,deleteKey,deleteTable, "showList", removeEntry,sortKey)
    }
    renderForm = () => {
        const options = ["yes", "no"];
        const categories = ["checkin", "checkout"];
        let lsDriver=[];
        let lsVehicle=[];
        for(let i in this.state.Vehicles){
            const row = this.state.Vehicles[i];
            lsVehicle.push(row.ref);
        }
        for(let i in this.state.Drivers){
            const row = this.state.Drivers[i];
            lsDriver.push(row.ref);
        }


        let ls = [
            {field: "Vehicle", label: "Vehicle", options: lsVehicle, type: "option", required: true},
            {field: "Driver", label: "Driver", options: lsDriver, type: "option", required: true},
            {field: "Direction", label: "Category of Collection", options: categories, type: "option", required: true},
            {field: "Mileage", label: "Your Mileage", options: options, type: "text", required: true},
            {field: "CollectDate", label: "Your Collection Date", options: options, type: "text", required: true},
            {field: "CollectBy", label: "Collection Agent", options: options, type: "text", required: true},
        ]
        return renderFormWithSubmit(ls, "showList", this.state.cqlTable, this,this.submitCollection)
    }

    render() {

        let _this = this;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row">
                        <div className="col col-xs-12">
                            <h3>
                                Fleet Maintenance Vehicle Attribute


                                {this.state.showList ?
                                    <a className={"btn btn-primary pull-right"}
                                       onClick={(e) => handleInput(e, "showList", false, _this)}>Add New Record</a>
                                    :
                                    <a className={"btn btn-danger pull-right"}
                                       onClick={(e) => handleInput(e, "showList", true, _this)}>Cancel Adding new
                                        Record</a>
                                }
                            </h3>
                        </div>
                    </div>

                    {this.state.showList ? this.renderList() : this.renderForm()}
                </div>
            </PageContentWallpaper>
        )
    }

}
