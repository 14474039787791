import React from "react";
import eConfig from "../../config";
import {PostToBackend} from "../../api/services";

const GetJobListOption=(info,vehicle)=>{
    let ls=[];
    console.log("GetJobListOption> ",info);
    for(let i in info){
        const row=info[i];
        if(typeof vehicle !=="undefined"){
            if(row.vehicle===vehicle){
                ls.push({
                    key:row.jobnumber,
                    val:row.jobnumber+"; "+row.vehicle+"; "+row.activity+"; "+row.service
                })
            }
        }else{
            ls.push({
                key:row.jobnumber,
                val:row.jobnumber+"; "+row.vehicle+"; "+row.activity+"; "+row.service
            })
        }

    }
    return ls
}

const GetModelListOption = (_this,modelName,displaySession,resultType) => {
    const database=GetModelInfo(_this,modelName,"Databases");
    const attributes=GetModelInfo(_this,modelName,"Attributes");
    //console.log("GetModelListOption &&&-> ",database," > ",attributes)
    return GetSectionFromDatabaseEntityOptions(
        database,
        attributes,
        displaySession, //"names",
        null,
        resultType ,//"array"
    );
}
const GetModelInfo=(_this,model,infoType/*Databases*/)=>{
    let ls=[];
    const information =_this.state[infoType];
    //console.log("GetModelListOption &&&-> ",information," > ",_this.state);
    for(let i in information){
        const row = information[i];
        //console.log("GetModelListOption &&&-> ",row.tableref," > ",model," > ",row.tableref ===model);
        if(row.tableref ===model){
            ls.push(row)
        }
    }
    //console.log("GetModelListOption &&&-> ",model," > ",ls);
    return ls
}

const GetDriverListOption = (_this) => {
    return GetSectionFromDatabaseEntityOptions(
        _this.state.Personnel,
        _this.state.PersonnelAttributes,
        "names",
        null,
        "array"
    );
}

const GetPersonnelListOption = (_this) => {
    return GetSectionFromDatabaseEntityOptions(
        _this.state.Personnel,
        _this.state.PersonnelAttributes,
        "names",
        null,
        "array"
    );
}

const GetSupplierListOption = (_this) => {
    return GetSectionFromDatabaseEntityOptions(
        _this.state.Suppliers,
        _this.state.SupplierAttributes,
        "names",
        null,
        "array"
    );
}

const GetSectionFromDatabaseEntityOptions = (dataEntity, dataAttribute, category, ref, returnType) => {
    const cat = category; //"names";
    let keyList = [];
    for (let i in dataAttribute) {
        if (dataAttribute[i].section === cat) {
            keyList.push(dataAttribute[i].fieldname);
        }
    }


    let returnObject = {key: "", val: ""};
    let returnMap = {};
    let returnArray = [];
    for (let e in dataEntity) {
        const rowParrent = dataEntity[e];
        const key = rowParrent.ref;
        let val = "";
        let x = 0;
        for (let i in keyList) {
            const field = keyList[i];
            const v = rowParrent.data[field] || "--"
            val += x == 0 ? " " + v : ", " + v;
            returnMap[field] = v

        }

        returnObject = {
            key: key,
            val: val
        };
        returnArray.push({
            key: key,
            val: val
        })

    }

    if (returnType === "array") {
        return returnArray
    }
    if (returnType === "map") {
        return returnMap
    }
    return returnObject
}

const GetSectionFromDatabaseEntity = (dataEntity, dataAttribute, category, ref, returnType) => {
    const cat = category; //"names";
    let keyList = [];
    for (let i in dataAttribute) {
        if (dataAttribute[i].section === cat) {
            keyList.push(dataAttribute[i].fieldname);
        }
    }
    let returnObject = {key: "", val: ""};
    let returnMap = {};
    let returnArray = [];
    for (let e in dataEntity) {
        const rowParrent = dataEntity[e];
        const key = rowParrent.ref;
        if (ref === null) {
            console.log("getAssignInCharge==> ", ref)
            continue
        }
        if (key === ref) {
            let val = "";
            let x = 0;
            for (let i in keyList) {
                const field = keyList[i];
                const v = rowParrent.data[field] || "--"
                val += x == 0 ? " " + v : ", " + v;
                returnMap[field] = v
                returnArray.push({
                    key: field,
                    val: v
                })
            }
            returnObject = {
                key: key,
                val: val
            };
        }
    }
    if (returnType === "array") {
        return returnArray
    }
    if (returnType === "map") {
        return returnMap
    }
    return returnObject
}


const FindEntryCounter = (ref, data, key) => {
    let total = 0;
    let ls = [];
    for (let i in data) {
        const row = data[i];
        if (row[key] === ref) {
            total++;
            ls.push(row)
        }
    }
    return {total: total, list: ls}
}
const FindImages = (ref, Files) => {
    for (let i in Files) {
        const row = Files[i];
        if (row.ref === ref &&
            row.tableref === "vehicle" &&
            row.type === "profile") {

            return row.link;
        }
    }
    return "https://res.cloudinary.com/gocar/image/upload//f_auto,q_auto/v1562835896/assets/blank_car.png"
}
const FindImagesAny = (ref, Files,module) => {
    for (let i in Files) {
        const row = Files[i];
        if (row.ref === ref &&
            row.tableref === module &&
            row.type === "profile") {

            return row.link;
        }
    }
    return "https://res.cloudinary.com/gocar/image/upload//f_auto,q_auto/v1562835896/assets/blank_car.png"
}

const FindData = (rowIn, cat, Attributes) => {
    const keyList = [];
    for (let i in Attributes) {
        const row = Attributes[i];
        keyList.push({
            field: row.fieldname,
            label: row.fieldname.toLocaleUpperCase(),
            category: row.section
        });
    }
    let ls = [];
    for (let i in keyList) {
        const row = keyList[i];
        if (row.category === cat) {
            const data = rowIn;
            if (row.field.toLocaleLowerCase() !== "mileage") {
                let val = "";
                if (typeof data !== "undefined") {
                    if (typeof data[row.field] !== "undefined") {
                        val = data[row.field];
                        ls.push({val: val, field: row.field})
                    }
                }
            }
        }
    }
    return ls.map((item) => {
        return (
            <span><span style={{color: "red"}}>{item.field}</span>: <span
                style={{color: "gray"}}>{item.val} &nbsp;</span></span>
        )
    })
}

const removeEntry = async (e, row, deleteKey, table, _this) => {
    e.preventDefault();
    if (!window.confirm("Are you sure that you want to remove?")) {
        return
    }
    //Conditions
    let conds = [
        /*{Key: "org", Val: row.org, Type: "string"},
        {Key: "name", Val: row.name, Type: "string"},*/
    ]
    for (let i in deleteKey) {
        const key = deleteKey[i];
        conds.push({
            Key: key,
            Val: row[key],
            Type: "string"
        });
    }
    let hub = {
        Fields: [],
        DbName: "fleetminder",
        TableName: table,
        Conditions: conds,
    }
    let backend = eConfig.mainBackend;
    let endpoint = "/api/global-service/remove";

    console.log("removeEntry send > ", hub);
    await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
        console.log("removeEntry response > ", dataIn);
        if (dataIn !== null) {
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Record  removed!");
                _this.setState({showList: true});
                _this.loadInitialData();
            }
        }
    });
}

export {
    FindData,
    FindImages,
    FindEntryCounter,
    GetSectionFromDatabaseEntity,
    GetSectionFromDatabaseEntityOptions,
    GetDriverListOption,
    GetPersonnelListOption,
    GetSupplierListOption,
    GetModelListOption,
    FindImagesAny,
    GetJobListOption,
    removeEntry,
};
