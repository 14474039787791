import {SAVE_NAVIGATIONS} from "../constant";

const allRole = ["super", "admin", "capture", "enumerator"];
const adminRole = ["super", "admin"];
const superRole = ["super"];
const clientRole = ["client"];

const initial = {
    currentModule: "home",
    currentMenu: "",
    currentSubmenu: "",
    menus: {
        home: [
            {name: "Vehicle", hasMenu: false, link: "home-vehicle", roles: adminRole},
            {name: "Help", hasMenu: false, link: "home-help", roles: allRole},
        ],
        mileage: [
            {name: "Record", hasMenu: false, link: "mileage-recording", roles: adminRole},
            {name: "View", hasMenu: false, link: "mileage-view", roles: allRole},
        ],
        fuel: [
            {name: "Spec", hasMenu: false, link: "fuel-spec", roles: adminRole},
            {name: "Filling Up", hasMenu: false, link: "fuel-filling", roles: adminRole},
            {name: "Usage", hasMenu: false, link: "fuel-usage", roles: adminRole},
            {name: "Analysis", hasMenu: false, link: "fuel-usage", roles: adminRole},
        ],
        fleet: [
            {
                name: "Dashboards", hasMenu: true, link: "", submenu: [
                    {name: "Maintenance", link: "fleet-dashboard-task-due"},
                    {name: "Vehicles", link: "fleet-dashboard-vehicle"},
                    {name: "Drivers", link: "fleet-dashboard-vehicle"},
                    {name: "Vendors", link: "fleet-dashboard-vehicle"},
                    {name: "Personnel", link: "fleet-dashboard-vehicle"},
                    {name: "Expense", link: "fleet-dashboard-expense"},
                ], roles: adminRole
            },
            {
                name: "Mileage Collection", hasMenu: true, link: "", submenu: [
                    {name: "Mileage Capture", link: "fleet-collection-mileage"},

                ], roles: adminRole
            },
            /*{
                name: "Expenses", hasMenu: true, link: "", submenu: [
                    {name: "Capture Invoice", link: "fleet-finance-invoice"},
                    {name: "Analytic", link: "fleet-finance-analytic"},
                ], roles: adminRole
            },*/
            {name: "Job Origination", hasMenu: false, link: "setting-planning", roles: adminRole},
            {
                name: "Jobs Scheduler", hasMenu: true, link: "", submenu: [
                    {name: "Jobs", link: "fleet-schedule-jobs"},
                    {name: "Analytic", link: "fleet-schedule-analytic"},
                ], roles: adminRole
            },
            {name: "Jobs Expense", hasMenu: false, link: "fleet-job-expense", roles: adminRole},
            {name: "Fault Logging", hasMenu: false, link: "fleet-faults", roles: adminRole},
            {
                name: "Databases", hasMenu: true, link: "", submenu: [
                    {name: "Driver", link: "setting-model-databases/driver"},
                    {name: "Personnel", link: "setting-model-databases/personnel"},
                    {name: "Supplier", link: "setting-model-databases/supplier"},
                    {name: "Vehicle", link: "setting-model-databases/vehicle"},
                    {name: "Manufacturer", link: "setting-model-databases/manufacturer"},
                    {name: "Fleet Location", link: "setting-model-databases/fleet-location"},
                ], roles: adminRole
            },
            {
                name: "Parts Management", hasMenu: true, link: "", submenu: [
                    {name: "Create Inventory", link: "parts-inventory"},
                    {name: "Used Parts", link: "parts-used"},
                    {name: "Order", link: "parts-order"},
                ], roles: adminRole
            },
            {
                name: "Settings", hasMenu: true, link: "", submenu: [
                    {name: "Driver Attribute", link: "setting-model-attributes/driver"},
                    {name: "Personnel Attribute", link: "setting-model-attributes/personnel"},
                    {name: "Supplier Attribute", link: "setting-model-attributes/supplier"},
                    {name: "Vehicle Attribute", link: "setting-model-attributes/vehicle"},
                    {name: "Manufacturer Attribute", link: "setting-model-attributes/manufacturer"},
                    {name: "Fleet Location", link: "setting-model-attributes/fleet-location"},

                ], roles: adminRole
            },
            {
                name: "Utilities", hasMenu: true, link: "", submenu: [
                    {name: "Set Job Categories", link: "util-maintenance-activity-list"},
                    {name: "Set Database Entity Option", link: "util-maintenance-database-option"},
                ], roles: adminRole
            },
        ],


        util: [
            {
                name: "Fleet Maintenance Activity",
                hasMenu: false,
                link: "util-maintenance-activity-list",
                roles: adminRole
            },
            {
                name: "Fleet Maintenance Service",
                hasMenu: false,
                link: "util-maintenance-service-list",
                roles: adminRole
            },
            /*{
               name: "Fleet Maintenance Service", hasMenu: true, link: "", submenu: [
                   {name: "Type of Service", link: "util-maintenance-service-type"},
                   {name: "Category of Service", link: "util-maintenance-service-category"},
               ], roles: adminRole
           }, {
               name: "Fleet  Maintenance Task", hasMenu: true, link: "", submenu: [
                   {name: "Type of Task", link: "util-maintenance-task-type"},
                   {name: "Category of Task", link: "util-maintenance-task-category"},
               ], roles: adminRole
           },*/

        ],
        work: [
            {
                name: "Work Board", hasMenu: true, link: "", submenu: [
                    {name: "Help", link: "work-help"},
                ], roles: adminRole
            },
        ]

    }
}


const reduceNavigations = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case "SAVE_MODULE_MENU":
            const entry = action.payload;
            console.log("Reducer ::(--> ", action);
            newState = initial;
            newState.currentModule = entry.module;
            newState.currentMenu = entry.menu;
            newState.currentSubmenu = entry.submenu;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default reduceNavigations;
